import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import {
    BuilderContainer, BackButton, SectionTitle, SectionContainer,
    SectionText, AddSectionButton, MenuContainer, MenuButton
} from "./ResumeBuilder.Styled";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import arrow_left from "../../images/icons/arrow-left-black.svg";
import reorder from "../../images/icons/reorder.svg";
import more from "../../images/icons/more.svg";
import deleteIcon from "../../images/icons/delete-mobile-large.svg";
import {
    download_data, page_loading, resume,
    selected_section as selected_section_atom,
    request_save as request_save_
} from "../../recoil/store";
import { getCvById } from "../../services/getCvById";
import { calculateTextColorFromRGB } from "../../utils/color-formating";
import { decor, section_types } from "../../templates";
import AddSectionModal from "../../Component/AddSectionModal/AddSectionModal";
import DeleteSectionModal from "../../Component/DeleteSectionModal/DeleteSectionModal";
import PreviewButton from "../../Component/ActionButtons/ActionButtons";
import { useSaveFunction } from "../../utils/save";

const ResumeBuilderMobile = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const menuRefs = useRef([]);

    const [page_loading_, set_page_loading] = useRecoilState(page_loading);
    const [download_data_, set_download_data] = useRecoilState(download_data);
    const [resume_, set_resume] = useRecoilState(resume);
    const [selected_section, set_selected_section] = useRecoilState(selected_section_atom);
    const [request_save, set_request_save] = useRecoilState(request_save_);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [currIndex, setCurrIndex] = useState(undefined);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [{ sectionToRemove, itemToRemove }, setToRemove] = useState({ sectionToRemove: "", itemToRemove: null });

    const saveFunction = useSaveFunction();

    useEffect(() => {
        if (request_save === true) {
            saveFunction(id, true);
        }
    }, [request_save])

    useEffect(() => {
        (async function () {
            if (!request_save) {
                set_page_loading(true)
                try {
                    let response = await getCvById(id);
                    set_download_data({
                        id: id,
                        name: response?.name
                    })
                    const textColor = calculateTextColorFromRGB(response?.settings?.color);
                    if (Array.isArray(response?.customSection)) {
                        for (const key of Object.keys(response)) {
                            if (key === 'customSection') {
                                for (const k of response[key]) {
                                    for (const k2 of Object.keys(k)) {
                                        response = {
                                            ...response,
                                            [k2]: k[k2]
                                        }
                                    }
                                }
                            }
                        }
                    }
                    let deep_copy = JSON.parse(JSON.stringify(response));
                    deep_copy.settings.textColor = textColor;
                    set_resume(deep_copy);
                    set_page_loading(false);
                } catch (error) {
                    console.log(error);
                    set_page_loading(false)
                    enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" })
                }
            }
        })()
    }, [])

    useEffect(() => {
        const listener = (event) => {
            if (!menuRefs?.current[currIndex] || menuRefs?.current[currIndex].contains(event.target)) {
                return;
            }
            setMenuOpen(false)
        };
        document.addEventListener("mousedown", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, [currIndex]);

    const sectionKeys = (copy) => {
        return Object.keys(copy).filter(f =>
            f !== "designType" && f !== "name" && f !== "summary" &&
            f !== "professionId" && f !== "settings" &&
            f !== "personalInformation" && f !== "customSection");
    }

    const visiableKeys = (copy) => {
        return sectionKeys(copy)
            .filter(key => resume_[key]?.active == true)
            .filter(key => resume_[key]?.order !== undefined)
            .sort((s1, s2) => resume_[s1]?.order < resume_[s2]?.order ? -1 : resume_[s1]?.order > resume_[s2]?.order ? 1 : 0)
    }

    function findMaxOrder(resume) {
        const maxOrder = Object.entries(resume)
            .filter(([key, obj]) => typeof obj === 'object' && obj?.hasOwnProperty('order') && obj.active && key !== "summary")
            .reduce((max, [key, obj]) => Math.max(max, obj.order), 0);
        return maxOrder;
    }

    const handleDrop = (droppedItem) => {

        if (!droppedItem.destination) return;
        let updatedList = { ...resume_ };
        let vKeys = visiableKeys(updatedList);
        const currIndex = droppedItem.source.index;
        const newIndex = droppedItem.destination.index;
        const oldOrder = updatedList[vKeys[currIndex]].order;
        let newOrder = droppedItem.destination.index + 1;

        //order объекта который перетащили
        if (newOrder >= vKeys.length) {
            const maxOrder = findMaxOrder(updatedList);
            newOrder = maxOrder;
            updatedList[vKeys[currIndex]] = {
                ...updatedList[vKeys[currIndex]],
                order: newOrder
            };
        }
        else {
            updatedList[vKeys[currIndex]] = {
                ...updatedList[vKeys[currIndex]],
                order: newOrder
            };
        }

        // Обновляем order у других объектов в зависимости от нового порядка
        vKeys.map((key, index) => {
            if (index !== currIndex) {
                if (newOrder < oldOrder && updatedList[key]?.order >= newOrder && updatedList[key]?.order < oldOrder) {
                    updatedList[key] = {
                        ...updatedList[key],
                        order: updatedList[key]?.order + 1
                    };
                }
                else if (newOrder > oldOrder && updatedList[key]?.order <= newOrder && updatedList[key]?.order > oldOrder) {
                    updatedList[key] = {
                        ...updatedList[key],
                        order: updatedList[key]?.order - 1
                    };
                }
            }
        })
        set_resume(updatedList);
        saveFunction(id, true);
    };

    const openDelete = (e, index) => {
        e.stopPropagation();
        setCurrIndex(index);
        menuRefs[index]?.current?.focus();
        setMenuOpen(!menuOpen)
    };

    function deleteQR(e) {
        e.stopPropagation();
        setToRemove({ sectionToRemove: "qr", itemToRemove: null });
        setMenuOpen(false);
        setShowConfirmationModal(true);
    }

    function displayDeleteConfirmation(e, key, item) {
        e.stopPropagation();
        setToRemove({ sectionToRemove: key, itemToRemove: item });
        setMenuOpen(false);
        setShowConfirmationModal(true);
    }

    return (
        <BuilderContainer>
            <BackButton onClick={() => navigate(-1)}>
                <img src={arrow_left} alt="back" className='me-2' />
                Back
            </BackButton>
            <SectionTitle>
                Resume Sections
            </SectionTitle>
            {!page_loading_ &&
                <div>
                    <SectionContainer
                        onClick={() => {
                            set_selected_section({ ...selected_section, side_bar: "personalInformation", section_controller: "" });
                            navigate(`/resume-section/${id}/personalInformation/0`);
                        }}>
                        <SectionText>
                            {'PERSONAL INFORMATION'}
                        </SectionText>
                    </SectionContainer>
                    {resume_.personalInformation.qr.active && <SectionContainer
                        onClick={() => { set_selected_section({ ...selected_section, side_bar: "QR" }); navigate(`/resume-section/${id}/QR/0`); }}>
                        <SectionText>
                            {"QR CODE"}
                        </SectionText>
                        <div ref={(element) => { menuRefs.current[0] = element }} style={{ position: 'relative' }}>
                            <button onClick={(e) => openDelete(e, 0)}>
                                <img src={more} alt="more" />
                            </button>
                            {menuOpen && currIndex === 0 &&
                                <MenuContainer>
                                    <MenuButton onClick={(e) => deleteQR(e)}>
                                        <img src={deleteIcon} alt="delete" />
                                        Delete
                                    </MenuButton>
                                </MenuContainer>}
                        </div>
                    </SectionContainer>}
                    {resume_.summary.active && <SectionContainer
                        onClick={() => {
                            set_selected_section({
                                ...selected_section,
                                side_bar: "summary",
                                section_controller: "summary",
                                index: 1
                            });
                            navigate(`/resume-section/${id}/summary/1`);
                        }}>
                        <SectionText>
                            {"SUMMARY"}
                        </SectionText>
                        <div ref={(element) => { menuRefs.current[1] = element }} style={{ position: 'relative' }}>
                            <button onClick={(e) => openDelete(e, 1)}>
                                <img src={more} alt="more" />
                            </button>
                            {menuOpen && currIndex === 1 &&
                                <MenuContainer>
                                    <MenuButton onClick={(e) => displayDeleteConfirmation(e, "summary", null)}>
                                        <img src={deleteIcon} alt="delete" />
                                        Delete
                                    </MenuButton>
                                </MenuContainer>}
                        </div>
                    </SectionContainer>}
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {visiableKeys(resume_).map((k, index) => (
                                        <Draggable key={k} draggableId={k} index={index}>
                                            {(provided) => (
                                                <SectionContainer key={k}
                                                    onClick={() => {
                                                        section_types?.type_4?.includes(k) ?
                                                            set_selected_section({
                                                                ...selected_section, side_bar: k,
                                                                section_controller: k, index: index
                                                            }) :
                                                            set_selected_section({
                                                                ...selected_section,
                                                                side_bar: k, section_controller: k, index: index
                                                            })
                                                        navigate(`/resume-section/${id}/${k}/${index}`);
                                                    }}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}>
                                                    <div className="d-flex align-items-center">
                                                        <img src={reorder} alt="reorder"
                                                            {...provided.dragHandleProps} />
                                                        <SectionText>
                                                            {k.includes("custom") ? resume_[k].name : decor[k]?.label}
                                                        </SectionText>
                                                    </div>
                                                    <div ref={(element) => { menuRefs.current[index + 2] = element }} style={{ position: 'relative' }}>
                                                        <button onClick={(e) => openDelete(e, index + 2)}>
                                                            <img src={more} alt="more" />
                                                        </button>
                                                        {menuOpen && currIndex === index + 2 &&
                                                            <MenuContainer>
                                                                <MenuButton onClick={(e) => displayDeleteConfirmation(e, k, null)}>
                                                                    <img src={deleteIcon} alt="delete" />
                                                                    Delete
                                                                </MenuButton>
                                                            </MenuContainer>}
                                                    </div>
                                                </SectionContainer>
                                            )}
                                        </Draggable>))}
                                    {provided.placeholder}
                                </div>)}
                        </Droppable>
                    </DragDropContext>
                </div>}
            <PreviewButton id={id} />
            <AddSectionButton onClick={() => setIsOpen(true)}>
                + Add New Section
            </AddSectionButton>
            <AddSectionModal
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false)
                }} />
            <DeleteSectionModal
                isOpen={showConfirmationModal}
                onClose={() => {
                    saveFunction(id, true);
                    setShowConfirmationModal(false)
                }}
                sectionToRemove={sectionToRemove}
                itemToRemove={itemToRemove}
                mobSection={false} />
        </BuilderContainer>
    )
}
export default ResumeBuilderMobile