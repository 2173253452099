import React from "react";
import { Modal } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { resume, selected_section as selected_section_atom } from "../../recoil/store";
import { BUTTON, TEXT } from "../../styled";
import { section_types } from "../../templates";
import { useSaveFunction } from "../../utils/save";

const DeleteSectionModal = ({ isOpen, onClose, sectionToRemove, itemToRemove, mobSection }) => {

    const [resume_, set_resume] = useRecoilState(resume);
    const [selected_section, set_selected_section] = useRecoilState(selected_section_atom);

    function delete_QR() {
        let copy = { ...resume_ };
        copy.personalInformation = {
            ...copy.personalInformation,
            qr: {
                ...copy.personalInformation.qr,
                active: copy.personalInformation.qr?.active ? false : true
            }
        };
        set_resume(copy);
        set_selected_section({ ...selected_section, side_bar: "" });
        onClose();
    }

    function deleteSection() {
        let key = sectionToRemove;
        let resume = { ...resume_ };
        if (itemToRemove != null) {
            let collection = { ...resume?.[key] }
            let items = [...collection?.items]
            items?.splice(itemToRemove, 1);
            resume = {
                ...resume,
                [key]: {
                    ...resume[key],
                    items
                }
            }
        } else {
            resume[key] = {
                ...resume[key],
                active: false
            };
            set_selected_section({ ...selected_section, side_bar: "", section_controller: "", index: 0 })
        }
        onClose();
        set_resume(resume);
    }

    function handleDeleteLine() {
        let resume_copy = { ...resume_ };
        let key_ = { ...resume_copy[sectionToRemove] };
        let array = [...resume_copy[sectionToRemove].content];
        array.splice(itemToRemove, 1);
        key_.content = array;
        resume_copy[sectionToRemove] = key_;
        set_resume(resume_copy);
        onClose();
    }

    function handleDeleteButton() {
        if (mobSection) {
            if (section_types.type_2.includes(selected_section.side_bar) ||
                section_types.type_5.includes(selected_section.side_bar)) {
                handleDeleteLine();
            }
            else deleteSection();
        }
        else {
            if (sectionToRemove === "qr") {
                delete_QR();
            }
            else deleteSection();
        }

    }

    return (
        <Modal
            onHide={onClose}
            show={isOpen}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="modal_container">
                <div className='text-center'>
                    <TEXT
                        f_size={"18px"}
                        clr="#1C1C1C"
                        f_family='montserrat_semibold'
                    >
                        {mobSection ? 'Are you sure you want to delete this item?' : 'Are you sure you want to delete this section?'}
                    </TEXT>
                </div>
                <div className={`d-flex`}>
                    <BUTTON
                        className={"mt-5"}
                        clr="#000"
                        bord="0"
                        pad="12px 0"
                        bg_color='transparent'
                        brad="6px"
                        wid="100%"
                        f_family='montserrat_semibold'
                        onClick={onClose}
                    >
                        Cancel
                    </BUTTON>
                    <BUTTON
                        className={"mt-5"}
                        clr="#fff"
                        bord="0"
                        pad="12px 0"
                        bg_color='#4984EA'
                        brad="6px"
                        wid="100%"
                        onClick={() => handleDeleteButton()}
                    >
                        Delete
                    </BUTTON>
                </div>
            </div>
        </Modal>
    )
}
export default DeleteSectionModal