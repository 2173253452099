import { Link } from "react-router-dom";
import styled from "styled-components";

export const NAVBAR_HEADER = styled.header`
    height: ${props => props?.hei};
    background: ${props => props?.bg_color};
    width: 100%;
    padding: ${props => props?.pad};
`

