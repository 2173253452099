import Interceptor from "./interceptor";

export async function getCv() {
    return (await Interceptor.get("/cv")).data;
}

export async function getUserInfo() {
    return (await Interceptor.get("/user")).data;
}

export async function getPrices() {
    return (await Interceptor.get("/products")).data;
}

export async function payment(data) {
    return (await Interceptor.post("/payment/init", data));
}