import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import Header from './Header';
import Body from './Body';
import { TEMPLATE } from './template.styled';
import plus_icon from "../../images/icons/plus.svg";
import { BUTTON, AFTER_PAGER } from '../../styled';
import { fonts } from '../../constants';
import { holder_ref, cvHeight, resume, template_ref, is_preview } from '../../recoil/store';
import { enqueueSnackbar } from 'notistack';
import useWindowDimensions from '../../utils/WindowDimension';
import AddSectionModal from '../AddSectionModal/AddSectionModal';
import useMediaQuery from '../useMediaQuery';
import styles from "./template.module.css";
import close_icon from "../../images/icons/close.svg";
import custom_icon from "../../images/icons/custom.svg";
import { decor, section_images } from '../../templates';

const Template = ({
    marg,
    downl,
    templateWidth,
    is_download,
    set_toolbar_view,
    isMobile
}) => {
    const [is_preview_, set_is_preview] = useRecoilState(is_preview);
    const [template_ref_, set_template_ref] = useRecoilState(template_ref);
    const [holder_ref_, set_holder] = useRecoilState(holder_ref);
    const [resume_, set_resume] = useRecoilState(resume);
    const [modal, set_modal] = useState(false);
    const [touch_line, set_touch_line] = useState(false);
    const [heightCv, set_height] = useRecoilState(cvHeight);
    const [threshold, set_threshold] = useState(1190);
    const [pagers, set_pagers] = useState([
        { id: 0, disability: true, transformMarker: "45px", transformLine: "45px" },
    ]);
    const [page_index, set_page_index] = useState(0);
    let cv_ref = useRef(null);
    const isPreviewRef = useRef(is_preview_);
    const { width, height } = useWindowDimensions();
    const isMobileS = useMediaQuery('(max-width: 575px)');
    const lineWidth = width > 1025 ? "866px" : "730px"
    const open_sections_modal = () => {
        set_modal(true);
    };

    const onTemplateClick = () => {
        if (!isPreviewRef.current) {
            return;
        }
        if (!isMobile) {
            enqueueSnackbar("Switch to Edit mode.", { variant: "warning" });
        }
    }

    let resume_copy = JSON.parse(JSON.stringify(resume_));

    const add_custom = () => {
        let o = Object.entries(resume_).filter(
            (f) =>
                f[0] !== "summary" &&
                f[0] !== "designType" &&
                f[0] !== "name" &&
                f[0] !== "professionId" &&
                f[0] !== "personalInformation" &&
                f[0] !== "settings" &&
                f[0] !== "customSection"
        );
        let object = Object.fromEntries(o);
        resume_copy = {
            ...resume_copy,
            [`custom${Object.keys(object)?.length + 1}`]: {
                content: "<p><br></p>",
                name: "Custom Section",
                active: true,
                order: Object.keys(object)?.length + 1,
            },
        };
        set_resume(resume_copy);
        set_modal(false);
    };

    const select_section = (key) => {
        let copy = { ...resume_ };
        if (key === "qr") {
            copy.personalInformation = {
                ...copy.personalInformation,
                qr: {
                    ...copy.personalInformation.qr,
                    active: copy.personalInformation.qr?.active ? false : true,
                },
            };
        } else {
            copy[key] = {
                ...copy[key],
                active: copy[key]?.active ? false : true,
            };
        }
        set_resume(copy);
    };

    useEffect(() => {
        if (!cv_ref.current || is_download) return;
        const resizeObserverCallback = (entries) => {
            const currentThreshold = threshold;
            const currentHeight = entries[0].target.clientHeight;
            const lastPager = pagers[pagers.length - 1];
            if (currentHeight > currentThreshold) {
                let newPagers = [...pagers];
                newPagers.push({ id: newPagers.length + 1, disability: true, transformMarker: `${currentThreshold - 1}px`, transformLine: `${currentThreshold}px` });
                set_pagers(newPagers);
                set_threshold(currentThreshold + 1190);
            } else if (lastPager && currentHeight <= parseInt(lastPager.transformMarker)) {
                let newPagers = [...pagers];
                newPagers.pop();
                set_pagers(newPagers);
                set_threshold(currentThreshold - 1190);
            }
            set_height(currentHeight);
        };
        const resizeObserver = new ResizeObserver(resizeObserverCallback);
        resizeObserver.observe(cv_ref.current);
        return () => resizeObserver.disconnect();
    }, [cv_ref, threshold]);

    useEffect(() => {
        const templateHolder = document.getElementById("template_holder");
        templateHolder.addEventListener("click", onTemplateClick);
        set_holder(templateHolder);
        set_template_ref(document.getElementById("template_ref"));
    }, []);

    useEffect(() => {
        isPreviewRef.current = is_preview_;
    }, [is_preview_]);

    let style_pager = {
        width: "100%",
        height: "100%",
        position: "relative",
        zIndex: 0,
        pointerEvents: (is_preview_) ? "none" : "auto"
    };

    return (
        <div ref={holder_ref_} id="template_holder" className='position-relative' style={(isMobile && isMobileS) ? {
            transform: `scale(${(width / 865)})`,
            transformOrigin: 'top center',
            width: '100vw',
            maxWidth: (width - 40) * (width / 865),
            maxHeight: heightCv * (width / 865),
            marginBottom: 95,
            marginRight: 20,
            marginLeft: 5
        } : isMobile ? {
            transform: `scale(${(width / 1000)})`,
            transformOrigin: 'top center',
            width: '100vw',
            maxWidth: (width) * (width / 1000),
            maxHeight: heightCv * (width / 1000),
            marginBottom: 95,
            marginLeft: 50
        } : {}}>
            <style>{`body {background-color: #EFF1F3;}`}</style>
            <div style={!downl ? style_pager : {}}>
                <TEMPLATE marg={marg} wid={templateWidth} down ref={template_ref_} id="template_ref">
                    {!downl &&
                        pagers?.map((p, i) => (
                            <React.Fragment key={p.id}>
                                <div
                                    style={{
                                        transform: `translate(-50px, ${p?.transformLine})`,
                                        height: "1px",
                                        background: "#EB4B07",
                                        width: lineWidth,
                                        zIndex: 1,
                                        position: "absolute",
                                        opacity: touch_line && p?.id == page_index ? 0 : 1,
                                        top: isMobile && i === 0 ? -45 : 0
                                    }}
                                ></div>
                                <AFTER_PAGER
                                    aft="Page2"
                                    bg_color={touch_line && p?.id == page_index ? "#000" : "#EB4B07"}
                                    transformMarker={p?.transformMarker}
                                    style={{ top: isMobile && i === 0 ? -45 : 0 }}
                                >
                                    <div
                                        onClick={() => {
                                            set_page_index(p?.id);
                                            set_touch_line(p?.id == page_index && touch_line ? false : true);
                                        }}
                                    >
                                        Page {i + 1}
                                    </div>
                                </AFTER_PAGER>
                            </React.Fragment>
                        ))}
                    {!downl && !isMobile && (
                        <BUTTON
                            bord={"0"}
                            wei="500"
                            bg_color="transparent"
                            brad="4px"
                            onClick={open_sections_modal}
                            className='d-flex aic'
                        >
                            <img src={plus_icon} alt="" className='add_section me-2' />
                            Add Section
                        </BUTTON>
                    )}
                    <div
                        className={`${!downl && "mt-3"} position-relative`}
                        id="to_download"
                        style={{ fontFamily: fonts?.find((f) => f?.value == resume_?.settings?.font)?.key, background: "#fff" }}
                        ref={cv_ref}
                    >
                        <Header downl={downl} />
                        <Body is_download={is_download ? is_download : is_preview_} set_toolbar_view={set_toolbar_view} />
                    </div>
                </TEMPLATE>
            </div>
            <Modal
                onHide={() => set_modal(false)}
                show={modal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className={styles.modal_container}>
                    <div className='d-flex jcsb aic'>
                        <div className={styles.modal_title}>Add section</div>
                        <div>
                            <BUTTON bg_color="transparent" bord="0" onClick={() => set_modal(false)}>
                                <img src={close_icon} alt="close_icon" />
                            </BUTTON>
                        </div>
                    </div>
                    <div className={`${styles.modal_content} mt-5`}>
                        {Object.keys(resume_)?.filter(
                            (k) =>
                                k !== "name" &&
                                k !== "designType" &&
                                k !== "settings" &&
                                k !== "personalInformation" &&
                                k !== "professionId" &&
                                k !== "customSection"
                        ).map((k) => (
                            <div key={k}>
                                <BUTTON
                                    wid="80%"
                                    f_size="93%"
                                    bord={"0"}
                                    bg_color="transparent"
                                    f_family="montserrat_bold"
                                    brad="4px"
                                    className='d-flex aic'
                                    clr={resume_[k]?.active ? "#1b1C1C" : "#1c1c1c7d"}
                                    wei="normal"
                                    onClick={() => select_section(k)}
                                >
                                    <img src={k.includes("custom") ? custom_icon : section_images[k]} alt="" className='me-3' />
                                    {k.includes("custom") ? resume_[k]?.name : decor[k]?.label_pop}
                                </BUTTON>
                            </div>
                        ))}
                        <BUTTON
                            wid="80%"
                            f_size="93%"
                            bord={"0"}
                            bg_color="transparent"
                            f_family="montserrat_bold"
                            brad="4px"
                            className='d-flex aic'
                            clr={resume_.personalInformation["qr"]?.active ? "#1b1C1C" : "#1c1c1c7d"}
                            wei="normal"
                            onClick={() => select_section("qr")}
                        >
                            <img src={section_images["qr"]} alt="" className='me-3' />
                            QR Code
                        </BUTTON>
                        <BUTTON
                            wid="80%"
                            f_size="93%"
                            bord={"0"}
                            bg_color="transparent"
                            f_family="montserrat_bold"
                            brad="4px"
                            className='d-flex aic'
                            wei="normal"
                            onClick={() => add_custom("custom_section")}
                        >
                            <img src={custom_icon} alt="" className='me-3' />
                            Custom section
                        </BUTTON>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Template;