import styled from "styled-components";

export const ButtonPosition = styled.div`
    display: flex;
    width: calc(100% - 40px);
    justify-content: space-between;
    position: fixed;
    bottom: 30px;
`
export const BlueButton = styled.button`
    width: 100%;
    height: 48px;
    padding: 12px 18px 12px 18px;
    border-radius: 6px;
    background: #4984EA;
    font-family: montserrat_semibold;
    font-size: 17px;
    line-height: 21.94px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    img {
        margin-left: 8px;
        width: 21.5px;
    }
`
export const TitleText = styled.p`
    font-family: montserrat_medium;
    font-size: 24px;
    line-height: 21.94px;
    letter-spacing: -0.01em;
    width: 100%;
    padding-bottom: 24px;
`;
export const SectionAIContainer = styled.div`
    padding: 30px 20px 30px 20px;
    min-height: 500px;
`
export const SettingsButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EFF1F3;
    height: 48px;
    width: 100%;
    border-radius: 6px;
    padding: 0 12px 0 16px;
    margin-bottom: 16px;
`
export const SettingsText = styled.p`
    font-family: montserrat_medium;
    font-size: 18px;
    line-height: 21.94px;
    letter-spacing: -0.01em;
    text-align: left;
`
export const SettingsArrow = styled.img`
    transform: rotate(90deg);
`
export const BackButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 28px;
    border-radius: 6px;
    background: #EFF1F3;
    border: none;
`