import Interceptor from "./interceptor";

let link = 'https://build.engineernow.org';
export async function downloadCv(id, persist_token, type = "A4") {
    const queryParams = new URLSearchParams({
        url: `${link}/builder/d/${id}/${persist_token?.access}`,
        format: type,
        cvId: id
    });
    const urlWithParams = `/cvs/download?${queryParams.toString()}`;
    return (await Interceptor.get(urlWithParams, { responseType: "blob" })).data;
}