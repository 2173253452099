import { Link } from "react-router-dom";
import styled from "styled-components";

export const HEADER = styled.div`
    padding: ${props => props?.pad};
    hei: ${props => props?.hei};
    background-color: ${props => props?.bg_color};
`
export const BODY = styled.div`
    padding: ${props => props?.pad};
    background-color: ${props => props?.bg_color}
`

// export const SECTION_ROOT = styled.div`
//     border-bottom: ${props => (props?.is_last === true)
//         ? `3px solid ${props?.border_color}` : ""};
//     padding-bottom: 5px;
//     margin-bottom: 5px;
// `

export const SECTION_ROOT = styled.div`
    border-bottom: ${props => (props?.is_last === true)
        ? `3px solid ${props?.border_color}` : ""};
    padding-bottom: 5px;
    margin-bottom: 5px;
`

export const GENERAL_BLOCK = styled.div`
    padding: ${props => props?.pad};
    background-color: ${props => props?.bg_color};
    color: ${props => props?.clr};
    font-family: ${props => props?.f_family};;
    font-size: ${props => props?.f_size};
    text-transform: ${props => props?.textTrans};
    position: ${props => props?.poss};
    top: ${props => props?.topp};
    left: ${props => props?.leftt};
    right: ${props => props?.rightt};
    z-index: ${props => props?.z_ind};
    color:  ${props => props?.clr};
    margin:  ${props => props?.marg};
    font-size: ${props => props?.f_size};
    font-weight: ${props => props?.wei};
    font-family: ${props => props?.f_family};
    display:  ${props => props?.d_play};
    padding: ${props => props?.pad};
    border-radius: ${props => props?.brad};
    background-color: ${props => props?.bg_color};
    border: ${props => props?.bord == "0" ? 0 : props?.bord};
    border-bottom: ${props => props?.bordBot};
    border-top: ${props => props?.bordTop};
    border-left: ${props => props?.bordLeft};
    min-width: ${props => props?.m_wid};
    width: ${props => props?.wid};
    height: ${props => props?.hei};
    font-style: ${props => props?.f_style};
`

export const STYLED_LINK = styled(Link)`
    height: ${props => props?.hei};
    background: ${props => props?.hei};
    width: 100%;
    color: ${props => props?.clr};
    font-family: ${props => props?.f_family};
`

export const INPUT_PLACEHOLDER = styled.span`
    position: absolute;
    color: #C2BFBA;
    left: 20px;
    top: 0;
    font-size: ${props => props?.f_size};
    transition: .3s all ease-in-out;
`
export const ADD_INPUT = styled.textarea`
    font-style: normal;
    font-weight: ${props => props?.f_weight};
    font-size: ${props => props?.f_size};
    line-height: ${props => props?.l_height};
    color: #000;
    border: 0;
    width: 100%;
    outline: 0;
    resize: none;
    display: flex;
    align-items: center;
    // height: 20px;
    // padding-top: ${props => props?.pad};
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #999;
        opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #999;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #999;
    }
`
export const ADD_INPUT_INPUT = styled.input`
    font-style: normal;
    font-weight: ${props => props?.f_weight};
    font-size: ${props => props?.f_size};
    line-height: ${props => props?.l_height};
    font-family: ${props => props?.f_family};
    color: #000;
    border: 0;
    width: 100%;
    outline: 0;
    resize: none;
    display: flex;
    padding: 0;
    align-items: center;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #999;
        opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #999;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #999;
    }
`
export const BULLET = styled.span`
    font-size: 25px;
    font-weight: bold;
    height: 20px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    font-family: 'montserrat_semibold' !important;
`
export const CALENDAR = styled.span`
    // position: absolute;
    justify-content: flex-end;
    right: 0;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    // z-index: 11111111111111;
    background: white;
    // box-shadow: 0px 3px 5px 1px rgba(181, 181, 181, 0.15)
`

export const CALENDAR_BUTTON = styled.div`
    display: flex;
    background: white;
`

export const SECTION_CONTROL = styled.div`
    position: absolute;
    right: -60px;
    // 65px;
    top: 0px;
    padding: ${props => props?.pad};
    background-color: ${props => props?.bg_color};
    // opacity: 0;
    z-index: 1
`
export const SUB_SECTION_CONTROL = styled.div`
    position: absolute;
    top: 0px;
    padding: ${props => props?.pad};
    background-color: ${props => props?.bg_color};
    z-index: 1
`
export const TEXT = styled.p`
    color:  ${props => props?.clr};
    font-size: ${props => props?.f_size};
    font-family: ${props => props?.f_family};
    font-weight: ${props => props?.f_weight};
    display:  ${props => props?.d_play};
    text-decoration: ${props => props?.t_decor};
    font-feature-settings: 'pnum' on, 'lnum' on;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props?.p_holder_style?.color};
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props?.p_holder_style?.color};
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props?.p_holder_style?.color};
    }
`

export const INPUT = styled.input`
    color:  ${props => props?.clr};
    font-size: ${props => props?.f_size};
    display:  ${props => props?.d_play};
    padding: ${props => props?.pad};
    border-radius: ${props => props?.brad};
    background-color: ${props => props?.bg_color};
    height: ${props => props?.hei};
    border: ${props => props?.bord};
    font-family: ${props => props?.f_family};
    width: 100%;
    outline: 0;
`

export const INPUT_LABEL = styled.span`
    color:  ${props => props?.clr};
    font-size: ${props => props?.f_size};
    display:  ${props => props?.d_play};
    padding: ${props => props?.pad};
    border-radius: ${props => props?.brad};
    background-color: ${props => props?.bg_color};
    font-weight: ${props => props?.f_weight};
    font-family: ${props => props?.f_family};
`
export const EDIT_PANEL_TITLE = styled.span`
    color:  ${props => props?.clr};
    font-size: 16px;
    font-weight: 500;
    display:  ${props => props?.d_play};
    padding: ${props => props?.pad};
    border-radius: ${props => props?.brad};
    background-color: ${props => props?.bg_color};
`

export const BUTTON = styled.button`
    color:  ${props => props?.clr};
    font-size: ${props => props?.f_size};
    font-weight: ${props => props?.wei};
    font-family: ${props => props?.f_family};
    display:  ${props => props?.d_play};
    padding: ${props => props?.pad};
    border-radius: ${props => props?.brad};
    background-color: ${props => props?.dis ? props?.dis : props?.bg_color};
    border: ${props => props?.bord == "0" ? 0 : props?.bord};
    min-width: ${props => props?.m_wid};
    width: ${props => props?.wid};
    height: ${props => props?.hei};
    &:hover {
        color: ${props => props.hov && props.hov?.color};
    }
`

export const LINK_BUTTON = styled.a`
    color:  ${props => props?.clr};
    font-size: ${props => props?.f_size};
    font-weight: ${props => props?.wei};
    font-family: ${props => props?.f_family};
    display:  ${props => props?.d_play};
    padding: ${props => props?.pad};
    border-radius: ${props => props?.brad};
    background-color: ${props => props?.bg_color};
    border: ${props => props?.bord == "0" ? 0 : props?.bord};
    min-width: ${props => props?.m_wid};
    width: ${props => props?.wid};
    height: ${props => props?.hei};
    text-decoration: none;
`

export const AFTER_PAGER = styled.div`
    width: 816px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translate(0, ${props => props.transformMarker});
    div {
        width: 10%;
        padding: 2px 0;
        background: ${props => props.bg_color};
        transform: translate(-100px, 0);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    
    }
    "::after": {
     content: "${props => props?.aft}";
    }
    @media screen and (max-width: 575px) {
        width: 680px;
        div {
            width: 11%;
        }
    }
`
