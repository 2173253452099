import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import arrow_up from "../../images/icons/arrow-up-mob.svg";
import palette_icon from "../../images/icons/palette.svg";
import font_size_icon from "../../images/icons/font-size.svg";
import letter_spacing_icon from "../../images/icons/letter-spacing.svg";
import arrow_left_black from "../../images/icons/arrow-left-black.svg";
import check_icon from "../../images/icons/check.svg";
import { date_format, fonts, font_sizes, spacing } from "../../constants";
import { resume } from "../../recoil/store";
import {
    BackButton, BlueButton, SectionAIContainer,
    SettingsArrow, SettingsButton, SettingsText
} from "../ActionButtons/ActionButtons.Styled";
import { SwatchesPicker } from 'react-color';
import { design_types } from "../../templates";
import { useSaveFunction } from "../../utils/save";
import { calculateTextColorFromRGB } from '../../utils/color-formating';

const ResumeSection = ({ id }) => {

    const [resume_, set_resume] = useRecoilState(resume);
    const [settings, setSettings] = useState(0);
    const [color, setColor] = useState(resume_?.settings?.color || '#fff');

    const saveFunction = useSaveFunction();

    useEffect(() => {
        setColor(resume_?.settings?.color || '#fff');
    }, [resume_]);

    const set_settings = (prop, value) => {
        let copy = { ...resume_ };
        copy.settings = {
            ...copy.settings,
            [prop]: +value
        };
        set_resume(copy)
        saveFunction(id, true);
    };

    const handleColorChange = (color) => {
        const textColor = calculateTextColorFromRGB(color.rgb);
        let copy = { ...resume_ };
        copy.settings = {
            ...copy.settings,
            color: color.hex,
            textColor
        };
        set_resume(copy)
        saveFunction(id, true);
    };

    const handleBack = () => {
        setSettings(0);
    }

    const options = (items, name, currentValue) => {
        return (
            <div style={{ marginTop: 30 }}>
                {items.map(item => (
                    <SettingsButton onClick={() => set_settings(name, item.value)}>
                        <div className="d-flex align-items-center">
                            {(settings === 2 || settings === 3) &&
                                <img src={settings === 2 ? font_size_icon : letter_spacing_icon}
                                    alt="" width={20} height={20} />}
                            <SettingsText style={(settings === 2 || settings === 3) ? { marginLeft: 8 } : {}}>
                                {item.text}
                            </SettingsText>
                        </div>
                        {item.value === currentValue &&
                            <img src={check_icon} alt="check" width={20} height={20} />}
                    </SettingsButton>
                ))}
            </div>
        )
    }

    return (
        <SectionAIContainer>
            {settings === 0 ? <div>
                <SettingsButton onClick={() => setSettings(1)}>
                    <SettingsText>
                        {fonts.find(item => item.value === resume_?.settings?.font).text}
                    </SettingsText>
                    <SettingsArrow src={arrow_up} alt="" width={24} height={24} />
                </SettingsButton>
                <SettingsButton onClick={() => setSettings(2)}>
                    <div className="d-flex align-items-center">
                        <img src={font_size_icon} alt="" width={20} height={20} />
                        <SettingsText style={{ marginLeft: 8 }}>
                            {font_sizes.find(item => item.value === resume_?.settings?.fontSize).text}
                        </SettingsText>
                    </div>
                    <SettingsArrow src={arrow_up} alt="" width={24} height={24} />
                </SettingsButton>
                <SettingsButton onClick={() => setSettings(3)}>
                    <div className="d-flex align-items-center">
                        <img src={letter_spacing_icon} alt="" width={20} height={20} />
                        <SettingsText style={{ marginLeft: 8 }}>
                            {spacing.find(item => item.value === resume_?.settings?.lineSpacing).text}
                        </SettingsText>
                    </div>
                    <SettingsArrow src={arrow_up} alt="" width={24} height={24} />
                </SettingsButton>
                <SettingsButton onClick={() => setSettings(4)}>
                    <SettingsText>
                        {date_format.find(item => item.value === resume_?.settings?.dateFormat).text}
                    </SettingsText>
                    <SettingsArrow src={arrow_up} alt="" width={24} height={24} />
                </SettingsButton>
                <SettingsButton
                    style={{ marginBottom: 30 }}
                    onClick={() => setSettings(5)}>
                    <img id="palette" src={palette_icon} alt="" />
                    <SettingsArrow src={arrow_up} alt="" width={24} height={24} />
                </SettingsButton>
                <BlueButton
                    className='d-flex align-items-center justify-content-center'
                    style={{ width: '100%' }}
                    onClick={() => setSettings(6)}>
                    Change Template
                </BlueButton>
            </div> :
                <BackButton onClick={() => handleBack()} style={{ width: 45 }}>
                    <img src={arrow_left_black} alt="back" />
                </BackButton>}
            {settings === 1 && options(fonts, "font", resume_?.settings?.font)}
            {settings === 2 && options(font_sizes, "fontSize", resume_?.settings?.fontSize)}
            {settings === 3 && options(spacing, "lineSpacing", resume_?.settings?.lineSpacing)}
            {settings === 4 && options(date_format, "dateFormat", resume_?.settings?.dateFormat)}
            {settings === 5 &&
                <div style={{ marginTop: 30 }}>
                    <SwatchesPicker
                        color={color}
                        onChangeComplete={handleColorChange}
                        width="100%"
                        height="100%"
                        styles={{
                            default: {
                                picker: {
                                    width: '100%',
                                    boxShadow: 'none',
                                    padding: '15px'
                                }
                            }
                        }}
                    />
                </div>}
            {settings === 6 && <div style={{ marginTop: 30 }}>
                <SettingsText style={{ fontFamily: 'montserrat_semibold', marginBottom: 20 }}>
                    Change Template
                </SettingsText>
                {
                    design_types?.map(d => (
                        <button
                            key={d?.id}
                            className='mb-4'
                            style={resume_?.designType == d?.id ? { border: "3px solid #4984EA", borderRadius: 15, padding: 5 } : { border: "3px solid #4984ea00", borderRadius: 11.5 }}
                            onClick={() => set_resume({ ...resume_, designType: d?.id })}
                        >
                            <img src={d?.img} alt="" width="100%" style={{ borderRadius: "10px" }} />
                        </button>
                    ))
                }
            </div>}
        </SectionAIContainer>
    )
}
export default ResumeSection;
