import React, { useState, useEffect, useRef } from 'react';
import "./selectbox.css";
import { CircularProgress } from '@mui/material';

const SelectBox = (props) => {
    const { data, setResult, loading, loadingColor, title, f_size, f_family } = props;
    const [selectboxView, setSelectboxView] = useState(false);
    const ref = useRef(null);

    const handleSelectedValue = (event) => {
        const { id } = event.target;
        setResult(id);
        setSelectboxView(false);
    };

    const handleClickOutside = (event) => {
        if (ref && !ref?.current?.contains(event.target)) {
            setSelectboxView(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div
            style={{
                display: "flex",
                padding: props?.padding || "0 10px",
                height: "40px",
                position: "relative",
                minWidth: "100px",
                zIndex: 12,
            }}
            ref={ref}
        >
            <div
                className='select_box_handler'
                style={{ alignSelf: "center", fontSize: f_size, fontFamily: f_family }}
                onClick={() => setSelectboxView(!selectboxView)}
            >
                {loading ? "" : title}
                {loading && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <CircularProgress
                            style={{
                                width: "25px",
                                height: "25px",
                                color: loadingColor,
                            }}
                        />
                    </div>
                )}
            </div>
            {selectboxView && (
                <ul className='select_box'>
                    {data?.length > 0 && data?.map(({ text, value }) => (
                        <li key={text}>
                            <button
                                className={`select_box_item`}
                                id={value}
                                name={text}
                                onClick={handleSelectedValue}
                            >
                                {text}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SelectBox;
