import React from "react";
import ContentEditable from "react-contenteditable";

export class InlineCustom extends React.Component {

    constructor() {
        super()
        this.contentEditable = React.createRef();
        this.state = { html: "", };
    };

    componentDidMount() {
        let resume_copy = { ...this.props.resume };
        let key_ = { ...resume_copy[this.props.r] };
        let array = [...resume_copy[this.props.r].content];
        if (array[this.props.i]?.value || array[this.props.i]?.value === "") {
            this.setState({ html: array[this.props.i]?.value })
        }
        else this.setState({ html: array[this.props.i] })
    }

    handleChange = (value, i, key) => {
        let resume_copy = { ...this.props.resume };
        let key_ = { ...resume_copy[key] };
        let array = [...resume_copy[key].content];
        if (key == "keySkillsCompetencies") {
            array[i] = {
                ...array[i],
                value,
            };
        }
        else array[i] = value;
        key_.content = array;
        resume_copy[key] = key_;
        this.props.set_resume(resume_copy)
        this.setState({ html: value });

    };

    add_new_input = (e, key, i) => {
        const { set_isEnter, set_resume } = this.props;
        const resume_copy = { ...this.props.resume };
        const key_ = { ...resume_copy[key] };
        const array = [...resume_copy[key].content];

        if (e.key === "Enter") {
            set_isEnter(true);

            let newIndex = i + 1;

            if (key === "keySkillsCompetencies") {
                array.splice(newIndex, 0, {
                    value: "",
                    last: true,
                });
            } else {
                array.splice(newIndex, 0, "");
            }

            key_.content = array;
            resume_copy[key] = key_;
            e.preventDefault();
            set_resume(resume_copy);

            // Focus on the newly added element
            const newElementId = `${key}-${newIndex}`;
            const newElement = document.getElementById(newElementId);
            if (newElement) {
                newElement.focus();
            }

        } else if (e.key === "Backspace" && array.length > 1 && !e.currentTarget.textContent) {
            e.preventDefault();

            array.splice(i, 1);
            key_.content = array;
            resume_copy[key] = key_;
            set_resume(resume_copy);

            const focusElement = document.getElementById(`${key}-${i - 1}`);
            if (focusElement) {
                const range = document.createRange();
                const sel = window.getSelection();
                range.setStart(focusElement, focusElement.childNodes.length);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    };


    static getDerivedStateFromProps(nextProps, prevState) {
        let resume_copy = { ...nextProps.resume };
        let array = [...resume_copy[nextProps.r].content];
        if (array[nextProps.i]?.value || array[nextProps.i]?.value === "") {
            if (array[nextProps.i]?.value !== prevState.html) {
                return {
                    html: array[nextProps.i]?.value,
                };
            }
        } else {
            if (array[nextProps.i] !== prevState.html) {
                return { html: array[nextProps.i] };
            }
        }
        return null;
    }

    render = () => {
        return <ContentEditable
            key={this.props.r}
            className="inline_editor"
            style={this.props.style}
            onKeyDown={(e) => this.add_new_input(e, this.props.r, this.props.i, this.props.class)}
            id={`${this.props.r}-${this.props.i}`}
            onFocus={(e) => {
                this.props.set_isEnter(false);
                this.props.set_key(this.props.r)
            }}
            data-placeholder={this.props.r == "keySkillsCompetencies" ? "Add Key Skills & Competencies" : "Enter text (not visible if blank, click Preview)"}
            aria-multiline={false}
            innerRef={this.contentEditable}
            html={this.state.html} // innerHTML of the editable div
            disabled={false}       // use true to disable editing
            onChange={(e) => this.handleChange(e.target.value, this.props.i, this.props.r)} // handle innerHTML change
            tagName='article' // Use a custom HTML tag (uses a div by default)
        />
    };
};

export default InlineCustom