import React, { useEffect, useRef, useState } from 'react'
import "./sidebar.css";
import { useNavigate } from 'react-router-dom';
import arrow_left from "../../images/icons/arrow-left.svg";
import arrow_right from "../../images/icons/arrow-right.svg";
import search_icon from "../../images/icons/search.svg";
import mark_icon from "../../images/icons/mark.svg";
import { SIDEBAR_CONTAINER, DRAWER, SIDEBAR } from "./sidebar.styled"
import { decor, design_types } from '../../templates';
import { BUTTON, EDIT_PANEL_TITLE, INPUT, INPUT_LABEL, TEXT } from '../../styled';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  purchase_modal, resume, selected_section as selected_section_atom, user_info,
  request_save as request_save_
} from '../../recoil/store';
import { enqueueSnackbar } from 'notistack';
import { InlineEditorAsTextarea } from '../InlineEditorAsTextArea';
import { rewriteSummary } from '../../services/rewriteSummary';
import { CircularProgress } from '@mui/material';
import { Types } from 'react-easy-edit';
import { getRecommandationList } from '../../services/getRecommendationList';
import { getAllProfessionsWithTags } from '../../services/tags';
import { toWordCase } from '../../utils/string-formating';
import SelectBox from '../../Component/Selectbox/SelectboxWithSearchbar';

const Sidebar = (props) => {
  let style = props?.style;
  let target = props?.target;
  const navigate = useNavigate();
  let user_info_ = useRecoilValue(user_info);
  const [purchase_modal_, set_purchase_modal] = useRecoilState(purchase_modal);
  const [resume_, set_resume] = useRecoilState(resume);
  const [selected_profession, set_selected_profession] = useState(null);
  const [loading, set_loading] = useState(false);
  const [loaded, set_loaded] = useState(false);
  const [all_professions, set_all_professions] = useState([{}]);
  const [professions, set_professions] = useState([]);
  const [temporary_state_custom, set_temporary_state_custom] = useState({})
  const [temporary_state_pi, set_temporary_state__pi] = useState({ ...resume_?.personalInformation })
  const [temporary_state_qr, set_temporary_state_qr] = useState({ ...resume_?.personalInformation?.qr })
  const [active_bulletType, set_active_bulletType] = useState(4);
  const [summary, set_summary] = useState("");
  const [design_panel, set_design_panel] = useState(true);
  const [temporary_summary, set_temporary_summary] = useState("");
  const [gpt_responses, set_gpt_responses] = useState({});
  const [tags, set_tags] = useState({ list: [] });
  const [reset_tags_slice, set_reset_tags_slice] = useState(true);
  const [request_sent, set_request_sent] = useState(false)
  const [request_save, set_request_save] = useRecoilState(request_save_);
  // Parameters below are used for GPT searches, including storing and keeping history.
  const [selected_section, set_selected_section] = useRecoilState(selected_section_atom);
  const [previous_selected_section, set_previous_selected_section] = useState("");
  const [last_search_values, set_last_search_values] = useState({});
  const [search, set_search] = useState("");

  const searchInputRef = useRef(null);
  let resume_copy = { ...resume_ };

  useEffect(() => {
    getAllProfessions();
  }, [])

  useEffect(() => {
    set_temporary_state__pi({ ...resume_?.personalInformation })
    set_temporary_state_qr({ ...resume_?.personalInformation?.qr })
    if (!loaded && resume_?.professionId) {
      set_selected_profession(resume_?.professionId);
      set_loaded(true);
    }
  }, [resume_])

  useEffect(() => {
    if (previous_selected_section != selected_section?.side_bar) {
      set_previous_selected_section(selected_section?.side_bar);
    }
  }, [selected_section])
  useEffect(() => {
    const sectionKey = previous_selected_section;
    const lastSearchValue = last_search_values[sectionKey];
    const professionId = resume_?.professionId;
    const defaultProfession = all_professions.find(p => p.id == professionId)?.[selected_section?.side_bar] || [];

    set_reset_tags_slice(true);
    if (lastSearchValue?.length > 0) {
      set_search(lastSearchValue);
      if (selected_section?.side_bar === "keySkillsCompetencies") {
        updateTags();
      } else if (gpt_responses[sectionKey]) {
        set_tags({ list: gpt_responses[sectionKey] })
      }
    } else if (selected_section?.side_bar === "keySkillsCompetencies") {
      updateTags();
    } else {
      set_tags(professionId ? { list: defaultProfession } : { list: [] });
      set_search("");
    }
  }, [previous_selected_section]);

  useEffect(() => {
    if (all_professions != null) {
      let professions = all_professions.map(p => ({
        key: p.id,
        value: p.id,
        text: p.name,
        prof_id: p.id,
      }));
      if (resume_?.professionId != null) {
        const defaultProfession = professions.find(p => p.prof_id === resume_.professionId);
        if (defaultProfession) {
          professions = professions.filter(p => p.prof_id !== resume_.professionId);
          professions.unshift({
            key: defaultProfession.key,
            value: defaultProfession.value,
            text: `Default: ${defaultProfession.text}`,
            prof_id: defaultProfession.prof_id,
          });
        }
      }
      set_professions(professions);
    }
  }, [all_professions]);

  useEffect(() => {
    set_gpt_responses((previousGptResponses) => ({
      ...previousGptResponses,
      [previous_selected_section]: null,
    }));
    set_last_search_values((previousValue) => ({
      ...previousValue,
      [previous_selected_section]: "",
    }));
    updateTags();
  }, [selected_profession])

  useEffect(() => {
    set_last_search_values((previousValue) => ({
      ...previousValue,
      [previous_selected_section]: search,
    }));
  }, [search])

  useEffect(() => {
    getData();
  }, [request_sent])

  // Reusable Functions
  function updateTags() {
    let selectedSectionKey = selected_section?.side_bar;
    const profession = all_professions?.find(p => p.id === selected_profession);
    const gptResponses = gpt_responses[selectedSectionKey] || [];
    const allTags = [
      ...gptResponses,
      ...(profession?.tags || []),
    ];
    const lastSearchValue = last_search_values[selectedSectionKey];
    set_tags((previousTags) => {
      let tagSliceEnd = 5;
      if (!user_info_?.isPremium && reset_tags_slice === false) {
        if (previousTags.list.length === 5) {
          tagSliceEnd = 10;
        } else if (previousTags.list.length === 10) {
          tagSliceEnd = 15;
        }
      }
      set_reset_tags_slice(false);
      return {
        list: (!user_info_?.isPremium) ? allTags.slice(0, tagSliceEnd) : allTags
      };
    });
    set_search(profession?.name ?? lastSearchValue);
  }

  async function getAllProfessions() {
    try {
      set_loading(true);
      let response = await getAllProfessionsWithTags();
      set_all_professions(response);
      set_loading(false);
    } catch (error) {
      set_loading(false);
      console.error("An error occurred:", error);
    }
  }

  async function getData() {
    if (request_sent) {
      var searchValue = search?.trim();
      if (searchValue?.length < 3) {
        if (resume_.personalInformation.jobTitle.length >= 3) {
          searchValue = resume_.personalInformation.jobTitle;
        } else {
          set_request_sent(false);
          enqueueSnackbar("Please enter a value into search bar.", { variant: "error" });
          return;
        }
      }
      searchInputRef?.current?.blur();
      let selectedSectionKey = selected_section?.side_bar;
      if (selectedSectionKey?.length > 0) {
        try {
          set_loading(true);
          const response = await getRecommandationList({ type: selectedSectionKey, search: searchValue });
          if (response && response?.result) {
            let parsedList = JSON.parse(response?.result?.content)?.[selectedSectionKey];
            set_gpt_responses((previousGptResponses) => ({
              ...previousGptResponses,
              [selectedSectionKey]: [
                ...parsedList,
                ...(previousGptResponses[selectedSectionKey] || []),
              ],
            }));
            set_tags((previousTags) => ({
              list: [...parsedList, ...previousTags.list],
            }));
            set_loading(false);
            set_request_sent(false);
          }
        } catch (error) {
          set_loading(false);
          set_request_sent(false);
          if (error.response && error.response.status === 403) {
            set_purchase_modal(true);
          } else {
            enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" });
          }
        }
      }
    }
  }

  const handle_selected_tags = (tag, key) => {
    let index = selected_section.index;
    if (key === "keySkillsCompetencies") {
      let filteredContent = [];
      let rawContent = [...(resume_copy[key]?.content || [])];
      filteredContent = rawContent.filter(c => c?.value !== "");
      const tagExists = filteredContent.some(tg => tg?.value === tag);
      tagExists
        ? (filteredContent = filteredContent.filter(tg => tg?.value !== tag))
        : filteredContent.push({ value: tag });
      resume_copy[key] = { ...resume_copy[key], content: filteredContent };
      if (filteredContent.length === 0) {
        filteredContent.push("");
      }
    } else if (["workExperience", "internship"].includes(key)) {
      let items = [...resume_copy[key]?.items];
      const currentItem = { ...items[index] };
      const tagExists = currentItem.content.includes(tag);
      const escapedTag = tag.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      tagExists
        ? (currentItem.content = currentItem.content.replace(new RegExp(escapedTag, 'gi'), ""))
        : (currentItem.content += `<ul><li>${tag}</li>`);
      items[index] = currentItem;
      resume_copy[key] = { ...resume_copy[key], items };
    } else if (["certifications", "professionalAffiliations", "achievements"].includes(key)) {
      let rawContent = resume_copy[key]?.content || "";
      const tagExists = rawContent.includes(tag);
      const escapedTag = tag.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const updatedContent = tagExists
        ? rawContent.replace(new RegExp(escapedTag, 'gi'), "")
        : `${rawContent}<ul><li>${tag}</li>`;
      resume_copy[key] = { ...resume_copy[key], content: updatedContent };
    }
    set_resume(resume_copy);
  };

  const tag_is_used = (tag, key) => {
    const index = selected_section.index;
    const rawContent = resume_copy[key]?.content || [];
    if (key === "keySkillsCompetencies") {
      return rawContent.some(c => c?.value === tag);
    } else if (["workExperience", "internship"].includes(key)) {
      const currentItem = (resume_copy[key]?.items || [])[index] || {};
      return currentItem.content?.includes(tag);
    } else if (["certifications", "professionalAffiliations",
      "achievements"].includes(key)) {
      return resume_copy[key]?.content?.includes(tag);
    }
    return false;
  };

  const handle_custom_section = (e) => {
    let { value, name } = e.target;
    let resume_copy = JSON.parse(JSON.stringify(resume_));
    let copy_name = resume_copy[selected_section?.side_bar].name = value;
    set_temporary_state_custom(resume_copy)
  }

  const add_custom = (e) => {
    set_resume(temporary_state_custom)
    set_request_save(true);
    enqueueSnackbar("Custom section successfully updated", { variant: "success" })
  }
  const handle_pi = (e) => {
    let { value, name } = e.target;
    set_temporary_state__pi({ ...temporary_state_pi, [name]: value })
  }

  const save_personal = () => {
    let res = { ...resume_ };
    res["personalInformation"] = {
      ...res["personalInformation"],
      ...temporary_state_pi
    };
    set_resume(res);
    set_request_save(true);
  }
  function change_bulletType_sidebar(key, bulletType) {
    let resume_copy = { ...resume_ };
    let key_ = { ...resume_copy[key] };
    key_.bulletType = bulletType;
    resume_copy[key] = key_
    set_resume(resume_copy);
    set_active_bulletType(bulletType);
  }

  const get_summary = async () => {
    if (summary?.length < 100) {
      enqueueSnackbar("Please enter at least 100 characters.", { variant: "warning" });
    } else {
      set_loading(true);
      try {
        let response = await rewriteSummary(summary);
        if (response && response?.result) {
          set_temporary_summary(response?.result?.content);
          set_loading(false);
        }
      } catch (error) {
        set_loading(false);
        if (error.response && error.response.status === 403) {
          set_purchase_modal(true);
        } else {
          enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" });
        }
      }
    }
  };

  const save_summary = () => {
    resume_copy = {
      ...resume_copy,
      summary: {
        ...resume_copy["summary"],
        content: temporary_summary
      }
    }
    set_resume(resume_copy)
    set_request_save(true)
  }

  const handle_qr = (e) => {
    let { name, value } = e.target;
    set_temporary_state_qr({ ...temporary_state_qr, [name]: value })
  }

  const add_qr = () => {
    let res = { ...resume_ };
    let qr = { ...res.personalInformation.qr };
    qr = {
      ...qr,
      ...temporary_state_qr
    };
    res.personalInformation = {
      ...res.personalInformation,
      qr
    }
    set_resume(res);
    set_request_save(true);
    enqueueSnackbar("QR Code successfully generated", { variant: "success" });
  }

  useEffect(() => {
    set_active_bulletType(resume_[selected_section?.side_bar]?.bulletType)
  }, [resume_, selected_section?.side_bar]);
  return (
    <>
      <>
        {
          target == "edit_panel" && selected_section?.side_bar !== "" ? (
            <SIDEBAR>
              <SIDEBAR_CONTAINER
                right={style?.right_sidebar_style?.right}
                wid={style?.left_sidebar_style?.width}
                pad="18px"
                scrol={"scroll"}
              >
                <DRAWER
                  brad="6px 0px 0px 6px"
                  left={style?.right_drawer_style?.left}
                  onClick={(e) => {
                    // TODO: Fix editor / toolbar toggling of on click.
                    e.nativeEvent.stopPropagation();
                    set_selected_section({ ...selected_section, side_bar: "" });
                  }}
                ><img src={arrow_right} alt="" /></DRAWER>
                {
                  selected_section?.side_bar == "QR" ?
                    <div>
                      <EDIT_PANEL_TITLE>{selected_section?.side_bar}</EDIT_PANEL_TITLE>
                      <div className='mt-3'>
                        <INPUT_LABEL>
                          Frame Label
                        </INPUT_LABEL>
                        <INPUT
                          key={previous_selected_section}
                          className='mt-2'
                          pad="0 16px"
                          bg_color="#EFF1F3"
                          brad="6px"
                          hei="38px"
                          name="frameLabel"
                          value={temporary_state_qr?.frameLabel}
                          placeholder={decor.frame_label.placeholder}
                          onChange={handle_qr}
                          bord="0"
                        />
                      </div>
                      <div className='mt-3'>
                        <INPUT_LABEL>
                          URL
                        </INPUT_LABEL>
                        <INPUT
                          key={previous_selected_section}
                          className='mt-2'
                          pad="0 16px"
                          bg_color="#EFF1F3"
                          brad="6px"
                          hei="38px"
                          name="url"
                          value={temporary_state_qr?.url}
                          placeholder={decor.URL.placeholder}
                          onChange={handle_qr}
                          bord="0"
                        />
                      </div>
                      <div className='tar mt-5'>
                        <BUTTON
                          bg_color="#405595"
                          clr="#fff"
                          brad="6px"
                          pad="10px 40px"
                          bord="0"
                          onClick={add_qr}
                        >
                          Save
                        </BUTTON>
                      </div>
                    </div>
                    :
                    Object.keys(resume_)?.map(r => (
                      (selected_section?.side_bar == r) ?
                        (
                          selected_section?.side_bar?.includes("custom") ?
                            <div>
                              <EDIT_PANEL_TITLE
                                className='mb-2 mt-2'
                                d_play="block"
                              >CUSTOM SECTION</EDIT_PANEL_TITLE>
                              <div className='mt-3'>
                                <INPUT
                                  key={previous_selected_section}
                                  pad="0 16px"
                                  bg_color="#EFF1F3"
                                  brad="6px"
                                  hei="38px"
                                  name={r}
                                  placeholder="Enter name"
                                  onChange={handle_custom_section}
                                  bord="0"
                                />
                              </div>
                              <div className='tar mt-4'>
                                <BUTTON
                                  bg_color="#405595"
                                  clr="#fff"
                                  brad="6px"
                                  pad="10px 40px"
                                  bord="0"
                                  onClick={add_custom}
                                >
                                  Save
                                </BUTTON>
                              </div>
                            </div>
                            :
                            <div>
                              <EDIT_PANEL_TITLE
                                d_play="block"
                              >
                                {toWordCase(decor[selected_section?.side_bar]?.label)}
                              </EDIT_PANEL_TITLE>
                              {
                                ["keySkillsCompetencies", "trainingCourses", "achievements", "professionalAffiliations",
                                  "certifications", "workExperience", "internship"].includes(selected_section.side_bar) ?
                                  <>
                                    {
                                      (selected_section.side_bar === "keySkillsCompetencies" || selected_section.side_bar === "trainingCourses") && (
                                        <>
                                          <div className='mt-4'>Show as</div>
                                          <div className='d-flex mt-2'>
                                            {selected_section.side_bar === "keySkillsCompetencies" && (
                                              <>
                                                <BUTTON
                                                  bg_color="#EFF1F3"
                                                  clr="#000"
                                                  brad="6px"
                                                  pad="7px 5px"
                                                  bord="0"
                                                  f_size="11px"
                                                  wid="32%"
                                                  onClick={() => change_bulletType_sidebar(r, 1)}
                                                >
                                                  Bullets 2
                                                  {active_bulletType === 1 && <img src={mark_icon} alt="" className='ms-2' />}
                                                </BUTTON>
                                                <BUTTON
                                                  bg_color="#EFF1F3"
                                                  clr="#000"
                                                  brad="6px"
                                                  pad="7px 5px"
                                                  bord="0"
                                                  f_size="11px"
                                                  className='ms-1'
                                                  wid="32%"
                                                  onClick={() => change_bulletType_sidebar(r, 2)}
                                                >
                                                  Bullets 3
                                                  {active_bulletType === 2 && <img src={mark_icon} alt="" className='ms-2' />}
                                                </BUTTON>
                                                <BUTTON
                                                  bg_color="#EFF1F3"
                                                  clr="#000"
                                                  brad="6px"
                                                  pad="7px 5px"
                                                  bord="0"
                                                  f_size="11px"
                                                  className='ms-1'
                                                  wid="32%"
                                                  onClick={() => change_bulletType_sidebar(r, 3)}
                                                >
                                                  Pills
                                                  {active_bulletType === 3 && <img src={mark_icon} alt="" className='ms-2' />}
                                                </BUTTON>
                                                <BUTTON
                                                  bg_color="#EFF1F3"
                                                  clr="#000"
                                                  brad="6px"
                                                  pad="7px 5px"
                                                  bord="0"
                                                  f_size="11px"
                                                  className='ms-1'
                                                  wid="32%"
                                                  onClick={() => change_bulletType_sidebar(r, 4)}
                                                >
                                                  Normal
                                                  {active_bulletType === 4 && <img src={mark_icon} alt="" className='ms-2' />}
                                                </BUTTON>
                                              </>
                                            )}
                                            {selected_section.side_bar === "trainingCourses" && (
                                              <>
                                                <BUTTON
                                                  bg_color="#EFF1F3"
                                                  clr="#000"
                                                  brad="6px"
                                                  pad="7px 5px"
                                                  bord="0"
                                                  f_size="11px"
                                                  wid="50%"
                                                  onClick={() => change_bulletType_sidebar(r, 0)}
                                                >
                                                  Bullets 1
                                                  {active_bulletType === 0 && <img src={mark_icon} alt="" className='ms-2' />}
                                                </BUTTON>
                                                <BUTTON
                                                  bg_color="#EFF1F3"
                                                  clr="#000"
                                                  brad="6px"
                                                  pad="7px 5px"
                                                  bord="0"
                                                  f_size="11px"
                                                  className='ms-1'
                                                  wid="50%"
                                                  onClick={() => change_bulletType_sidebar(r, 1)}
                                                >
                                                  Bullets 2
                                                  {active_bulletType === 1 && <img src={mark_icon} alt="" className='ms-2' />}
                                                </BUTTON>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )
                                    }
                                    <div className='position-relative'>
                                      {
                                        selected_section.side_bar === "keySkillsCompetencies" ? (
                                          <SelectBox
                                            id="selectBox"
                                            style={{
                                              marginTop: "5px",
                                              height: "40px",
                                              position: "relative",
                                            }}
                                            selected_value={selected_profession}
                                            search_value={search}
                                            data={professions}
                                            staticSearch={false}
                                            setResult={(_, prof_id) => {
                                              set_reset_tags_slice(true);
                                              set_selected_profession(prof_id);
                                            }}
                                            onSearch={(value) => {
                                              set_selected_profession(null);
                                              set_search(value);
                                              set_request_sent(true);
                                            }}
                                            onInputChange={(value) => {
                                              set_search(value);
                                            }}
                                            isLoading={loading}
                                          />) : selected_section.side_bar !== "trainingCourses" ? (
                                            <>
                                              <INPUT
                                                key={previous_selected_section}
                                                ref={searchInputRef}
                                                className='mt-2'
                                                pad="0 16px"
                                                bg_color="#EFF1F3"
                                                brad="6px"
                                                hei="38px"
                                                name={r}
                                                value={search}
                                                onChange={(e) => set_search(e.target.value)}
                                                onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                    set_request_sent(true);
                                                  }
                                                }}
                                                placeholder="Enter your Job Title or Profession"
                                                bord="0"
                                                autoComplete="off"
                                              />
                                              <img
                                                src={search_icon}
                                                alt=""
                                                style={{
                                                  position: "absolute",
                                                  right: "10px",
                                                  top: "18px",
                                                  cursor: (search?.length ?? 0) <= 3 ? "auto" : "pointer",
                                                  opacity: (search?.length ?? 0) <= 3 ? "0.2" : "1"
                                                }}
                                                onClick={() => {
                                                  if ((search?.length ?? 0) > 3) {
                                                    set_request_sent(true);
                                                  }
                                                }}
                                              />
                                            </>
                                          ) : null
                                      }
                                    </div>
                                    {
                                      (selected_section.side_bar !== "trainingCourses") &&
                                      <EDIT_PANEL_TITLE d_play="block" className='mt-4 mb-2'>
                                        {
                                          tags.list?.length > 0 && (selected_section.side_bar == "keySkillsCompetencies" ? "RECOMMENDED SKILLS" : "RECOMMENDED")
                                        }
                                      </EDIT_PANEL_TITLE>
                                    }
                                    {(selected_section.side_bar !== "trainingCourses") &&
                                      <div className='tags_container' style={{ marginBottom: "12px" }}>
                                        {
                                          loading ? <CircularProgress style={{ width: "36px", height: "36px", margin: "12px", color: "#b8dbff" }} /> :
                                            (tags?.list?.length > 0) && tags?.list?.map((t, i) => (
                                              <BUTTON
                                                bg_color={tag_is_used(t, selected_section.side_bar) ? "#62C584" : "#EFF1F3"}
                                                hov="#62c5847d"
                                                clr={tag_is_used(t, selected_section.side_bar) ? "#fff" : "#000"}
                                                brad="6px"
                                                pad="6px 8px"
                                                bord="0"
                                                f_size="11px"
                                                className={`mb-2 ${t?.length < 28 ? "me-2" : ""} d-flex tal`}
                                                onClick={() => handle_selected_tags(t, selected_section.side_bar)}
                                              >
                                                {
                                                  tag_is_used(t, selected_section.side_bar) ?
                                                    <div className='me-2'>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 12 12" style={{ display: 'block', margin: 'auto', transform: 'translate(2px, 1.5px)' }}>
                                                        <g clipPath="url(#clip0_531_14569)">
                                                          <path d="M5.24971 9.43203L3.22555 7.40787C2.99805 7.18037 2.63055 7.18037 2.40305 7.40787C2.17555 7.63537 2.17555 8.00287 2.40305 8.23037L4.84138 10.6687C5.06888 10.8962 5.43638 10.8962 5.66388 10.6687L11.8355 4.49703C12.063 4.26953 12.063 3.90203 11.8355 3.67453C11.608 3.44703 11.2405 3.44703 11.013 3.67453L5.24971 9.43203Z" fill="white" />
                                                        </g>
                                                        <defs>
                                                          <clipPath id="clip0_531_14569">
                                                            <rect width="12" height="12" fill="white" />
                                                          </clipPath>
                                                        </defs>
                                                      </svg>
                                                    </div>
                                                    :
                                                    <div className='me-2'>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" style={{ display: 'block', margin: 'auto', transform: 'translate(2px, 3px)' }}>
                                                        <path d="M8.49935 5.58366H5.58268V8.50033C5.58268 8.82116 5.32018 9.08366 4.99935 9.08366C4.67852 9.08366 4.41602 8.82116 4.41602 8.50033V5.58366H1.49935C1.17852 5.58366 0.916016 5.32116 0.916016 5.00033C0.916016 4.67949 1.17852 4.41699 1.49935 4.41699H4.41602V1.50033C4.41602 1.17949 4.67852 0.916992 4.99935 0.916992C5.32018 0.916992 5.58268 1.17949 5.58268 1.50033V4.41699H8.49935C8.82018 4.41699 9.08268 4.67949 9.08268 5.00033C9.08268 5.32116 8.82018 5.58366 8.49935 5.58366Z" fill="#1C1C1C" />
                                                      </svg>
                                                    </div>

                                                }
                                                {t}
                                              </BUTTON>
                                            ))
                                        }
                                      </div>}
                                    {
                                      (selected_section.side_bar !== "trainingCourses") &&
                                      !loading && tags?.list?.length > 0 &&
                                      <div style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center"
                                      }}>
                                        <BUTTON
                                          clr="#fff"
                                          bord="0"
                                          f_family="montserrat_semibold"
                                          pad="8px 0"
                                          bg_color='#4984EA'
                                          brad="6px"
                                          m_wid="30%"
                                          onClick={() => {
                                            if (!user_info_?.isPremium) {
                                              if (tags.list.length == 15 ||
                                                selected_section.side_bar != "keySkillsCompetencies") {
                                                set_purchase_modal(true);
                                              } else {
                                                updateTags();
                                              }
                                            } else {
                                              set_request_sent(true)
                                            }
                                          }}
                                        >
                                          More
                                        </BUTTON>
                                      </div>
                                    }
                                  </> :
                                  selected_section?.side_bar == "personalInformation" ?
                                    <>
                                      <>
                                        {
                                          !resume_["linkedin"] &&
                                          <div>
                                            {
                                              Object.keys(resume_["personalInformation"])?.map(v => (
                                                v !== "qr" &&
                                                <div className='mt-3'>
                                                  <INPUT_LABEL
                                                    d_play="block"
                                                    f_size="12px"
                                                  >{decor[v]?.label}</INPUT_LABEL>
                                                  <INPUT
                                                    key={previous_selected_section}
                                                    className='mt-2'
                                                    pad="0 16px"
                                                    bg_color="#EFF1F3"
                                                    brad="6px"
                                                    hei="32px"
                                                    name={v}
                                                    value={temporary_state_pi[v]}
                                                    placeholder={decor[v]?.placeholder}
                                                    onChange={handle_pi}
                                                    bord="0"
                                                  />
                                                </div>
                                              ))
                                            }
                                          </div>
                                        }
                                      </>
                                    </> : selected_section?.side_bar == "summary" ?
                                      <>
                                        <InlineEditorAsTextarea
                                          style={{
                                            minWidth: "30px",
                                            maxWidth: "628px",
                                            marginRight: "0px",
                                            outline: "none",
                                            minHeight: "260px",
                                            maxHeight: temporary_summary?.length > 500 ? '330px' : "unset",
                                            overflowY: temporary_summary?.length > 500 ? 'scroll' : "unset",
                                            borderRadius: "5px",
                                            color: summary?.length > 0 ? "black" : "#999"
                                          }}
                                          value={summary}
                                          class="summary_insert"
                                          set_value={set_summary}
                                          type={Types.TEXT}
                                          attributes={{ name: "awesome-input", id: 1 }}
                                          placeholder="Write here about you, for example: «Drilling engineer with 12 years in oil and gas industry, 
                                          offshore experience, drilled vertical and deviated wells (J, S, Horizontals), 
                                          H2S experience, shallow and deep measure depths (MD) up to 6000 m, 
                                          saved $120,000 and reduced the well construction time by 15% by introducing new types of PDC drill bits»
                                          *Please enter at least 100 characters."
                                        />
                                        <div className='tar mt-3 d-flex jce'>
                                          <BUTTON
                                            bg_color="#405595"
                                            clr="#fff"
                                            brad="6px"
                                            pad="10px 40px"
                                            bord="0"
                                            hei="40px"
                                            className='d-flex aic jcc'
                                            onClick={get_summary}
                                            width={loading ? "135px" : "100%"}
                                          >
                                            {loading ? "" : "Create"} {loading &&
                                              <CircularProgress style={{ width: "24px", height: "24px", margin: "12px", color: "#b8dbff" }} />}
                                          </BUTTON>
                                        </div>
                                        {
                                          <div
                                            style={{
                                              minWidth: "30px",
                                              maxWidth: "628px",
                                              marginTop: "25px",
                                              marginRight: "0px",
                                              outline: "none",
                                              minHeight: "260px",
                                              maxHeight: temporary_summary?.length > 500 ? '330px' : "unset",
                                              overflowY: temporary_summary?.length > 500 ? 'scroll' : "unset",
                                              borderRadius: "5px",
                                              color: temporary_summary?.length > 0 ? "black" : "#999"
                                            }}
                                            className={`inline_editor ${"summary_result"}`}
                                          >
                                            {temporary_summary || "The rewritten AI result will appear here."}
                                          </div>
                                        }
                                      </> : null
                              }
                              {selected_section?.side_bar == "personalInformation" &&
                                <div className='tar mt-3'>
                                  <BUTTON
                                    bg_color="#405595"
                                    clr="#fff"
                                    brad="6px"
                                    pad="10px 40px"
                                    bord="0"
                                    onClick={save_personal}
                                  >
                                    Save
                                  </BUTTON>
                                </div>
                              }
                              {
                                temporary_summary !== "" &&
                                selected_section?.side_bar === "summary" &&
                                <div className='tar mt-3'>
                                  <BUTTON
                                    bg_color="#405595"
                                    clr="#fff"
                                    brad="6px"
                                    pad="10px 40px"
                                    bord="0"
                                    onClick={save_summary}
                                  >
                                    Add
                                  </BUTTON>
                                </div>
                              }
                            </div>
                        ) : null
                    ))
                }
              </SIDEBAR_CONTAINER>
            </SIDEBAR >
          ) : target == "template_design_panel" && (
            <div className='' style={{
              width: design_panel ? "360px" : "0",
              position: "fixed",
              paddingTop: "140px",
              top: "0",
              left: "0px",
              background: "rgb(255, 255, 255)",
              height: "100%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)"
            }} >
              {
                <SIDEBAR_CONTAINER
                  wid={design_panel ? "360px" : "0"}
                  pad={design_panel ? "55px 30px 25px 28px" : "0"}
                  className='text-center'
                  scrol={"scroll"}
                >
                  < DRAWER
                    brad="0px 6px 6px 0px"
                    right="-58px"
                    onClick={() => set_design_panel(!design_panel)}
                  ><img src={design_panel ? arrow_left : arrow_right} alt="" /></DRAWER>
                  <div style={{
                    display: design_panel ? "flex" : "none",
                    flexDirection: "column",
                  }}>
                    <TEXT
                      f_family="montserrat_semibold"
                      className='mb-4'
                    >Choose your design</TEXT>
                    {
                      design_types?.map(d => (
                        <BUTTON
                          key={d?.id}
                          pad="4px"
                          bord={resume_?.designType == d?.id ? "2px solid #4984EA" : "2px solid #4984ea00"}
                          bg_color="transparent"
                          brad="14px"
                          className='mb-3'
                          onClick={() => set_resume({ ...resume_, designType: d?.id })}
                        >
                          <img src={d?.img} alt="" width="100%" style={{ borderRadius: "10px" }} />
                        </BUTTON>
                      ))
                    }
                  </div>
                </SIDEBAR_CONTAINER>
              }
            </div>
          )
        }
      </ >
    </>
  )
}

export default Sidebar