import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    resume, selected_section as selected_section_atom,
    request_save as request_save_,
    user_info,
    purchase_modal
} from '../../recoil/store';
import search_icon from "../../images/icons/search.svg";
import mark_icon from "../../images/icons/mark.svg";
import { BUTTON, EDIT_PANEL_TITLE, INPUT } from "../../styled";
import SelectBox from "../Selectbox/SelectboxWithSearchbar";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { getAllProfessionsWithTags } from "../../services/tags";
import { getRecommandationList } from "../../services/getRecommendationList";
import { SectionAIContainer } from "../ActionButtons/ActionButtons.Styled";

const SectionAI = () => {

    const [resume_, set_resume] = useRecoilState(resume);
    let user_info_ = useRecoilValue(user_info);
    const [purchase_modal_, set_purchase_modal] = useRecoilState(purchase_modal);
    const [selected_profession, set_selected_profession] = useState(null);
    const [loading, set_loading] = useState(false);
    const [loaded, set_loaded] = useState(false);
    const [all_professions, set_all_professions] = useState([{}]);
    const [professions, set_professions] = useState([]);
    const [temporary_state_pi, set_temporary_state__pi] = useState({ ...resume_?.personalInformation })
    const [temporary_state_qr, set_temporary_state_qr] = useState({ ...resume_?.personalInformation?.qr })
    const [active_bulletType, set_active_bulletType] = useState(4);
    const [gpt_responses, set_gpt_responses] = useState({});
    const [tags, set_tags] = useState({ list: [] });
    const [reset_tags_slice, set_reset_tags_slice] = useState(true);
    const [request_sent, set_request_sent] = useState(false)

    // Parameters below are used for GPT searches, including storing and keeping history.
    const [selected_section, set_selected_section] = useRecoilState(selected_section_atom);
    const [previous_selected_section, set_previous_selected_section] = useState("");
    const [last_search_values, set_last_search_values] = useState({});
    const [search, set_search] = useState("");
    const searchInputRef = useRef(null);
    let resume_copy = { ...resume_ };

    // Disables zoom functionality
    useEffect(() => {
        const handleTouchMove = (event) => {
            if (event.scale !== 1) {
                event.preventDefault();
            }
        };
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        return () => {
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    useEffect(() => {
        getAllProfessions();
    }, [])

    useEffect(() => {
        set_temporary_state__pi({ ...resume_?.personalInformation })
        set_temporary_state_qr({ ...resume_?.personalInformation?.qr })
        if (!loaded && resume_?.professionId) {
            set_selected_profession(resume_?.professionId);
            set_loaded(true);
        }
    }, [resume_])

    useEffect(() => {
        if (previous_selected_section != selected_section?.side_bar) {
            set_previous_selected_section(selected_section?.side_bar);
        }
    }, [selected_section])

    useEffect(() => {
        const sectionKey = previous_selected_section;
        const lastSearchValue = last_search_values[sectionKey];
        const professionId = resume_?.professionId;
        const defaultProfession = all_professions.find(p => p.id == professionId)?.[selected_section?.side_bar] || [];
        set_reset_tags_slice(true);
        if (lastSearchValue?.length > 0) {
            set_search(lastSearchValue);
            if (selected_section?.side_bar === "keySkillsCompetencies") {
                updateTags();
            } else if (gpt_responses[sectionKey]) {
                set_tags({ list: gpt_responses[sectionKey] })
            }
        } else if (selected_section?.side_bar === "keySkillsCompetencies") {
            updateTags();
        } else {
            set_tags(professionId ? { list: defaultProfession } : { list: [] });
            set_search("");
        }
    }, [previous_selected_section, all_professions]);

    useEffect(() => {
        if (all_professions != null) {
            let professions = all_professions.map(p => ({
                key: p.id,
                value: p.id,
                text: p.name,
                prof_id: p.id,
            }));
            if (resume_?.professionId != null) {
                const defaultProfession = professions.find(p => p.prof_id === resume_.professionId);
                if (defaultProfession) {
                    professions = professions.filter(p => p.prof_id !== resume_.professionId);
                    professions.unshift({
                        key: defaultProfession.key,
                        value: defaultProfession.value,
                        text: `Default: ${defaultProfession.text}`,
                        prof_id: defaultProfession.prof_id,
                    });
                }
            }
            set_professions(professions);
        }
    }, [all_professions]);

    useEffect(() => {
        set_gpt_responses((previousGptResponses) => ({
            ...previousGptResponses,
            [previous_selected_section]: null,
        }));
        set_last_search_values((previousValue) => ({
            ...previousValue,
            [previous_selected_section]: "",
        }));
        updateTags();
    }, [selected_profession])

    useEffect(() => {
        set_last_search_values((previousValue) => ({
            ...previousValue,
            [previous_selected_section]: search,
        }));
    }, [search])

    useEffect(() => {
        getData();
    }, [request_sent])

    // Reusable Functions
    function updateTags() {
        let selectedSectionKey = selected_section?.side_bar;
        const profession = all_professions?.find(p => p.id === selected_profession);
        const gptResponses = gpt_responses[selectedSectionKey] || [];
        const allTags = [
            ...gptResponses,
            ...(profession?.tags || []),
        ];
        const lastSearchValue = last_search_values[selectedSectionKey];
        set_tags((previousTags) => {
            let tagSliceEnd = 5;
            if (!user_info_?.isPremium && reset_tags_slice === false) {
                if (previousTags.list.length === 5) {
                    tagSliceEnd = 10;
                } else if (previousTags.list.length === 10) {
                    tagSliceEnd = 15;
                }
            }
            set_reset_tags_slice(false);
            return {
                list: (!user_info_?.isPremium) ? allTags.slice(0, tagSliceEnd) : allTags
            };
        });
        set_search(profession?.name ?? lastSearchValue);
    }

    async function getAllProfessions() {
        try {
            set_loading(true);
            let response = await getAllProfessionsWithTags();
            set_all_professions(response);
            set_loading(false);
        } catch (error) {
            set_loading(false);
            console.error("An error occurred:", error);
        }
    }

    async function getData() {
        if (request_sent) {
            var searchValue = search?.trim();
            if (searchValue?.length < 3) {
                if (resume_.personalInformation.jobTitle.length >= 3) {
                    searchValue = resume_.personalInformation.jobTitle;
                } else {
                    set_request_sent(false);
                    enqueueSnackbar("Please enter a value into search bar.", { variant: "error" });
                    return;
                }
            }
            searchInputRef?.current?.blur();
            let selectedSectionKey = selected_section?.side_bar;
            if (selectedSectionKey?.length > 0) {
                try {
                    set_loading(true);
                    const response = await getRecommandationList({ type: selectedSectionKey, search: searchValue });
                    if (response && response?.result) {
                        let parsedList = JSON.parse(response?.result?.content)?.[selectedSectionKey];
                        set_gpt_responses((previousGptResponses) => ({
                            ...previousGptResponses,
                            [selectedSectionKey]: [
                                ...parsedList,
                                ...(previousGptResponses[selectedSectionKey] || []),
                            ],
                        }));
                        set_tags((previousTags) => ({
                            list: [...parsedList, ...previousTags.list],
                        }));
                        set_loading(false);
                        set_request_sent(false);
                    }
                } catch (error) {
                    set_loading(false);
                    set_request_sent(false);
                    if (error.response && error.response.status === 403) {
                        set_purchase_modal(true);
                    } else {
                        enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" });
                    }
                }
            }
        }
    }

    const handle_selected_tags = (tag, key) => {
        let index = selected_section.index;
        if (key === "keySkillsCompetencies") {
            let filteredContent = [];
            let rawContent = [...(resume_copy[key]?.content || [])];
            filteredContent = rawContent.filter(c => c?.value !== "");
            const tagExists = filteredContent.some(tg => tg?.value === tag);
            tagExists
                ? (filteredContent = filteredContent.filter(tg => tg?.value !== tag))
                : filteredContent.push({ value: tag });
            resume_copy[key] = { ...resume_copy[key], content: filteredContent };
            if (filteredContent.length === 0) {
                filteredContent.push("");
            }
        } else if (["workExperience", "internship"].includes(key)) {
            let items = [...resume_copy[key]?.items];
            const currentItem = { ...items[index] };
            const tagExists = currentItem.content.includes(tag);
            const escapedTag = tag.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            tagExists
                ? (currentItem.content = currentItem.content.replace(new RegExp(escapedTag, 'gi'), ""))
                : (currentItem.content += `<ul><li>${tag}</li>`);
            items[index] = currentItem;
            resume_copy[key] = { ...resume_copy[key], items };
        } else if (["certifications", "professionalAffiliations", "achievements"].includes(key)) {
            let rawContent = resume_copy[key]?.content || "";
            const tagExists = rawContent.includes(tag);
            const escapedTag = tag.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const updatedContent = tagExists
                ? rawContent.replace(new RegExp(escapedTag, 'gi'), "")
                : `${rawContent}<ul><li>${tag}</li>`;
            resume_copy[key] = { ...resume_copy[key], content: updatedContent };
        }
        set_resume(resume_copy);
    };

    const tag_is_used = (tag, key) => {
        const index = selected_section.index;
        const rawContent = resume_copy[key]?.content || [];
        if (key === "keySkillsCompetencies") {
            return rawContent.some(c => c?.value === tag);
        } else if (["workExperience", "internship"].includes(key)) {
            const currentItem = (resume_copy[key]?.items || [])[index] || {};
            return currentItem.content?.includes(tag);
        } else if (["certifications", "professionalAffiliations",
            "achievements"].includes(key)) {
            return resume_copy[key]?.content?.includes(tag);
        }
        return false;
    };
    function change_bulletType_sidebar(key, bulletType) {
        let resume_copy = { ...resume_ };
        let key_ = { ...resume_copy[key] };
        key_.bulletType = bulletType;
        resume_copy[key] = key_
        set_resume(resume_copy);
        set_active_bulletType(bulletType);
    }

    useEffect(() => {
        set_active_bulletType(resume_[selected_section?.side_bar]?.bulletType)
    }, [resume_, selected_section?.side_bar]);

    return (
        <SectionAIContainer>
            {
                (selected_section.side_bar === "keySkillsCompetencies" || selected_section.side_bar === "trainingCourses") && (
                    <>
                        <div className='mt-4'>Show as</div>
                        <div className='d-flex mt-2'>
                            {selected_section.side_bar === "keySkillsCompetencies" && (
                                <>
                                    <BUTTON
                                        bg_color="#EFF1F3"
                                        clr="#000"
                                        brad="6px"
                                        pad="7px 5px"
                                        bord="0"
                                        f_size="11px"
                                        wid="32%"
                                        onClick={() => change_bulletType_sidebar(selected_section.side_bar, 1)}
                                    >
                                        Bullets 2
                                        {active_bulletType === 1 && <img src={mark_icon} alt="" className='ms-2' />}
                                    </BUTTON>
                                    <BUTTON
                                        bg_color="#EFF1F3"
                                        clr="#000"
                                        brad="6px"
                                        pad="7px 5px"
                                        bord="0"
                                        f_size="11px"
                                        className='ms-1'
                                        wid="32%"
                                        onClick={() => change_bulletType_sidebar(selected_section.side_bar, 2)}
                                    >
                                        Bullets 3
                                        {active_bulletType === 2 && <img src={mark_icon} alt="" className='ms-2' />}
                                    </BUTTON>
                                    <BUTTON
                                        bg_color="#EFF1F3"
                                        clr="#000"
                                        brad="6px"
                                        pad="7px 5px"
                                        bord="0"
                                        f_size="11px"
                                        className='ms-1'
                                        wid="32%"
                                        onClick={() => change_bulletType_sidebar(selected_section.side_bar, 3)}
                                    >
                                        Pills
                                        {active_bulletType === 3 && <img src={mark_icon} alt="" className='ms-2' />}
                                    </BUTTON>
                                    <BUTTON
                                        bg_color="#EFF1F3"
                                        clr="#000"
                                        brad="6px"
                                        pad="7px 5px"
                                        bord="0"
                                        f_size="11px"
                                        className='ms-1'
                                        wid="32%"
                                        onClick={() => change_bulletType_sidebar(selected_section.side_bar, 4)}
                                    >
                                        Normal
                                        {active_bulletType === 4 && <img src={mark_icon} alt="" className='ms-2' />}
                                    </BUTTON>
                                </>
                            )}
                            {selected_section.side_bar === "trainingCourses" && (
                                <>
                                    <BUTTON
                                        bg_color="#EFF1F3"
                                        clr="#000"
                                        brad="6px"
                                        pad="7px 5px"
                                        bord="0"
                                        f_size="11px"
                                        wid="50%"
                                        onClick={() => change_bulletType_sidebar(selected_section.side_bar, 0)}
                                    >
                                        Bullets 1
                                        {active_bulletType === 0 && <img src={mark_icon} alt="" className='ms-2' />}
                                    </BUTTON>
                                    <BUTTON
                                        bg_color="#EFF1F3"
                                        clr="#000"
                                        brad="6px"
                                        pad="7px 5px"
                                        bord="0"
                                        f_size="11px"
                                        className='ms-1'
                                        wid="50%"
                                        onClick={() => change_bulletType_sidebar(selected_section.side_bar, 1)}
                                    >
                                        Bullets 2
                                        {active_bulletType === 1 && <img src={mark_icon} alt="" className='ms-2' />}
                                    </BUTTON>
                                </>
                            )}
                        </div>
                    </>
                )
            }
            <div className='position-relative'>
                {
                    selected_section.side_bar === "keySkillsCompetencies" ? (
                        <SelectBox
                            id="selectBox"
                            style={{
                                marginTop: "5px",
                                height: "40px",
                                position: "relative",
                            }}
                            selected_value={selected_profession}
                            search_value={search}
                            data={professions}
                            staticSearch={false}
                            setResult={(_, prof_id) => {
                                set_reset_tags_slice(true);
                                set_selected_profession(prof_id);
                            }}
                            onSearch={(value) => {
                                set_selected_profession(null);
                                set_search(value);
                                set_request_sent(true);
                            }}
                            onInputChange={(value) => {
                                set_search(value);
                            }}
                            isLoading={loading}
                        />) : selected_section.side_bar !== "trainingCourses" ? (
                            <>
                                <INPUT
                                    key={previous_selected_section}
                                    ref={searchInputRef}
                                    f_size="16px"
                                    className='mt-2'
                                    pad="0 16px"
                                    bg_color="#EFF1F3"
                                    brad="6px"
                                    hei="38px"
                                    name={selected_section.side_bar}
                                    value={search}
                                    onChange={(e) => set_search(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            set_request_sent(true);
                                        }
                                    }}
                                    placeholder="Enter your Job Title or Profession"
                                    bord="0"
                                    autoComplete="off"
                                />
                                <img
                                    src={search_icon}
                                    alt=""
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "18px",
                                        cursor: (search?.length ?? 0) <= 3 ? "auto" : "pointer",
                                        opacity: (search?.length ?? 0) <= 3 ? "0.2" : "1"
                                    }}
                                    onClick={() => {
                                        if ((search?.length ?? 0) > 3) {
                                            set_request_sent(true);
                                        }
                                    }}
                                />
                            </>
                        ) : null
                }
            </div>
            {
                (selected_section.side_bar !== "trainingCourses") &&
                <EDIT_PANEL_TITLE d_play="block" className='mt-4 mb-2'>
                    {
                        tags.list?.length > 0 && (selected_section.side_bar == "keySkillsCompetencies" ? "RECOMMENDED SKILLS" : "RECOMMENDED")
                    }
                </EDIT_PANEL_TITLE>
            }
            {(selected_section.side_bar !== "trainingCourses") &&
                <div className='tags_container' style={{ marginBottom: "12px" }}>
                    {
                        loading ? <CircularProgress style={{ width: "36px", height: "36px", margin: "12px", color: "#b8dbff" }} /> :
                            (tags?.list?.length > 0) && tags?.list?.map((t, i) => (
                                <BUTTON
                                    bg_color={tag_is_used(t, selected_section.side_bar) ? "#62C584" : "#EFF1F3"}
                                    hov="#62c5847d"
                                    clr={tag_is_used(t, selected_section.side_bar) ? "#fff" : "#000"}
                                    brad="6px"
                                    pad="6px 8px"
                                    bord="0"
                                    f_size="11px"
                                    className={`mb-2 ${t?.length < 28 ? "me-2" : ""} d-flex tal`}
                                    onClick={() => handle_selected_tags(t, selected_section.side_bar)}
                                >
                                    {
                                        tag_is_used(t, selected_section.side_bar) ?
                                            <div className='me-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 12 12" style={{ display: 'block', margin: 'auto', transform: 'translate(2px, 1.5px)' }}>
                                                    <g clipPath="url(#clip0_531_14569)">
                                                        <path d="M5.24971 9.43203L3.22555 7.40787C2.99805 7.18037 2.63055 7.18037 2.40305 7.40787C2.17555 7.63537 2.17555 8.00287 2.40305 8.23037L4.84138 10.6687C5.06888 10.8962 5.43638 10.8962 5.66388 10.6687L11.8355 4.49703C12.063 4.26953 12.063 3.90203 11.8355 3.67453C11.608 3.44703 11.2405 3.44703 11.013 3.67453L5.24971 9.43203Z" fill="white" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_531_14569">
                                                            <rect width="12" height="12" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            :
                                            <div className='me-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" style={{ display: 'block', margin: 'auto', transform: 'translate(2px, 3px)' }}>
                                                    <path d="M8.49935 5.58366H5.58268V8.50033C5.58268 8.82116 5.32018 9.08366 4.99935 9.08366C4.67852 9.08366 4.41602 8.82116 4.41602 8.50033V5.58366H1.49935C1.17852 5.58366 0.916016 5.32116 0.916016 5.00033C0.916016 4.67949 1.17852 4.41699 1.49935 4.41699H4.41602V1.50033C4.41602 1.17949 4.67852 0.916992 4.99935 0.916992C5.32018 0.916992 5.58268 1.17949 5.58268 1.50033V4.41699H8.49935C8.82018 4.41699 9.08268 4.67949 9.08268 5.00033C9.08268 5.32116 8.82018 5.58366 8.49935 5.58366Z" fill="#1C1C1C" />
                                                </svg>
                                            </div>

                                    }
                                    {t}
                                </BUTTON>
                            ))
                    }
                </div>}
            {
                (selected_section.side_bar !== "trainingCourses") &&
                !loading && tags?.list?.length > 0 &&
                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <BUTTON
                        clr="#fff"
                        bord="0"
                        f_family="montserrat_semibold"
                        pad="8px 0"
                        bg_color='#4984EA'
                        brad="6px"
                        m_wid="30%"
                        onClick={() => {
                            if (!user_info_?.isPremium) {
                                if (tags.list.length == 15 ||
                                    selected_section.side_bar != "keySkillsCompetencies") {
                                    set_purchase_modal(true);
                                } else {
                                    updateTags();
                                }
                            } else {
                                set_request_sent(true)
                            }
                        }}
                    >
                        More
                    </BUTTON>
                </div>
            }
        </SectionAIContainer>
    )
}
export default SectionAI;