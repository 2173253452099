import React from "react";
import styles from '../Template/template.module.css';
import { Modal } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { resume, request_save as request_save_ } from "../../recoil/store";
import { BUTTON } from "../../styled";
import { decor, section_images } from "../../templates";
import close_icon from "../../images/icons/close.svg";
import custom_icon from "../../images/icons/custom.svg";
import useMediaQuery from "../useMediaQuery";

const AddSectionModal = ({ isOpen, onClose }) => {

    const isMobile = useMediaQuery('(max-width: 900px)');
    const [request_save, set_request_save] = useRecoilState(request_save_);
    const [resume_, set_resume] = useRecoilState(resume);
    let resume_copy = JSON.parse(JSON.stringify(resume_));

    const add_custom = () => {
        let o = Object.entries(resume_).filter(
            (f) =>
                f[0] !== "summary" &&
                f[0] !== "designType" &&
                f[0] !== "name" &&
                f[0] !== "professionId" &&
                f[0] !== "personalInformation" &&
                f[0] !== "settings" &&
                f[0] !== "customSection"
        );
        let object = Object.fromEntries(o);
        resume_copy = {
            ...resume_copy,
            [`custom${Object.keys(object)?.length + 1}`]: {
                content: "<p><br></p>",
                name: "Custom Section",
                active: true,
                order: Object.keys(object)?.length + 1,
            },
        };
        set_resume(resume_copy);
        if (isMobile) {
            set_request_save(true);
        }
        onClose();
    };

    const select_section = (key) => {
        let copy = { ...resume_ };
        if (key === "qr") {
            copy.personalInformation = {
                ...copy.personalInformation,
                qr: {
                    ...copy.personalInformation.qr,
                    active: copy.personalInformation.qr?.active ? false : true,
                },
            };
        } else {
            copy[key] = {
                ...copy[key],
                active: copy[key]?.active ? false : true,
            };
        }
        set_resume(copy);
        if (isMobile) {
            set_request_save(true);
        }
    };
    return (
        <Modal
            onHide={onClose}
            show={isOpen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className={styles.modal_container}>
                <div className='d-flex jcsb aic'>
                    <div className={styles.modal_title}>Add section</div>
                    <div>
                        <BUTTON bg_color="transparent" bord="0" onClick={onClose}>
                            <img src={close_icon} alt="close_icon" />
                        </BUTTON>
                    </div>
                </div>
                <div className={styles.modal_content}>
                    {Object.keys(resume_)?.filter(
                        (k) =>
                            k !== "name" &&
                            k !== "designType" &&
                            k !== "settings" &&
                            k !== "personalInformation" &&
                            k !== "professionId" &&
                            k !== "customSection"
                    ).map((k) => (
                        <div key={k}>
                            <BUTTON
                                wid="80%"
                                f_size="93%"
                                bord={"0"}
                                bg_color="transparent"
                                f_family="montserrat_bold"
                                brad="4px"
                                className='d-flex aic'
                                clr={resume_[k]?.active ? "#1b1C1C" : "#1c1c1c7d"}
                                wei="normal"
                                onClick={() => select_section(k)}
                            >
                                <img src={k.includes("custom") ? custom_icon : section_images[k]} alt="" className='me-3' />
                                {k.includes("custom") ? resume_[k]?.name : decor[k]?.label_pop}
                            </BUTTON>
                        </div>
                    ))}
                    <BUTTON
                        wid="80%"
                        f_size="93%"
                        bord={"0"}
                        bg_color="transparent"
                        f_family="montserrat_bold"
                        brad="4px"
                        className='d-flex aic'
                        clr={resume_.personalInformation["qr"]?.active ? "#1b1C1C" : "#1c1c1c7d"}
                        wei="normal"
                        onClick={() => select_section("qr")}
                    >
                        <img src={section_images["qr"]} alt="" className='me-3' />
                        QR Code
                    </BUTTON>
                    <BUTTON
                        wid="80%"
                        f_size="93%"
                        bord={"0"}
                        bg_color="transparent"
                        f_family="montserrat_bold"
                        brad="4px"
                        className='d-flex aic'
                        wei="normal"
                        onClick={() => add_custom("custom_section")}
                    >
                        <img src={custom_icon} alt="" className='me-3' />
                        Custom section
                    </BUTTON>
                </div>
            </div>
        </Modal>
    )
}
export default AddSectionModal