import { editCV } from "../services/crud";
import {
    resume,
    loading_save as loadingSaveState,
    request_save as requestSaveState
} from "../recoil/store";
import { enqueueSnackbar } from 'notistack';
import { useRecoilCallback } from 'recoil';

export const useSaveFunction = () => {
    return useRecoilCallback(({ snapshot, set }) => async (id, inBackground = false) => {
        const [requestSave, loadingSave, resumeData] = await Promise.all([
            snapshot.getPromise(requestSaveState),
            snapshot.getPromise(loadingSaveState),
            snapshot.getPromise(resume)
        ]);

        if (loadingSave) {
            return false;
        }

        try {
            set(requestSaveState, true);
            const resumeCopy = { ...resumeData };
            const deepCopy = JSON.parse(JSON.stringify(resumeCopy));

            // Remove unnecessary properties
            if (deepCopy.professionId === null) {
                delete deepCopy.professionId;
            }
            delete deepCopy?.settings?.textColor;

            // Clean up specific sections
            ["keySkillsCompetencies", "trainingCourses"].forEach(section => {
                deepCopy[section]?.content?.forEach(item => delete item?.last);
            });

            // Extract custom sections
            const customSection = Object.entries(resumeCopy)
                .filter(([key]) => key.includes("custom") && key !== "customSection")
                .map(([key, value]) => ({ [key]: value }));

            const filteredResume = Object.fromEntries(
                Object.entries(deepCopy).filter(([key]) => !key?.includes("custom"))
            );

            set(loadingSaveState, prevState => (!inBackground ? true : prevState));

            const response = await editCV(id, { ...filteredResume, customSection });

            set(loadingSaveState, prevState => (!inBackground ? false : prevState));
            set(requestSaveState, false);

            if (response && !inBackground) {
                enqueueSnackbar("Resume has been successfully updated!", { variant: "success" });
            }

            return true;
        } catch (error) {
            set(loadingSaveState, prevState => (!inBackground ? false : prevState));
            set(requestSaveState, false);
            const errorMessage = error.response?.data?.message || "Something went wrong, please try again later.";
            enqueueSnackbar(errorMessage, { variant: "error" });
            return false;
        }
    });
};
