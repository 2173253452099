export const editorMethods = {
    editor: "",
    myUndo: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.history.undo();
    },
    myRedo: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.history.redo();
    },
    bold: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.formatText(myEditor.getSelection().index, myEditor.getSelection().length, 'bold', true);
    },
    italic: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.formatText(myEditor?.getSelection()?.index, myEditor?.getSelection()?.length, 'italic', true);
    },
    underline: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.formatText(myEditor.getSelection().index, myEditor.getSelection().length, 'underline', true);
    },
    alignLeft: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.formatLine(myEditor.getSelection().index, myEditor.getSelection().length, 'align', "");
    },
    alignRight: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.formatLine(myEditor.getSelection().index, myEditor.getSelection().length, 'align', "right");
    },
    alignCenter: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.formatLine(myEditor.getSelection().index, myEditor.getSelection().length, 'align', "center");
    },
    link: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.format('link', "https://google.com");
    },
    unordered: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.formatLine(myEditor.getSelection().index, myEditor.getSelection().length, 'list', "bullet");
    },
    ordered: () => {
        let myEditor = editorMethods.editor.reactQuillRef.getEditor();
        return myEditor.formatLine(myEditor.getSelection().index, myEditor.getSelection().length, 'list', "ordered");
    },
}