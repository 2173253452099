import React, { useEffect, useState } from 'react'
import "./index.css";
import { BUTTON, HEADER, TEXT, GENERAL_BLOCK } from '../../styled';
import delete_icon from "../../images/icons/delete.svg";
import { useRecoilState } from 'recoil';
import { resume, selected_section as selected_section_atom, tool_view as tool_view_atom } from '../../recoil/store';
import { useQRCode, QRCode } from 'react-qrcode'
import { svg_Email, svg_Linkedin, svg_Location, svg_Phone } from '../../templates';
import { QRCodeCanvas } from 'qrcode.react';
import { font_sizes } from '../../constants';

const Header = ({ downl }) => {
    const [resume_, set_resume] = useRecoilState(resume);
    const [selected_section, set_selected_section] = useRecoilState(selected_section_atom);
    const [tool_view, set_tool_view] = useRecoilState(tool_view_atom);
    let section_content = resume_["personalInformation"];
    const [is_delete, set_is_delete] = useState(false);
    const dataUrl = useQRCode(resume_?.personalInformation.qr?.url == "" ? 'https://engineernow.org/' : resume_?.personalInformation.qr?.url);
    const url = resume_?.personalInformation.qr?.url == "" ? 'https://engineernow.org/' : resume_?.personalInformation.qr?.url;
    const set_section_ = (e) => {
        e.stopPropagation()
        set_selected_section({ ...selected_section, side_bar: "QR" });
    }

    useEffect(() => {
        set_is_delete(selected_section?.side_bar == "QR" ? true : false);
    }, [selected_section])

    function delete_QR(e) {
        e.stopPropagation();
        let copy = { ...resume_ };
        let copy_qr = { ...copy.personalInformation.qr };
        copy.personalInformation = {
            ...copy.personalInformation,
            qr: { ...copy.personalInformation.qr, active: copy.personalInformation.qr?.active ? false : true }
        };
        set_resume(copy);
        set_selected_section({ ...selected_section, side_bar: "" })
    }

    return (

        <div className='position-relative'>
            <HEADER
                pad={resume_?.designType == 3 && "10px 27px 0px 27px"}
                onClick={() => {
                    set_selected_section({ ...selected_section, side_bar: "personalInformation", section_controller: "" })
                    set_tool_view(false)
                }}
            >
                <div className='d-flex'>
                    <div className='col-12'>
                        <GENERAL_BLOCK
                            hei={resume_?.designType == 4 ? "108px" : "124px"}
                            marg={resume_?.designType == 4 ? "10px 24px" : "0"}
                            bordLeft={resume_?.designType == 4 && `16px solid ${resume_?.settings?.color}`}
                            pad={resume_?.designType == 4 ? "0 0 0 16px" : (resume_?.designType == 3) ? "0" : "14px 27px 10px 27px"}
                            className='d-flex jcsb aic'
                            bg_color={(resume_?.designType == 0 || resume_?.designType == 1) ? resume_?.settings?.color : "#fff"}
                        >
                            <div>
                                <TEXT
                                    clr={(resume_?.designType == 0 || resume_?.designType == 1) ?
                                        resume_?.settings?.textColor : "black"}
                                    f_size="20pt"
                                    d_play="block"
                                >{section_content?.fullName}</TEXT>
                                <TEXT
                                    clr={(resume_?.designType == 0 || resume_?.designType == 1) ?
                                        resume_?.settings?.textColor : "black"}
                                    f_size="14pt"
                                    d_play="block"
                                >{section_content?.jobTitle}</TEXT>
                                <TEXT
                                    clr={(resume_?.designType == 0 || resume_?.designType == 1) ?
                                        resume_?.settings?.textColor : "black"}
                                    f_size={`${font_sizes.find(f => f?.value == resume_?.settings?.fontSize)?.text}pt`}
                                    d_play="block"
                                    style={{ marginTop: "5px" }}
                                >{section_content?.credentials}</TEXT>
                            </div>

                            {
                                resume_?.personalInformation?.qr?.active &&
                                <div
                                    onClick={(e) => set_section_(e)}
                                    style={{
                                        right: "40px",
                                        top: "25px",
                                        padding: "2px",
                                        borderRadius: '8px',
                                        backgroundColor: (resume_?.designType == 3 || resume_?.designType == 4) ? resume_?.settings?.color : (resume_?.designType == 2) ? 'black' : resume_?.settings?.textColor ? resume_?.settings?.textColor : 'white'
                                    }}
                                >
                                    <div className='position-relative' style={{ backgroundColor: "white", borderRadius: '5px' }}>
                                        {is_delete &&
                                            <div className='position-absolute' style={{ right: "-30px", top: 0 }}>
                                                <BUTTON
                                                    wid="24px"
                                                    hei="24px"
                                                    bg_color="#1C1C1C"
                                                    brad="4px"
                                                    className='d-flex aic'
                                                    onClick={delete_QR}
                                                >
                                                    <img src={delete_icon} alt="" width="100%" height="auto" />
                                                </BUTTON>
                                            </div>
                                        }
                                        <div style={{
                                            background: (resume_?.designType == 3 || resume_?.designType == 4) ? resume_?.settings?.color : "#000",
                                            borderRadius: "5px"
                                        }}>
                                            <div style={{
                                                padding: "4px 4px 0px 4px",
                                                backgroundColor: "white",
                                                borderRadius: "5px 5px 0px 0px",
                                                marginBottom: "-2px"
                                            }}>
                                                <QRCodeCanvas
                                                    value={url}
                                                    size={64}
                                                    bgColor={"#ffffff"}
                                                    fgColor={"#000000"}
                                                />
                                            </div>
                                            <div style={{
                                                background: (resume_?.designType == 3 || resume_?.designType == 4) ? resume_?.settings?.color : "#000",
                                                color: (resume_?.designType == 3 || resume_?.designType == 4) ? resume_?.settings?.textColor : "white",
                                                textAlign: "center",
                                                fontSize: "12px",
                                                overflow: "hidden",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                borderRadius: "0px 0px 5px 5px",
                                            }}>{
                                                    resume_?.personalInformation.qr?.frameLabel == "" ? 'Website' :
                                                        `${resume_?.personalInformation.qr?.frameLabel.substring(0, 11)}${(resume_?.personalInformation.qr?.frameLabel || '').length > 11 ? '...' : ''
                                                        }`
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </GENERAL_BLOCK>
                        <GENERAL_BLOCK
                            pad={(resume_?.designType == 0 || resume_?.designType == 3 || resume_?.designType == 4) ? "10px 27px 10px" : "0px 27px 10px"}
                            marg={resume_?.designType == 0 && "0px 27px"}
                            bordBot={(resume_?.designType == 0 || resume_?.designType == 4) && `1px solid ${resume_?.settings?.color}`}
                            bordTop={resume_?.designType == 4 && `1px solid ${resume_?.settings?.color}`}
                            bg_color={(resume_?.designType == 1 || resume_?.designType == 3) && resume_?.settings?.color}
                            className={`d-flex ${resume_?.designType != 1 ? 'jcc' : 'jcsb'} aic`}>
                            <div className='d-flex align-items-center me-3'>
                                {svg_Phone((resume_?.designType == 1 || resume_?.designType == 3) ?
                                    resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor))}
                                {
                                    downl ?
                                        <a href={`tel: ${section_content?.phone}`}>
                                            <TEXT
                                                clr={(resume_?.designType == 1 || resume_?.designType == 3) ?
                                                    resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor)}
                                                d_play="block"
                                                className='ms-2 personal-info_text'
                                            >{section_content?.phone}</TEXT>
                                        </a> :
                                        <TEXT
                                            clr={(resume_?.designType == 1 || resume_?.designType == 3) ?
                                                resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor)}
                                            d_play="block"
                                            className='ms-2 personal-info_text'
                                        >{section_content?.phone}</TEXT>
                                }
                            </div>
                            <div className='d-flex align-items-center me-3'>
                                {svg_Email((resume_?.designType == 1 || resume_?.designType == 3) ?
                                    resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor))}
                                {
                                    downl ?
                                        <a href={`mailto: ${section_content?.email}`}>
                                            <TEXT
                                                clr={(resume_?.designType == 1 || resume_?.designType == 3) ?
                                                    resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor)}
                                                d_play="block"
                                                className='ms-2 personal-info_text'
                                            >{section_content?.email}</TEXT>
                                        </a> :
                                        <TEXT
                                            clr={(resume_?.designType == 1 || resume_?.designType == 3) ?
                                                resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor)}
                                            d_play="block"
                                            className='ms-2 personal-info_text'
                                        >{section_content?.email}</TEXT>
                                }
                            </div>
                            {section_content?.linkedin && <div className='d-flex align-items-center me-3'>
                                {svg_Linkedin((resume_?.designType == 1 || resume_?.designType == 3) ?
                                    resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor))}
                                {
                                    downl ?
                                        <a id='linked-in' href={section_content?.linkedin}>
                                            <TEXT
                                                clr={(resume_?.designType == 1 || resume_?.designType == 3) ?
                                                    resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor)}
                                                d_play="block"
                                                className='ms-2 personal-info_text'
                                            >Linkedin</TEXT>
                                        </a> :
                                        <TEXT
                                            clr={(resume_?.designType == 1 || resume_?.designType == 3) ?
                                                resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor)}
                                            d_play="block"
                                            className='ms-2 personal-info_text'
                                        >Linkedin</TEXT>
                                }
                            </div>}
                            {section_content?.location && <div className='d-flex align-items-center me-3'>
                                {svg_Location((resume_?.designType == 1 || resume_?.designType == 3) ?
                                    resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor))}

                                <TEXT
                                    d_play="block"
                                    clr={(resume_?.designType == 1 || resume_?.designType == 3) ?
                                        resume_?.settings?.textColor : (resume_?.settings?.textColor == "white" ? resume_?.settings?.color : resume_?.settings?.textColor)}
                                    className='ms-2 personal-info_text'
                                >{section_content?.location}</TEXT>
                            </div>}
                        </GENERAL_BLOCK>
                    </div>

                </div >
            </HEADER >
        </div >
    )
}

export default Header