import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { reset_modal, token_engineer, purchase_modal as purchase_modal_atom, page_loading, user_info } from '../recoil/store';
import { getReferals } from '../services/referals';
import { enqueueSnackbar } from 'notistack';
import { CircularProgress, Pagination, Stack } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Modal } from 'react-bootstrap';
import useMediaQuery from '../Component/useMediaQuery';
import { getUserInfo } from '../services/userCv';
import { BUTTON, TEXT } from '../styled';
import "./page-style/style.css";

const Profile = () => {
    const nav = useNavigate();
    const loc = useLocation();
    const referalRef = useRef(null);
    const persistToken = JSON.parse(localStorage.getItem("token"));

    const [user_info_, set_user_info] = useRecoilState(user_info);
    const [token, set_token] = useRecoilState(token_engineer);
    const [purchase_modal, set_purchase_modal] = useRecoilState(purchase_modal_atom);
    const [referals, set_referals] = useState([]);
    const [page, set_page] = useState(1);
    const [loading, set_loading] = useState(false);
    const [clipboard, set_clipboard] = useState({ copied: false });
    const [page_loading_, set_page_loading] = useRecoilState(page_loading);
    const isMobile = useMediaQuery('(max-width:576px)');

    useEffect(() => {
        if (loc.search.includes("access")) {
            set_loading(true);
            const str = loc.search.replace("access=", "").replace("refresh=", "").replace("?", "");
            const splited = str.split("&");
            if (splited.length) {
                googleSignIn(splited[0], splited[1]);
            }
        }
    }, [loc]);

    useEffect(() => {
        set_clipboard({
            ...clipboard,
            value: user_info_?.referalCode
                ? `https://build.engineernow.org/user/referal=${user_info_?.referalCode}`
                : `https://build.engineernow.org/user/referal=...`
        });
    }, [user_info_]);

    const googleSignIn = async (accessToken, refreshToken) => {
        localStorage.setItem("token", JSON.stringify({ access: accessToken, refresh: refreshToken }));
        const userInfo = await getUserInfo();
        set_loading(false);
        set_user_info(userInfo);
        nav("/");
    };

    const handle_clipboard = () => {
        set_clipboard({ ...clipboard, copied: true });
        enqueueSnackbar("Successfully copied!", { variant: "success" });
    };

    const log_out = () => {
        set_token({
            email: "",
            password: "",
            name: "",
            surname: "",
            access: ""
        });
        set_user_info(null);
        localStorage.removeItem("token");
        nav("/");
        nav(0);
    };

    async function getData() {
        set_page_loading(true);
        try {
            const response = await getReferals();
            set_referals({ count: response?.count, rows: response?.rows });
            set_page_loading(false);
        } catch (error) {
            set_page_loading(false);
            enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" });
        }
    }

    useEffect(() => { persistToken?.access && getData(); }, []);

    return (
        <div className='container mt-4 mb-4'>
            <style>{`.loading_modal .modal-content {
                background: transparent;
                border: 0;
            }`}</style>

            <div>
                <TEXT
                    f_size={isMobile ? "18px" : "40px"}
                    d_play="block"
                    clr="#000"
                    f_family='montserrat_semibold'
                >Account</TEXT>
            </div>

            <div className='profile_block_1 mt-2'>
                <div className='profile_block_1_1'>
                    <TEXT
                        f_size={isMobile ? "18px" : "20px"}
                        d_play="block"
                        clr="#000"
                        f_family='montserrat_bold'
                    >Email</TEXT>
                    <TEXT
                        f_size={isMobile ? "14px" : "18px"}
                        d_play="block"
                        clr="#000"
                        f_family='montserrat_semibold'
                    >{user_info_?.email}</TEXT>
                </div>

                <div className='profile_block_1_2'>
                    {
                        (!user_info_?.isPremium) &&
                        <BUTTON
                            clr="#fff"
                            bord="0"
                            pad="12px 0"
                            bg_color='#4984EA'
                            brad="6px"
                            f_family="montserrat_semibold"
                            m_wid="31.5%"
                            className={`${isMobile ? 'mb-3' : ""}`}
                            onClick={() => set_purchase_modal(true)}
                        >
                            Buy Access
                        </BUTTON>
                    }

                    <BUTTON
                        className={`${isMobile ? 'mb-3' : "me-3 ms-3"}`}
                        clr="#fff"
                        bord="0"
                        pad="12px 0"
                        bg_color='#4984EA'
                        brad="6px"
                        f_family="montserrat_semibold"
                        m_wid="31.5%"
                        onClick={log_out}
                    >
                        Logout
                    </BUTTON>
                </div>
            </div>

            {/* <div className='referal_block_1'>
                <TEXT
                    f_size={isMobile ? "20px" : "18px"}
                    d_play="block"
                    clr="#000"
                    f_family='montserrat_bold'
                >Share the referral link</TEXT>

                <div className='referal_block mt-2 mt-md-0 mt-sm-0 mt-lg-0'>
                    <div ref={referalRef} className='referal_container'>
                        {clipboard.value}
                    </div>

                    <CopyToClipboard text={clipboard?.value} onCopy={handle_clipboard}>
                        <div className='copy_icon_profile'>
                            {
                                clipboard?.copied ?
                                    <TEXT
                                        f_size="12px"
                                        d_play="block"
                                        clr="#4984EA"
                                        f_family='montserrat_semibold'
                                    >
                                        Copied
                                    </TEXT> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                            }
                        </div>
                    </CopyToClipboard>
                </div>
            </div>

            {referals.count > 0 &&
                <div>
                    <div className="invitadors">
                        {referals?.rows?.map(r => (
                            <div key={r.email} className='d-flex jcsb aic mb-3 mt-3 mt-md-5 mt-sm-5 mt-lg-5'>
                                <div>
                                    <TEXT
                                        f_size="18px"
                                        d_play="block"
                                        clr="#000"
                                        f_family='montserrat_semibold'
                                    >
                                        {r?.name} {r?.surname}
                                    </TEXT>
                                    <TEXT
                                        f_size="16px"
                                        d_play="block"
                                        clr="#000"
                                        f_family='montserrat_medium'
                                    >
                                        {r?.email}
                                    </TEXT>
                                </div>
                                <div>
                                    <TEXT
                                        f_size="18px"
                                        d_play="block"
                                        clr="#4984EA"
                                        f_family='montserrat_semibold'
                                    >
                                        Invited
                                    </TEXT>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={`d-flex jcsb mt-3 ${isMobile ? "flex-column" : "aic"}`}>
                        <TEXT
                            f_size="14px"
                            d_play="block"
                            clr="#B5B7C0"
                            f_family='montserrat_medium'
                        >
                            Total activated referrals: {referals?.count}
                        </TEXT>
                        <Stack spacing={2}>
                            <Pagination
                                className='mt-4 mt-md-0 mt-sm-0'
                                count={Math.ceil(referals.count / 9)}
                                page={1}
                                onChange={(e, p) => set_page(p)}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Stack>
                    </div>

                    <Modal
                        onHide={() => set_loading(false)}
                        show={loading}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="loading_modal"
                    >
                        <div className="modal_container position-relative text-center">
                            <CircularProgress style={{ width: "25px", height: "25px", position: "absolute", color: "#b8dbff" }} />
                        </div>
                    </Modal>
                </div>
            } */}
        </div>
    );
};

export default Profile;
