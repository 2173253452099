import React, { useState, useEffect, useRef } from 'react';
import "./selectbox.css"
import check_icon from "../../images/icons/check.svg";

const SelectBox = (props) => {
    const { placeholder, data, represent_icon, arrow_icon, double_arrow, setResult, id, field_name, selected_value, } = props;
    const [selectboxView, setSelectboxView] = useState(false);
    const [selected, setSelected] = useState(placeholder);
    const [selectedItems, setSelectedItems] = useState({});

    useEffect(() => {
        setSelected(selected_value)
    }, [selected_value])

    const handleSelectedValue = (event) => {
        let { id } = event.target;
        let selected = { ...selectedItems };
        selected.id = id == "-" ? "-" : id;
        setSelected(id);
        setResult(id == "-" ? "" : id, field_name)
        setSelectboxView(false)
    }
    let ref = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref && !ref?.current?.contains(event.target)) {
                setSelectboxView(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    return (
        <div style={{
            padding: props?.padding ? props.padding : "0 10px",
            height: "40px",
            position: "relative",
            zIndex: 12
        }}
            ref={ref}
        >
            <div

                className='select_box_handler' onClick={(e) => setSelectboxView(!selectboxView)}>
                {
                    represent_icon && <img src={represent_icon} alt="" className='represent_icon' />
                }
                {
                    <span>
                        {data?.find(v => v?.value == selected)?.text}
                    </span>
                }
                <img src={arrow_icon} alt="" className='top-arrow ms-2' style={{ top: double_arrow && "10px" }} />
            </div>
            {selectboxView &&
                <ul className='select_box' style={data?.length > 14 ? scrollStyle : {}} id={id}>
                    {data?.length > 0 && data?.map(({ key, text, value }) => (
                        <li key={`li_${key}`} id={`li_${value}`}>
                            <button
                                className={`select_box_item ${selected == value ? 'selected' : ""}`}
                                id={value}
                                name={text}
                                onClick={handleSelectedValue}
                            >
                                {text}
                                {selected == value && <img src={check_icon} alt="" />}
                            </button>
                        </li>
                    ))}
                </ul>
            }
        </div>
    )
}

export default SelectBox;


const scrollStyle = { height: "120px", overflowY: "scroll" }