import React, { useEffect, useState } from 'react'
import Template from './Component/Template/Template'
import { useRecoilState } from 'recoil'
import { resume } from './recoil/store'
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router'
import axios from 'axios';
import { calculateTextColorFromRGB } from './utils/color-formating';

const DownloadConstructor = () => {

    let param = useParams();
    const [resume_, set_resume] = useRecoilState(resume);
    const [loading, set_loading] = useState(true);

    useEffect(() => {
        (async function () {
            try {
                let response = await axios({
                    method: 'get',
                    url: `https://engineernow.org/api/cv/${param?.id}`,
                    headers: {
                        Authorization: `Bearer ${param?.token}`
                    }
                });
                const modifiedResponseData = { ...response?.data };
                modifiedResponseData.settings = {
                    ...modifiedResponseData.settings,
                    textColor: calculateTextColorFromRGB(modifiedResponseData.settings.color),
                };
                set_resume(modifiedResponseData);
                set_loading(false);
            } catch (error) {
                console.log(error);
                enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" })
            }
        })()
    }, [])


    return (
        <div className='constructor-container'>
            <style>{`body {background-color: #fff !important}`}</style>
            {
                !loading &&
                <Template downl
                    templateWidth="816px"
                    is_download={true}
                />
            }
        </div>
    )
}

export default DownloadConstructor;