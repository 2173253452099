import { Link } from "react-router-dom";
import styled from "styled-components";

export const TEMPLATE_CARD = styled.div`
    position: relative;
    cursor: pointer;
    width: ${props => props?.wid};
    font-family: montserrat_medium;
    padding: ${props => props?.pad};
    img {
        height: 280px;
        width: ${props => props?.mob_width ? props?.mob_width : props?.wid};
        object-fit: cover;
        border-radius: 10px;
    };
    &:hover .hover_on_cv {
        opacity: 1
    }
`
export const GRID = styled.div`
    display: grid;
    grid-gap: 20px;
    padding: ${props => props?.pad};
    grid-template-columns: ${props => props?.grid_col};
    @media only screen and (min-width: 1400px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media only screen and (max-width: 1399px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`