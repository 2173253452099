import styled from "styled-components";

export const BuilderContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 20px 95px 20px;
`
export const BackButton = styled.button`
    width: 122px;
    height: 40px;
    padding: 8px 20px 8px 20px;
    gap: 12px;
    border-radius: 6px;
    background: #EFF1F3;
    font-family: montserrat_semibold;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    letter-spacing: -0.01em;
    border: none;
`
export const SectionTitle = styled.p`
    margin-top: 30px;
    font-family: montserrat_semibold;
    font-size: 18px;
    line-height: 21.94px;
    letter-spacing: -0.01em;
`
export const SectionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    background: #EFF1F3;
    padding: 0 16px 0 16px;
    border-radius: 6px;
    margin-top: 12px;
`
export const SectionText = styled.p`
    font-family: montserrat_semibold;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    letter-spacing: -0.01em;
    margin-left: 12px;
`
export const AddSectionButton = styled.button`
    color: #4984EA;
    font-family: montserrat_semibold;
    font-size: 16px;
    line-height: 19.5px;
    letter-spacing: -0.01em;
    margin-top: 20px;
    align-self: flex-start;
`
export const MenuContainer = styled.div`
    position: absolute;
    right: 0;
    top: 28px;
    img {
        margin-right: 12px;
    }
`
export const MenuButton = styled.button`
    width: 138px;
    height: 42px;
    border-radius: 4px;
    background: #1C1C1C;
    font-family: montserrat_medium;
    font-size: 20px;
    line-height: 14.63px;
    letter-spacing: -0.01em;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
`