import React from 'react';
import { INPUT } from '../../styled';
import styles from "./style.module.css";
import search_icon from "../../images/icons/search.svg";

const SearchInput = ({ style, onClick, onFocus, onChange, onSearch, onKeyDown, staticSearch, value, inputReference, isLoading }) => {

    return (
        <div className={styles.search_container} style={style}>
            {
                staticSearch ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g opacity="0.5" clipPath="url(#clip0_737_3308)">
                            <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#5F5E5E" />
                        </g>
                        <defs>
                            <clipPath id="clip0_737_3308">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    :
                    <img
                        src={search_icon}
                        alt=""
                        style={{
                            position: "absolute",
                            right: "10px",
                            top: "18px",
                            cursor: isLoading ? "auto" : (value?.length ?? 0) <= 3 ? "auto" : "pointer",
                            opacity: isLoading ? "0.2" : (value?.length ?? 0) <= 3 ? "0.2" : "1"
                        }}
                        onClick={() => {
                            if (!isLoading && (value?.length ?? 0) > 3) {
                                onSearch(value);
                            }
                        }}
                    />
            }
            <INPUT
                ref={inputReference}
                type='text'
                f_size="16px"
                className='mt-1'
                f_family="montserrat_medium"
                placeholder='Enter your Job Title or Profession'
                p_holder_style={{
                    color: "#5F5E5E",
                    fontSize: "14px"
                }}
                hei="46px"
                brad="6px"
                pad="12px 48px 12px 12px"
                bg_color="#EFF1F3"
                bord="0"
                value={value}
                onClick={onClick}
                onFocus={onFocus}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={(e) => onKeyDown(e)}
                disabled={isLoading}
            />
        </div>
    )
}

export default SearchInput;
