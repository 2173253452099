import React, { useEffect, useState } from 'react'
import { BUTTON, INPUT, TEXT } from '../styled'
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    selected_template,
    selected_section,
    login_modal as login_modal_atom,
    tool_view,
    page_loading,
    user_info,
    purchase_modal
} from '../recoil/store';
import { useNavigate } from 'react-router';
import { Modal } from 'react-bootstrap';
import './page-style/style.css';
import { enqueueSnackbar } from 'notistack';
import empty_dashboard from '../images/icons/empty-dashboard.svg';
import { getCv } from '../services/userCv';
import { GRID, TEMPLATE_CARD } from '../page-style/page.styled';
import style from '../page-style/page-style.module.css';
import moment from 'moment';
import { deleteCV, duplicateCV, editCV } from '../services/crud';
import { Link } from 'react-router-dom';
import { design_types } from '../templates';
import { useMediaQuery } from '@mui/material';
import { downloadCv } from '../services/download';
import { pageSize } from '../constants';
import useWindowDimensions from '../utils/WindowDimension';

const Dashboard = () => {
    const nav = useNavigate();
    const { width } = useWindowDimensions();
    let persist_token = JSON.parse(localStorage.getItem("token"));
    const user_info_ = useRecoilValue(user_info);
    const [purchase_modal_, set_purchase_modal] = useRecoilState(purchase_modal);
    const [selected_section_, set_selected_section_] = useRecoilState(selected_section);
    const [selected_template_, set_selected_template_] = useRecoilState(selected_template);
    const [name, set_name] = useState("");
    const [login_modal, set_login_modal] = useRecoilState(login_modal_atom);
    const [action_modal, set_action_modal] = useState({
        show: false,
        key: ""
    });
    const [cv, set_cv] = useState([]);
    const isTablet = useMediaQuery('(max-width: 992px)');
    const isMobile = useMediaQuery('(max-width:576px)');
    const [tool_view_, set_tool_view] = useRecoilState(tool_view);
    const [page_loading_, set_page_loading] = useRecoilState(page_loading);
    const [showDropdown, setShowDropdown] = useState(false);

    const go_to_picker_page = () => {
        if (persist_token?.access == null) {
            set_login_modal(true);
            return
        }
        nav("/pick-a-template");
    }

    useEffect(() => {
        set_selected_template_("")
        set_selected_section_(
            {
                section_controller: "",
                side_bar: "",
                type_4_item: "",
                custom_type: "",
                index: 0
            }
        );
    }, [])

    const save = async (key) => {
        if (key == "Rename") {
            let response = await editCV(selected_template_, { name });
            enqueueSnackbar("Resume has been successfully renamed!", { variant: "success" });
            set_action_modal({
                ...action_modal,
                show: false
            })
            getData();
        } else if (key == "Duplicate") {
            let response = await duplicateCV(selected_template_, { name });
            if (response && response?.cvId) {
                enqueueSnackbar("Resume has been successfully duplicated!", { variant: "success" })
                getData();
                set_action_modal({
                    ...action_modal,
                    show: false
                })
            }
        }
        else {
            let response = await deleteCV(selected_template_);
            if (response) {
                enqueueSnackbar("Resume has been successfully deleted!", { variant: "success" });
                set_action_modal({
                    ...action_modal,
                    show: false
                })
                getData();
            }
        }
    }
    const handle_actions = (e, key, template_id, name) => {
        e.stopPropagation();
        e.preventDefault();
        set_selected_template_(template_id);
        set_name(name)
        set_action_modal({
            show: true,
            key
        })
    }

    const download = async (e, id, name, type) => {
        e.stopPropagation();
        e.preventDefault();
        if (user_info_?.isPremium) {
            set_tool_view(false);
            set_page_loading(true);
            try {
                setTimeout(async () => {
                    let persist_token = localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));
                    let resp = await downloadCv(id, persist_token, type);
                    const url = URL.createObjectURL(resp);
                    const a = document.createElement('a');
                    a.download = `${name}.pdf`;
                    a.href = url;
                    a.target = '_self';

                    a.click();
                    setTimeout(function () {
                        a.remove();
                        URL.revokeObjectURL(url);
                    }, 100);
                    set_page_loading(false);
                }, 1200);
            } catch (error) {
                set_page_loading(false);
                enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" })
            }
        } else {
            set_purchase_modal(true);
        }
    }

    async function getData() {
        set_page_loading(true)
        try {
            let response = await getCv();
            set_cv(response?.rows)
            set_page_loading(false)
        } catch (error) {
            console.log(error);
            set_page_loading(false)
            enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" })
        }
    }
    useEffect(() => { persist_token?.access && getData() }, []);

    return (
        <div className='container'>
            <div className={`${!isMobile ? 'd-flex' : ''} jcsb pt-5 aic`}>
                <div>
                    <TEXT
                        f_size={!isMobile ? '40px' : '18px'}
                        d_play="block"
                        f_family="montserrat_bold"
                    >My Dashboard</TEXT>

                </div>
                <BUTTON
                    m_wid={!isMobile ? '22%' : '100%'}
                    f_size="93%"
                    bord="0"
                    bg_color="#4984EA"
                    f_family="montserrat_bold"
                    brad="6px"
                    className={`${isMobile ? 'mt-3' : ''} d-flex aic jcc text-center`}
                    pad="12px 20px"
                    hei="48px"
                    clr="#fff"
                    wei="normal"
                    onClick={go_to_picker_page}>Create a New Resume</BUTTON>
            </div>
            {
                cv?.length ?
                    <GRID
                        grid_col={isMobile ? '1fr' : isTablet ? '1fr 1fr' : "1fr 1fr 1fr"}
                        pad="0 36px"
                        className='mt-5 mb-5'>
                        {
                            cv?.map(t => (
                                <div key={t?.id}>
                                    <Link to={width > 900 ? `/resume-builder/${t?.id}` : `/rb-mobile/${t?.id}`}>
                                        <TEMPLATE_CARD
                                            wid={!isMobile ? '278px' : '100%'}
                                            mob_width="100%"
                                            onClick={() => {
                                                set_selected_template_(t?.id);
                                            }}
                                        >
                                            <img src={design_types?.find(d => t?.designType == d?.id)?.imgPreview} alt=""
                                            />
                                            <TEXT
                                                className={`${style.text} mt-2`}
                                                f_size="20px"
                                                d_play="block"
                                                clr="#000"
                                                style={
                                                    {
                                                        height: "25px",
                                                        overflow: "hidden",
                                                        WebkitLineClamp: 3,
                                                        textOverflow: "ellipsis",
                                                    }
                                                }
                                            >{t?.name}</TEXT>
                                            <TEXT
                                                className={`${style.text} mt-2`}
                                                d_play="block"
                                                clr="#4984EA"
                                                f_family='montserrat_regular'
                                            >
                                                Modified: {t?.updatedAt ? moment(t.updatedAt).format("hh:mm, DD MMM YYYY") : ""}
                                            </TEXT>
                                            <div className='hover_on_cv d-flex jcc aic'>
                                                <div className='w-100 d-flex justify-content-evenly'
                                                    onMouseLeave={() => setShowDropdown(false)}>
                                                    <div className='button_cover_onhover'>
                                                        <BUTTON
                                                            f_size="93%"
                                                            bord="0"
                                                            bg_color="#fff"
                                                            f_family="montserrat_bold"
                                                            brad="4px"
                                                            className='text-center'
                                                            wei="normal"
                                                            onClick={(e) => handle_actions(e, "Rename", t?.id, t?.name)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 18 19" fill="none">
                                                                <g clipPath="url(#clip0_737_3994)">
                                                                    <path d="M10.5493 7.48809L11.2223 8.16114L4.59422 14.7892H3.92117V14.1162L10.5493 7.48809ZM13.183 3.08398C13.0001 3.08398 12.8098 3.15714 12.6708 3.29614L11.3321 4.63493L14.0755 7.37835L15.4143 6.03956C15.6996 5.75425 15.6996 5.29335 15.4143 5.00804L13.7024 3.29614C13.5561 3.14983 13.3732 3.08398 13.183 3.08398ZM10.5493 5.41772L2.45801 13.509V16.2524H5.20143L13.2927 8.16114L10.5493 5.41772Z" fill="#1C1C1C" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_737_3994">
                                                                        <rect x="0.263184" y="0.889648" width="17.5579" height="17.5579" rx="4.38947" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </BUTTON>
                                                        <div style={{ fontSize: "7px" }}>Rename</div>
                                                    </div>
                                                    <div className='button_cover_onhover'>
                                                        <BUTTON
                                                            f_size="93%"
                                                            bord="0"
                                                            bg_color="#fff"
                                                            f_family="montserrat_bold"
                                                            brad="4px"
                                                            className='text-center'
                                                            wei="normal"
                                                            onClick={(e) => handle_actions(e, "Duplicate", t?.id)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 19 18" fill="none">
                                                                <g clipPath="url(#clip0_737_4000)">
                                                                    <path d="M12.6183 1.12109H3.83938C3.03464 1.12109 2.37622 1.77951 2.37622 2.58425V12.8264H3.83938V2.58425H12.6183V1.12109ZM11.8867 4.04741H6.76569C5.96096 4.04741 5.30985 4.70583 5.30985 5.51057L5.30254 15.7527C5.30254 16.5574 5.95364 17.2158 6.75838 17.2158H14.8131C15.6178 17.2158 16.2762 16.5574 16.2762 15.7527V8.43688L11.8867 4.04741ZM6.76569 15.7527V5.51057H11.1552V9.16846H14.8131V15.7527H6.76569Z" fill="#1C1C1C" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_737_4000">
                                                                        <rect x="0.913086" y="0.389648" width="17.5579" height="17.5579" rx="4.38947" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </BUTTON>
                                                        <div style={{ fontSize: "7px" }}>Copy</div>
                                                    </div>
                                                    <div className='button_cover_onhover'>
                                                        <BUTTON
                                                            f_size="93%"
                                                            bord="0"
                                                            bg_color="#fff"
                                                            f_family="montserrat_bold"
                                                            brad="4px"
                                                            className='text-center'
                                                            wei="normal"
                                                            onClick={(e) => handle_actions(e, "Delete", t?.id)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 18 19" fill="none">
                                                                <g clipPath="url(#clip0_737_4006)">
                                                                    <path d="M4.39466 14.5109C4.39466 15.3157 5.05309 15.9741 5.85782 15.9741H11.7105C12.5152 15.9741 13.1736 15.3157 13.1736 14.5109V7.19514C13.1736 6.3904 12.5152 5.73198 11.7105 5.73198H5.85782C5.05309 5.73198 4.39466 6.3904 4.39466 7.19514V14.5109ZM6.5894 7.19514H10.9789C11.3812 7.19514 11.7105 7.52435 11.7105 7.92672V13.7793C11.7105 14.1817 11.3812 14.5109 10.9789 14.5109H6.5894C6.18703 14.5109 5.85782 14.1817 5.85782 13.7793V7.92672C5.85782 7.52435 6.18703 7.19514 6.5894 7.19514ZM11.3447 3.53724L10.8252 3.01782C10.6936 2.88614 10.5033 2.80566 10.3131 2.80566H7.25514C7.06493 2.80566 6.87472 2.88614 6.74303 3.01782L6.22361 3.53724H4.39466C3.9923 3.53724 3.66309 3.86645 3.66309 4.26882C3.66309 4.67119 3.9923 5.0004 4.39466 5.0004H13.1736C13.576 5.0004 13.9052 4.67119 13.9052 4.26882C13.9052 3.86645 13.576 3.53724 13.1736 3.53724H11.3447Z" fill="#1C1C1C" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_737_4006">
                                                                        <rect x="0.00537109" y="0.611328" width="17.5579" height="17.5579" rx="4.38947" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </BUTTON>
                                                        <div style={{ fontSize: "7px" }}>Delete</div>
                                                    </div>
                                                    <div className='button_cover_onhover'>
                                                        <BUTTON
                                                            f_size="93%"
                                                            bord="0"
                                                            bg_color="#fff"
                                                            f_family="montserrat_bold"
                                                            brad="4px"
                                                            className='text-center'
                                                            wei="normal"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 18 18" fill="none">
                                                                <g clipPath="url(#clip0_737_4012)">
                                                                    <path d="M2.19507 12.7751V14.9994C2.19507 15.2042 2.35604 15.3652 2.5609 15.3652H4.78517C4.88028 15.3652 4.9754 15.3286 5.04125 15.2554L13.0311 7.27295L10.2873 4.52921L2.30482 12.5117C2.23165 12.5849 2.19507 12.6727 2.19507 12.7751ZM15.1529 5.15112C15.4382 4.86577 15.4382 4.40482 15.1529 4.11947L13.4408 2.40737C13.1554 2.12202 12.6945 2.12202 12.4091 2.40737L11.0702 3.74632L13.8139 6.49007L15.1529 5.15112Z" fill="#1C1C1C" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_737_4012">
                                                                        <rect width="17.56" height="17.56" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </BUTTON>
                                                        <div style={{ fontSize: "7px" }}>Edit</div>
                                                    </div>
                                                    <div className='button_cover_onhover'>
                                                        <BUTTON
                                                            f_size="93%"
                                                            bord="0"
                                                            bg_color="#fff"
                                                            f_family="montserrat_bold"
                                                            brad="4px"
                                                            className='text-center'
                                                            wei="normal"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setShowDropdown(!showDropdown)
                                                            }}
                                                        >
                                                            <div
                                                                className='d-flex aic jcc'
                                                                style={{
                                                                    backgroundColor: "#fff",
                                                                    width: "21px",
                                                                    height: "21px"
                                                                }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 18 18" fill="none">
                                                                    <g clipPath="url(#clip0_737_4018)">
                                                                        <path d="M10.4751 6.58531V7.08531H10.9751H12.1385C12.3358 7.08531 12.449 7.33102 12.2971 7.48291L8.93873 10.8413C8.84864 10.9313 8.70427 10.9313 8.61418 10.8413L5.25583 7.48291C5.1115 7.33858 5.21372 7.08531 5.42176 7.08531H6.58511H7.08511V6.58531V2.92698C7.08511 2.8007 7.19051 2.69531 7.31678 2.69531H10.2434C10.3697 2.69531 10.4751 2.8007 10.4751 2.92698V6.58531ZM4.39011 14.1336C4.26384 14.1336 4.15845 14.0283 4.15845 13.902C4.15845 13.7757 4.26384 13.6703 4.39011 13.6703H13.1701C13.2964 13.6703 13.4018 13.7757 13.4018 13.902C13.4018 14.0283 13.2964 14.1336 13.1701 14.1336H4.39011Z" stroke="#1C1C1C" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_737_4018">
                                                                            <rect width="17.56" height="17.56" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </div>
                                                        </BUTTON>
                                                        <div style={{ fontSize: "7px" }}>Download</div>
                                                        {
                                                            showDropdown && (
                                                                <ul className='select_box'
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "80%",
                                                                        left: "72.5%",
                                                                        width: "30%"
                                                                    }}>
                                                                    {pageSize.map(({ text, value }) => (
                                                                        <li key={text}>
                                                                            <button
                                                                                className={`select_box_item`}
                                                                                id={value}
                                                                                name={text}
                                                                                onClick={
                                                                                    (e) => download(e, t?.id, t?.name, value)}
                                                                            >
                                                                                {text}
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </TEMPLATE_CARD>
                                    </Link>
                                </div >
                            ))
                        }
                    </GRID >
                    :
                    <div className='dashboard_icon'>
                        <div>
                            <div className='text-center'><img src={empty_dashboard} alt="" /></div>
                            <TEXT
                                f_size={!isMobile ? '36' : '20px'}
                                className='mt-5 tac'
                                f_family='montserrat_medium'
                            >There are currently no Resumes to display</TEXT>
                        </div>
                    </div>
            }

            <Modal
                onHide={() => set_action_modal({ ...action_modal, show: false })}
                show={action_modal?.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="login_modal"
            >
                <div className="modal_container">
                    <div className='text-center'>
                        <TEXT
                            f_size={isMobile ? "20px" : "30px"}
                            clr="#1C1C1C"
                            f_family='montserrat_bold'
                        >{action_modal?.key == "Delete" ? "Are you sure you want to delete this resume?" : action_modal?.key == "Duplicate" ?
                            "Are you sure you want to duplicate this resume?" : action_modal?.key}</TEXT>
                    </div>
                    {
                        action_modal?.key == "Delete" ?
                            <div className='text-center mt-3'>
                                <TEXT
                                    as="div"
                                    f_size={isMobile ? "16px" : "18px"}
                                    f_family='montserrat_medium'
                                >
                                    Proceeding with deletion will <TEXT clr="#4984EA" d_play="contents">permanently</TEXT> remove this resume, and its data cannot be recovered. Please confirm if you still want to delete it.
                                </TEXT>
                            </div> :
                            <INPUT
                                type='email'
                                className='mt-4'
                                f_family="montserrat_medium"
                                placeholder='name'
                                wid="505px"
                                hei="46px"
                                brad="6px"
                                pad="12px 24px 12px 24px"
                                bord="1px solid rgb(28 28 28 / 48%)"
                                value={name || ''}
                                onChange={(e) => set_name(e.target.value)}
                            />
                    }
                    <div className={`d-flex ${isMobile ? "flex-column-reverse" : ""}`}>
                        <BUTTON
                            className={isMobile ? "mt-2" : "mt-5"}
                            clr="#000"
                            bord="0"
                            pad="12px 0"
                            bg_color='transparent'
                            brad="6px"
                            wid="100%"
                            f_family='montserrat_semibold'
                            onClick={() => set_action_modal({ ...action_modal, show: false })}
                        >
                            Cancel
                        </BUTTON>
                        <BUTTON
                            className={isMobile ? "mt-4" : "mt-5"}
                            clr="#fff"
                            bord="0"
                            pad="12px 0"
                            bg_color='#4984EA'
                            brad="6px"
                            wid="100%"
                            onClick={() => save(action_modal?.key)}
                        >
                            {action_modal?.key == "Delete" ? "Confirm & Delete" : action_modal?.key == "Duplicate" ? "Yes, duplicate" : "Save"}
                        </BUTTON>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default Dashboard