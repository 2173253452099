import info_icon from "./images/icons/pers_info.svg"
import summary_icon from "./images/icons/summary.svg"
import skills_icon from "./images/icons/key-skills.svg"
import edu_icon from "./images/icons/edu.svg"
import conference_icon from "./images/icons/conference.svg"
import work_icon from "./images/icons/work.svg"
import business_icon from "./images/icons/business.svg"
import project_icon from "./images/icons/folder.svg"
import language_icon from "./images/icons/language.svg"
import patent_icon from "./images/icons/patent.svg";
import achivement_icon from "./images/icons/achievements.svg";
import internship_icon from "./images/icons/internship.svg";
import qr_icon from "./images/icons/qr_icon.svg";
import design_1 from "./images/design-1.jpg";
import design_1_preview from "./images/design-1-preview.jpg";
import design_2 from "./images/design-2.jpg";
import design_2_preview from "./images/design-2-preview.jpg";
import design_3 from "./images/design-3.jpg";
import design_3_preview from "./images/design-3-preview.jpg";
import design_4 from "./images/design-4.jpg";
import design_4_preview from "./images/design-4-preview.jpg";
import design_5 from "./images/design-5.jpg";
import design_5_preview from "./images/design-5-preview.jpg";

export const decor =
{
    frame_label: {
        label: "Frame Label",
        placeholder: "LinkedIn, VideoCV etc.",
    },
    URL: {
        label: "URL",
        placeholder: "https://www.linkedIn.com/yourpage"
    },
    fullName: {
        label: "Full Name",
        placeholder: "Full name"
    },
    jobTitle: {
        label: "Job Title You Applying For",
        placeholder: "Job title"
    },
    credentials: {
        label: "Credentials",
        placeholder: "Credentials"
    },
    email: {
        label: "Email",
        placeholder: "Email"
    },
    phone: {
        label: "Phone",
        placeholder: "050 425 45 66"
    },
    location: {
        label: "Location",
        placeholder: "Location"
    },
    linkedin: {
        label1: "Websites & Social Links",
        label: "LinkedIn",
        placeholder: "https://www.linkedIn.com/yourpage"
    },
    custom_section: {
        label1: "Custom Section",
        label2: "CUSTOM SECTION",
        placeholder: ""
    },
    patentPublications: {
        label_pop: "Patents & Publications",
        label: "PATENTS & PUBLICATIONS",
        placeholder: "Enter Text"
    },
    achievements: {
        label_pop: "Key achievements",
        label: "KEY ACHIEVEMENTS",
        placeholder: "Enter Text"
    },
    internship: {
        label_pop: "Internship",
        label: "INTERNSHIP",
        placeholder: "Enter Text"
    },
    personalInformation: {
        label_pop: "Personal Information",
        label: "PERSONAL INFORMATION",
        placeholder: "Describe in English your experience, skills, competencies and achievements."
    },
    summary: {
        label_pop: "Summary",
        label: "SUMMARY",
        placeholder: "Describe in English your experience, skills, competencies and achievements."
    },
    projects: {
        label_pop: "Projects",
        label: "PROJECTS",
        placeholder: "Enter text (not visible if blank, click Preview)"
    },
    languages: {
        label_pop: "Languages",
        label: "LANGUAGES",
        placeholder: "Enter text (not visible if blank, click Preview)"
    },
    conferences: {
        label_pop: "Conferences",
        label: "CONFERENCES",
        placeholder: "Enter text (not visible if blank, click Preview)"
    },
    professionalAffiliations: {
        label_pop: "Professional affiliations",
        label: "PROFESSIONAL AFFILIATIONS",
        placeholder: "Enter text (not visible if blank, click Preview)"
    },
    certifications: {
        label_pop: "Certifications",
        label: "CERTIFICATIONS",
        placeholder: "Enter text (not visible if blank, click Preview)"
    },
    references: {
        label_pop: "References",
        label: "REFERENCES",
        placeholder: "Enter text (not visible if blank, click Preview)"
    },
    keySkillsCompetencies: {
        label_pop: "Key skills & competencies",
        label: "KEY SKILLS & COMPETENCIES",
        placeholder: "Add Key Skills & Competencies",
    },
    trainingCourses: {
        label_pop: "Trainings & Courses",
        label: "TRAININGS & COURSES",
        placeholder: "Enter text (not visible if blank, click Preview)"
    },
    workExperience: {
        label_pop: "Work experiences",
        label: "WORK EXPERIENCE",
        placeholder: "Enter text (not visible if blank, click Preview)",
    },
    education: {
        label_pop: "Education",
        label: "EDUCATION",
        placeholder: "Enter text (not visible if blank, click Preview)",
    },
}

export const section_images =
{
    personalInformation: info_icon,
    summary: summary_icon,
    keySkillsCompetencies: skills_icon,
    workExperience: work_icon,
    education: edu_icon,
    conferences: conference_icon,
    professionalAffiliations: business_icon,
    patentPublications: patent_icon,
    trainingCourses: conference_icon,
    languages: language_icon,
    projects: project_icon,
    references: conference_icon,
    certifications: work_icon,
    achievements: achivement_icon,
    internship: internship_icon,
    qr: qr_icon,
}

export const section_types =
{
    type_0: ["personalInformation"],
    type_1: [
        "summary",
        "achievements",
        "certifications",
        "conferences",
        "professionalAffiliations",
        "patentPublications",
        "references",
        "languages",
        "projects"
    ],
    type_2: [
        "keySkillsCompetencies",
    ],
    type_4: ["workExperience", "education", "internship"],
    type_5: ["trainingCourses"],
}

export const design_types = [
    {
        id: 0,
        img: design_1,
        imgPreview: design_1_preview
    },
    {
        id: 1,
        img: design_2,
        imgPreview: design_2_preview
    },
    {
        id: 2,
        img: design_3,
        imgPreview: design_3_preview
    },
    {
        id: 3,
        img: design_4,
        imgPreview: design_4_preview
    },
    {
        id: 4,
        img: design_5,
        imgPreview: design_5_preview
    },
]



export const svg_Phone = (color) =>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
        fill="none">
        <g clipPath="url(#clip0_531_19933)">
            <path d="M20.1826 16.679C20.1826 16.9968 20.1119 17.3235 19.9618 17.6413C19.8117 17.9592 19.6175 18.2594 19.3614 18.5419C18.9288 19.0187 
              18.452 19.363 17.9135 19.5837C17.3837 19.8045 16.8098 19.9192 16.1918 19.9192C15.2912 19.9192 14.3288
              19.7073 13.3135 19.2747C12.2981 18.8421 11.2828 18.2594 
            10.2763 17.5266C9.25042 16.7762 8.28238 15.9498 7.38032 15.0544C6.48749
             14.1556 5.66402 13.1905 4.91699 12.1673C4.193 11.1607 3.61028 
             10.1542 3.18648 9.15654C2.76268 8.15002 2.55078 7.18764 2.55078 
             6.26941C2.55078 5.66903 2.65673 5.09514 2.86863 4.56539C3.08053 
             4.02681 3.41604 3.53238 3.88398 3.09092C4.44904 2.53469 5.06708
              2.26099 5.72044 2.26099C5.96766 2.26099 6.21487 2.31396 6.4356
               2.41991C6.66516 2.52586 6.86823 2.68478 7.02715 2.91434L9.07551
                5.80147C9.23443 6.0222 9.34921 6.22527 9.42868 6.41951C9.50814 6.60492 9.55228 6.79033 9.55228 6.95808C9.55228 
                7.16998 9.49048 7.38188 9.36687 7.58495C9.25209 7.78802 9.08434 7.99992 8.87244 8.21182L8.20143 8.90932C8.10431
                 9.00644 8.06016 9.12122 8.06016 9.26249C8.06016 9.33312 8.06899 9.39492 8.08665 9.46556C8.11314 9.53619
                  8.13962 9.58916 8.15728 9.64214C8.3162 9.9335 8.58991 10.3132 8.97839 10.7723C9.3757 11.2314 9.7995 
                  11.6993 10.2586 12.1673C10.7354 12.6352 11.1945 13.0678 11.6624 13.4652C12.1216 13.8536 12.5012 
                  14.1185 12.8014 14.2774C12.8455 14.2951 12.8985 14.3216 12.9603 14.3481C13.031 14.3746 13.1016 
                  14.3834 13.1811 14.3834C13.3312 14.3834 13.4459 14.3304 13.5431 14.2333L14.2141 13.5711C14.4348 
                  13.3504 14.6467 13.1826 14.8498 13.0767C15.0528 12.9531 15.2559 12.8913 15.4766 12.8913C15.6444 
                  12.8913 15.821 12.9266 16.0152 13.006C16.2094 13.0855 16.4125 13.2003 16.6332 13.3504L19.5557 
                  15.4252C19.7852 15.5841 19.9442 15.7696 20.0413 15.9903C20.1296 16.211 20.1826 16.4317 20.1826 16.679Z"
                stroke={color} strokeWidth="1.65546" strokeMiterlimit="10" />
        </g>
        <defs>
            <clipPath id="clip0_531_19933">
                <rect width="21.1899" height="21.1899" fill={color} transform="translate(0.784668 0.495117)" />
            </clipPath>
        </defs>
    </svg>

export const svg_Email = (color) =>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M15.6077 18.5947H6.77858C4.12984 18.5947 2.36401 17.2704 2.36401
 14.1802V7.99977C2.36401 4.90957 4.12984 3.58521 6.77858 3.58521H15.6077C18.2564 3.58521 20.0223 4.90957 20.0223 7.99977V14.1802C20.0223 
 17.2704 18.2564 18.5947 15.6077 18.5947Z"
            stroke={color}
            strokeWidth="1.65546" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.6075 8.44141L12.8439 10.6487C11.9345 11.3727 10.4424 11.3727 9.53301 10.6487L6.77832 8.44141"
            stroke={color} strokeWidth="1.65546" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


export const svg_Linkedin = (color) =>
    <svg width="22" height="22" viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M13.708 15.7254H15.6275L15.6283 12.3284C15.6283 10.6608 15.2696 9.37892 13.3241 9.37892C12.5804 9.35127 11.8817 9.73563 11.506 
        10.3792H11.4802V9.53295H9.63754V15.7252H11.557V12.6619C11.557 11.8541 11.71 11.0718 12.7097 11.0718C13.6952 11.0718 13.708 11.9962 13.708 12.7142V15.7254Z"
            fill={color} />
        <path d="M6.35767 7.57087C6.35778 8.18713 6.85657 8.68662 7.47175 8.68651C7.76717 8.68645 8.05047 8.56884 8.25933 8.35954C8.46819 8.15025 8.58549 7.86641 8.58544 7.57047C8.58533 6.95421 8.08653 6.45472 7.47135 6.45483C6.85617 6.45494 6.35756 6.95461 6.35767 7.57087Z"
            fill={color} />
        <path d="M6.51002 15.7254H8.43148V9.53295H6.51002V15.7254Z"
            fill={color} />
        <rect x="1.0603" y="1.15723" width="19.8655" height="19.8655" rx="3.97311" stroke={color} strokeWidth="1.32437" />
    </svg>


export const svg_Location = (color) =>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M10.7935 12.3526C11.1553 12.3526 11.5135 12.2814 11.8477 12.143C12.1819 12.0045 12.4856 11.8016 12.7414 
        11.5458C12.9972 11.29 13.2001 10.9863 13.3385 10.6521C13.4769 10.3179 13.5482 9.9597 13.5482 9.59795C13.5482 
        9.2362 13.4769 8.87799 13.3385 8.54378C13.2001 8.20956 12.9972 7.90589 12.7414 7.65009C12.4856 7.39429
         12.1819 7.19139 11.8477 7.05295C11.5135 6.91451 11.1553 6.84326 10.7935 6.84326C10.0629 6.84326 9.36225
          7.13349 8.84565 7.65009C8.32904 8.1667 8.03882 8.86736 8.03882 9.59795C8.03882 10.3285 8.32904 11.0292
           8.84565 11.5458C9.36225 12.0624 10.0629 12.3526 10.7935 12.3526Z"
            stroke={color}
            strokeWidth="1.65546" />
        <path d="M3.39451 7.99109C5.13385 0.345067 16.4616 0.353896 18.1921 
        7.99992C19.2075 12.4851 16.4175 16.2816 13.9718 18.6302C13.1172 19.4544
         11.9762 19.915 10.7889 19.915C9.6016 19.915 8.4606 19.4544 7.606
          18.6302C5.16916 16.2816 2.37916 12.4763 3.39451 7.99109Z"
            stroke={color}
            strokeWidth="1.65546" />
    </svg>
