import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import {
    is_preview, editor_index as editor_index_atom,
    selected_section as selected_section_atom,
    download_data,
    page_loading,
    resume
} from '../../recoil/store';
import Template from '../../Component/Template/Template';
import arrow_left_black from "../../images/icons/arrow-left-black.svg";
import { BackButton } from './ResumeBuilder.Styled';
import PreviewButton from '../../Component/ActionButtons/ActionButtons';
import { getCvById } from '../../services/getCvById';
import { enqueueSnackbar } from 'notistack';
import { calculateTextColorFromRGB } from '../../utils/color-formating';

const Preview = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [is_preview_, set_is_preview] = useRecoilState(is_preview);
    const [page_loading_, set_page_loading] = useRecoilState(page_loading);
    const [download_data_, set_download_data] = useRecoilState(download_data);
    const [resume_, set_resume] = useRecoilState(resume);
    const [editor_index, set_editor_index] = useRecoilState(editor_index_atom);
    const [selected_section, set_selected_section] = useRecoilState(selected_section_atom);
    const [toolbar_view, set_toolbar_view] = useState(false);
    const templateWidth = "680px";

    useEffect(() => {
        (async function () {
            set_page_loading(true)
            try {
                let response = await getCvById(id);
                set_download_data({
                    id: id,
                    name: response?.name
                })
                const textColor = calculateTextColorFromRGB(response?.settings?.color);
                if (Array.isArray(response?.customSection)) {
                    for (const key of Object.keys(response)) {
                        if (key === 'customSection') {
                            for (const k of response[key]) {
                                for (const k2 of Object.keys(k)) {
                                    response = {
                                        ...response,
                                        [k2]: k[k2]
                                    }
                                }
                            }
                        }
                    }
                }
                let deep_copy = JSON.parse(JSON.stringify(response));
                deep_copy.settings.textColor = textColor;
                set_resume(deep_copy);
                set_page_loading(false);
            } catch (error) {
                console.log(error);
                set_page_loading(false)
                enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" })
            }
        })()
    }, [])

    useEffect(() => {
        set_is_preview(true);
        set_editor_index('');
        set_selected_section({ ...selected_section, section_controller: "", })
    }, []);

    useEffect(() => {
        const handleTouchMove = (event) => {
            if (event.scale !== 1) {
                event.preventDefault();
            }
        };
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        return () => {
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);

    return (
        <div className='w-100'>
            <BackButton onClick={() => navigate(-1)}
                style={{ background: 'white', margin: '30px 0 14px 20px' }}>
                <img src={arrow_left_black} alt="back" className='me-2' />
                Back
            </BackButton>
            {!page_loading_ && <Template
                downl={false}
                is_download={false}
                isMobile={true}
                templateWidth={templateWidth}
                marg="0"
                set_toolbar_view={set_toolbar_view}
            />}
            {!page_loading_ && <div style={{ marginLeft: 20 }}>
                <PreviewButton id={id} />
            </div>}
        </div >
    )
}

export default Preview