import React, { useEffect, useRef, useState } from 'react'
import "../../Component/Sidebar/sidebar.css";
import { useNavigate, useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import Calendar from 'react-calendar';
import { Modal } from "react-bootstrap";
import arrow_left_black from "../../images/icons/arrow-left-black.svg";
import delete_mobile from "../../images/icons/delete-mobile.svg";
import arrow_up from "../../images/icons/arrow-up-mob.svg";
import arrow_down from "../../images/icons/arrow-down-mob.svg";
import plus_white from "../../images/icons/plus-white.svg";
import calendar from "../../images/icons/calendar.svg";
import { BuilderContainer } from "./ResumeBuilder.Styled";
import { decor, section_types } from '../../templates';
import { BUTTON } from '../../styled';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    purchase_modal, resume,
    selected_section as selected_section_atom,
    user_info, request_save as request_save_,
    tool_view as tool_view_atom,
    editor_index as editor_index_atom,
    editor_methods,
    page_loading,
    download_data
} from '../../recoil/store';
import { enqueueSnackbar } from 'notistack';
import { rewriteSummary } from '../../services/rewriteSummary';
import { CircularProgress } from '@mui/material';
import { getRecommandationList } from '../../services/getRecommendationList';
import { getAllProfessionsWithTags } from '../../services/tags';
import { toWordCase } from '../../utils/string-formating';
import { BackButton } from './ResumeBuilder.Styled';
import PreviewButton from '../../Component/ActionButtons/ActionButtons';
import {
    ActionBtn, DeleteBtn, Section4Title, SectionContainer,
    SectionDate, SectionInput, SectionLabel, SectionTitle,
    SectionTitleContainer, SectionDiv
} from './ResumeSection.Styled';
import { editorMethods } from '../../utilFunctions';
import QuillEditor from '../../Component/QuillEditor';
import 'react-quill/dist/quill.snow.css';
import DeleteSectionModal from '../../Component/DeleteSectionModal/DeleteSectionModal';
import { BlueButton } from '../../Component/ActionButtons/ActionButtons.Styled';
import moment from 'moment';
import { date_format } from '../../constants';
import { calculateTextColorFromRGB } from '../../utils/color-formating';
import { getCvById } from '../../services/getCvById';
import { useSaveFunction } from '../../utils/save';
import { useAutosave } from 'react-autosave';

const ResumeSection = () => {

    const navigate = useNavigate();
    const { id, section, index } = useParams();
    let user_info_ = useRecoilValue(user_info);

    const [purchase_modal_, set_purchase_modal] = useRecoilState(purchase_modal);
    const [resume_, set_resume] = useRecoilState(resume);
    const [editor_index, set_editor_index] = useRecoilState(editor_index_atom);
    const [tool_view, set_tool_view] = useRecoilState(tool_view_atom);
    const [editor_methods_, set_editor_methods] = useRecoilState(editor_methods);
    const [page_loading_, set_page_loading] = useRecoilState(page_loading);
    const [download_data_, set_download_data] = useRecoilState(download_data);
    const [selected_profession, set_selected_profession] = useState(null);
    const [loading, set_loading] = useState(false);
    const [loaded, set_loaded] = useState(false);
    const [all_professions, set_all_professions] = useState([{}]);
    const [professions, set_professions] = useState([]);
    const [customName, setCustomName] = useState('')
    const [temporary_state_pi, set_temporary_state__pi] = useState({ ...resume_?.personalInformation })
    const [temporary_state_qr, set_temporary_state_qr] = useState({ ...resume_?.personalInformation?.qr })
    const [active_bulletType, set_active_bulletType] = useState(4);
    const [summary, set_summary] = useState("");
    const [temporary_summary, set_temporary_summary] = useState("");
    const [gpt_responses, set_gpt_responses] = useState({});
    const [tags, set_tags] = useState({ list: [] });
    const [reset_tags_slice, set_reset_tags_slice] = useState(true);
    const [request_sent, set_request_sent] = useState(false)
    const [request_save, set_request_save] = useRecoilState(request_save_);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [{ sectionToRemove, itemToRemove }, setToRemove] = useState({ sectionToRemove: "", itemToRemove: null });
    const selectedDateFormat = date_format[resume_?.settings?.dateFormat - 1].key || date_format[0].key;
    const [start_calendar_view, set_start_calendar_view] = useState(false);
    const [start_date, set_start_date] = useState();
    const [end_date, set_end_date] = useState();
    const [end_calendar_view, set_end_calendar_view] = useState(false);
    const [calendar_index, set_calendar_index] = useState(0);

    // Parameters below are used for GPT searches, including storing and keeping history.
    const [selected_section, set_selected_section] = useRecoilState(selected_section_atom);
    const [previous_selected_section, set_previous_selected_section] = useState("");
    const [last_search_values, set_last_search_values] = useState({});
    const [search, set_search] = useState("");

    const searchInputRef = useRef(null);
    let resume_copy = { ...resume_ };

    const saveFunction = useSaveFunction();

    // Auto save
    const autoSave = () => {
        if (!page_loading_) {
            saveAll();
        }
    }

    useAutosave({
        data: resume_copy,
        onSave: autoSave,
        interval: 30000
    });

    // Function that saves all the data
    const saveAll = async () => {
        if (selected_section?.side_bar === "QR") {
            add_qr();
        } else if (selected_section?.side_bar === "personalInformation") {
            save_personal();
        } else if (selected_section?.side_bar?.includes("custom")) {
            add_custom();
        } else if (selected_section?.side_bar === "summary") {
            save_summary();
        }
        const result = await saveFunction(id, true);
        return result;
    };

    useEffect(() => {
        getAllProfessions();
    }, [])

    useEffect(() => {
        if (selected_section?.side_bar?.includes("custom")) {
            setCustomName(resume_[selected_section?.side_bar].name)
        }
    }, [])

    useEffect(() => {
        (async function () {
            if (!request_save) {
                set_page_loading(true)
                try {
                    let response = await getCvById(id);
                    set_download_data({
                        id: id,
                        name: response?.name
                    })
                    const textColor = calculateTextColorFromRGB(response?.settings?.color);
                    if (Array.isArray(response?.customSection)) {
                        for (const key of Object.keys(response)) {
                            if (key === 'customSection') {
                                for (const k of response[key]) {
                                    for (const k2 of Object.keys(k)) {
                                        response = {
                                            ...response,
                                            [k2]: k[k2]
                                        }
                                    }
                                }
                            }
                        }
                    }
                    let deep_copy = JSON.parse(JSON.stringify(response));
                    deep_copy.settings.textColor = textColor;
                    set_resume(deep_copy);
                    set_selected_section({
                        ...selected_section, side_bar: section,
                        section_controller: section, index: +index
                    })
                    set_page_loading(false);
                } catch (error) {
                    console.log(error);
                    set_page_loading(false)
                    enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" })
                }
            }
        })()
    }, [])

    useEffect(() => {
        set_temporary_state__pi({ ...resume_?.personalInformation })
        set_temporary_state_qr({ ...resume_?.personalInformation?.qr })
        if (!loaded && resume_?.professionId) {
            set_selected_profession(resume_?.professionId);
            set_loaded(true);
        }
        if (selected_section.side_bar === "summary") {
            if (resume_["summary"]?.content) {
                set_summary(resume_["summary"]?.content);
            }
        }
    }, [resume_])

    useEffect(() => {
        if (previous_selected_section != selected_section?.side_bar) {
            set_previous_selected_section(selected_section?.side_bar);
        }
    }, [selected_section])

    useEffect(() => {
        const sectionKey = previous_selected_section;
        const lastSearchValue = last_search_values[sectionKey];
        const professionId = resume_?.professionId;
        const defaultProfession = all_professions.find(p => p.id == professionId)?.[selected_section?.side_bar] || [];

        set_reset_tags_slice(true);
        if (lastSearchValue?.length > 0) {
            set_search(lastSearchValue);
            if (selected_section?.side_bar === "keySkillsCompetencies") {
                updateTags();
            } else if (gpt_responses[sectionKey]) {
                set_tags({ list: gpt_responses[sectionKey] })
            }
        } else if (selected_section?.side_bar === "keySkillsCompetencies") {
            updateTags();
        } else {
            set_tags(professionId ? { list: defaultProfession } : { list: [] });
            set_search("");
        }
    }, [previous_selected_section]);

    useEffect(() => {
        if (all_professions != null) {
            let professions = all_professions.map(p => ({
                key: p.id,
                value: p.id,
                text: p.name,
                prof_id: p.id,
            }));
            if (resume_?.professionId != null) {
                const defaultProfession = professions.find(p => p.prof_id === resume_.professionId);
                if (defaultProfession) {
                    professions = professions.filter(p => p.prof_id !== resume_.professionId);
                    professions.unshift({
                        key: defaultProfession.key,
                        value: defaultProfession.value,
                        text: `Default: ${defaultProfession.text}`,
                        prof_id: defaultProfession.prof_id,
                    });
                }
            }
            set_professions(professions);
        }
    }, [all_professions]);

    useEffect(() => {
        set_gpt_responses((previousGptResponses) => ({
            ...previousGptResponses,
            [previous_selected_section]: null,
        }));
        set_last_search_values((previousValue) => ({
            ...previousValue,
            [previous_selected_section]: "",
        }));
        updateTags();
    }, [selected_profession])

    useEffect(() => {
        set_last_search_values((previousValue) => ({
            ...previousValue,
            [previous_selected_section]: search,
        }));
    }, [search])

    useEffect(() => {
        getData();
    }, [request_sent])

    useEffect(() => {
        let key = selected_section?.section_controller
        let copy = JSON.parse(JSON.stringify(resume_))
        if (copy[key] != undefined
            && copy[key].items != undefined
            && copy[key].items[selected_section?.index] != undefined) {
            copy[key].items[selected_section?.index]["startDate"] = moment(start_date).format("MM/DD/YYYY");
            copy[key].items[selected_section?.index]["endDate"] = moment(end_date).format("MM/DD/YYYY");
            set_resume(copy)
        }
    }, [start_date, end_date])

    // Reusable Functions
    function updateTags() {
        let selectedSectionKey = selected_section?.side_bar;
        const profession = all_professions?.find(p => p.id === selected_profession);
        const gptResponses = gpt_responses[selectedSectionKey] || [];
        const allTags = [
            ...gptResponses,
            ...(profession?.tags || []),
        ];
        const lastSearchValue = last_search_values[selectedSectionKey];
        set_tags((previousTags) => {
            let tagSliceEnd = 5;
            if (!user_info_?.isPremium && reset_tags_slice === false) {
                if (previousTags.list.length === 5) {
                    tagSliceEnd = 10;
                } else if (previousTags.list.length === 10) {
                    tagSliceEnd = 15;
                }
            }
            set_reset_tags_slice(false);
            return {
                list: (!user_info_?.isPremium) ? allTags.slice(0, tagSliceEnd) : allTags
            };
        });
        set_search(profession?.name ?? lastSearchValue);
    }

    async function getAllProfessions() {
        try {
            set_loading(true);
            let response = await getAllProfessionsWithTags();
            set_all_professions(response);
            set_loading(false);
        } catch (error) {
            set_loading(false);
            console.error("An error occurred:", error);
        }
    }

    async function getData() {
        if (request_sent) {
            var searchValue = search?.trim();
            if (searchValue?.length < 3) {
                if (resume_.personalInformation.jobTitle.length >= 3) {
                    searchValue = resume_.personalInformation.jobTitle;
                } else {
                    set_request_sent(false);
                    enqueueSnackbar("Please enter a value into search bar.", { variant: "error" });
                    return;
                }
            }
            searchInputRef?.current?.blur();
            let selectedSectionKey = selected_section?.side_bar;
            if (selectedSectionKey?.length > 0) {
                try {
                    set_loading(true);
                    const response = await getRecommandationList({ type: selectedSectionKey, search: searchValue });
                    if (response && response?.result) {
                        let parsedList = JSON.parse(response?.result?.content)?.[selectedSectionKey];
                        set_gpt_responses((previousGptResponses) => ({
                            ...previousGptResponses,
                            [selectedSectionKey]: [
                                ...parsedList,
                                ...(previousGptResponses[selectedSectionKey] || []),
                            ],
                        }));
                        set_tags((previousTags) => ({
                            list: [...parsedList, ...previousTags.list],
                        }));
                        set_loading(false);
                        set_request_sent(false);
                    }
                } catch (error) {
                    set_loading(false);
                    set_request_sent(false);
                    if (error.response && error.response.status === 403) {
                        set_purchase_modal(true);
                    } else {
                        enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" });
                    }
                }
            }
        }
    }
    const handle_custom_section = (e) => {
        let { value, name } = e.target;
        console.warn('value', value)
        setCustomName(value);
    }

    const add_custom = () => {
        let resume_copy = JSON.parse(JSON.stringify(resume_));
        resume_copy[selected_section?.side_bar].name = customName;
        set_resume(resume_copy);
    }

    const handle_pi = (e) => {
        let { value, name } = e.target;
        set_temporary_state__pi({ ...temporary_state_pi, [name]: value })
    }

    function handle_type_2_5_state(value, i, key) {
        let resume_copy = { ...resume_ };
        let key_ = { ...resume_copy[key] };
        let array = [...resume_copy[key].content];
        array[i] = {
            ...array[i],
            value,
        };
        key_.content = array;
        resume_copy[key] = key_;
        set_resume(resume_copy)
    }
    function handle_add_item(key) {
        let resume_copy = { ...resume_ };
        let key_ = { ...resume_copy[key] };
        let array = [...resume_copy[key].content];
        array.push({ value: '' });
        key_.content = array;
        resume_copy[key] = key_;
        set_resume(resume_copy)
    }
    const handle_add_type_4_item = (key) => {
        let all = { ...resume_ };
        let copy = [...resume_[key].items];
        copy?.push({
            designation: "",
            company: "",
            content: "<p><br></p>",
            startDate: new Date(),
            endDate: new Date(),
            location: ""
        });
        all = {
            ...all,
            [key]: {
                ...all[key],
                items: copy
            }
        }
        set_resume(all);
    }

    const save_personal = () => {
        let res = { ...resume_ };
        res["personalInformation"] = {
            ...res["personalInformation"],
            ...temporary_state_pi
        };
        set_resume(res);
    }

    const get_summary = async () => {
        if (summary?.length < 100) {
            enqueueSnackbar("Please enter at least 100 characters.", { variant: "warning" });
        } else {
            set_loading(true);
            try {
                let response = await rewriteSummary(summary);
                if (response && response?.result) {
                    set_temporary_summary(response?.result?.content);
                    set_loading(false);
                }
            } catch (error) {
                set_loading(false);
                if (error.response && error.response.status === 403) {
                    set_purchase_modal(true);
                } else {
                    enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" });
                }
            }
        }
    };

    const update_summary = () => {
        set_summary(temporary_summary);
        set_temporary_summary("");
    }

    const save_summary = () => {
        resume_copy = {
            ...resume_copy,
            summary: {
                ...resume_copy["summary"],
                content: temporary_summary.trim() !== "" ? temporary_summary : summary
            }
        }
        set_resume(resume_copy);
    }

    const handle_qr = (e) => {
        let { name, value } = e.target;
        set_temporary_state_qr({ ...temporary_state_qr, [name]: value })
    }

    const add_qr = () => {
        let res = { ...resume_ };
        let qr = { ...res.personalInformation.qr };
        qr = {
            ...qr,
            ...temporary_state_qr
        };
        res.personalInformation = {
            ...res.personalInformation,
            qr
        }
        set_resume(res);
    }

    useEffect(() => {
        set_active_bulletType(resume_[selected_section?.side_bar]?.bulletType)
    }, [resume_, selected_section?.side_bar]);

    function displayDeleteConfirmation(key, item) {
        setToRemove({ sectionToRemove: key, itemToRemove: item });
        setShowConfirmationModal(true);
    }

    function handle_titles_of_type_4(e, key, i) {
        let { name, value } = e.target;
        let copy = { ...resume_ };
        let key_ = { ...copy[key] };
        let array = [...copy[key].items];
        array[i] = {
            ...array[i],
            [name]: value,
        };
        key_.items = array;
        copy[key] = key_;
        set_resume(copy)
    }

    const type_4_swap = (index, key, type) => {
        let all = { ...resume_ };
        let copy = [...resume_[key].items];
        let this_index = copy[index];
        if (type === "down") {
            if (index == copy?.length - 1) {
                copy[index] = copy[0];
                copy[0] = this_index;
            } else {
                copy[index] = copy[index + 1];
                copy[index + 1] = this_index;
            }
        }
        else {
            if (index == 0) {
                copy[index] = copy[copy?.length - 1];
                copy[copy?.length - 1] = this_index;
            }
            else {
                copy[index] = copy[index - 1];
                copy[index - 1] = this_index;
            }
        }
        all = {
            ...all,
            [key]: { ...all[key], items: copy }
        };
        set_resume(all)
    }

    function handle_calendar(date) {
        set_start_calendar_view(false);
        set_end_calendar_view(false);
        if (start_calendar_view) {
            set_start_date(date);
        } else {
            set_end_date((date == "Present") ? null : date);
        }
    }

    const handleBackButton = async () => {
        set_page_loading(true);
        let hasSaved = await saveAll();
        if (hasSaved == true) {
            navigate(-1);
            set_selected_section({ ...selected_section, side_bar: "", section_controller: "", index: 0 })
        }
    }

    const summaryPlaceholder = `Write here about you, for example: «Drilling engineer with 12 years in oil and gas industry, offshore experience, drilled vertical and deviated wells (J, S, Horizontals), H2S experience, shallow and deep measure depths (MD) up to 6000 m, saved $120,000 and reduced the well construction time by 15% by introducing new types of PDC drill bits» 
    
*Please enter at least 100 characters.`

    return (
        <BuilderContainer>
            <BackButton onClick={() => handleBackButton()}>
                <img src={arrow_left_black} alt="back" className='me-2' />
                Back
            </BackButton>
            {!page_loading_ && <SectionContainer>
                {
                    selected_section?.side_bar === "QR" ?
                        <div>
                            <SectionTitle>{selected_section?.side_bar}</SectionTitle>
                            <div>
                                <SectionLabel>
                                    Frame Label
                                </SectionLabel>
                                <SectionInput
                                    key={previous_selected_section}
                                    name="frameLabel"
                                    value={temporary_state_qr?.frameLabel}
                                    placeholder={decor.frame_label.placeholder}
                                    onChange={handle_qr}
                                />
                            </div>
                            <div>
                                <SectionLabel>
                                    URL
                                </SectionLabel>
                                <SectionInput
                                    key={previous_selected_section}
                                    name="url"
                                    value={temporary_state_qr?.url}
                                    placeholder={decor.URL.placeholder}
                                    onChange={handle_qr}
                                />
                            </div>
                        </div>
                        :
                        Object.keys(resume_)?.map((r, i) => (
                            (selected_section?.side_bar === r) ?
                                (
                                    <div>
                                        {!section_types.type_4.includes(selected_section.side_bar) && <SectionTitle>
                                            {selected_section?.side_bar?.includes("custom") ?
                                                'Custom Section' : toWordCase(decor[selected_section?.side_bar]?.label)}
                                        </SectionTitle>}
                                        {selected_section?.side_bar?.includes("custom") &&
                                            <div>
                                                <SectionLabel>
                                                    Name
                                                </SectionLabel>
                                                <SectionInput
                                                    style={{ marginBottom: 20 }}
                                                    key={previous_selected_section}
                                                    name={r}
                                                    value={customName}
                                                    placeholder="Enter name"
                                                    onChange={handle_custom_section}
                                                    type='text'
                                                    maxLength={36}
                                                />
                                                <QuillEditor
                                                    placeholder={"Description"}
                                                    index={`${r}_quill_1`}
                                                    set_index={set_editor_index}
                                                    set_tool_view={set_tool_view}
                                                    tool_view={tool_view}
                                                    set_editor_methods={set_editor_methods}
                                                    editorMethods={editorMethods}
                                                    set_resume={set_resume}
                                                    keys={r}
                                                    value={resume_[r]?.content ?? ""}
                                                    isMobile={true}
                                                />
                                            </div>}
                                        {section_types.type_1.includes(selected_section.side_bar) &&
                                            <div>
                                                <QuillEditor
                                                    placeholder={r === "summary" ? summaryPlaceholder : 'Enter text '}
                                                    index={`${r}_quill_1`}
                                                    set_index={set_editor_index}
                                                    set_tool_view={set_tool_view}
                                                    tool_view={tool_view}
                                                    set_editor_methods={set_editor_methods}
                                                    editorMethods={editorMethods}
                                                    set_resume={r === "summary" ? set_summary : set_resume}
                                                    keys={r}
                                                    value={r === "summary" ? summary : resume_[r]?.content ?? ""}
                                                    isMobile={true}
                                                />
                                                {r === "summary" &&
                                                    <div>
                                                        <div className='d-flex justify-content-center'>
                                                            <BlueButton
                                                                className='d-flex align-items-center justify-content-center'
                                                                style={{ width: 200, marginTop: 20 }}
                                                                onClick={get_summary}>
                                                                {loading ? "" : "Rewrite with AI"} {loading &&
                                                                    <CircularProgress style={{ width: "24px", height: "24px", margin: "12px", color: "#b8dbff" }} />}
                                                            </BlueButton>
                                                        </div>
                                                        <SectionDiv
                                                            style={{
                                                                maxHeight: temporary_summary?.length > 500 ? '330px' : "unset",
                                                                overflowY: temporary_summary?.length > 500 ? 'scroll' : "unset",
                                                                color: temporary_summary?.length > 0 ? "#1C1C1C" : "#1C1C1C80"
                                                            }}
                                                        >
                                                            {temporary_summary || "The rewritten AI result will appear here."}
                                                        </SectionDiv>
                                                        {temporary_summary &&
                                                            <div className='d-flex justify-content-center'>
                                                                <BlueButton
                                                                    className='d-flex align-items-center justify-content-center'
                                                                    style={{ width: 131, marginTop: 10, marginBottom: 24 }}
                                                                    onClick={update_summary}>
                                                                    {"Apply"}
                                                                </BlueButton>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>}
                                        {(section_types.type_2.includes(selected_section.side_bar) ||
                                            section_types.type_5.includes(selected_section.side_bar)) &&
                                            <div>
                                                {resume_[r]?.content?.map((item, i) => (
                                                    <div className='d-flex'>
                                                        <TextareaAutosize
                                                            className='mobile-input'
                                                            value={item.value}
                                                            onChange={(e) => handle_type_2_5_state(e.target.value, i, r)}
                                                            placeholder={selected_section.side_bar === "keySkillsCompetencies" ?
                                                                decor[r]?.placeholder : 'Enter text'}
                                                            maxRows={6}
                                                        />
                                                        <DeleteBtn onClick={() => displayDeleteConfirmation(r, i)}>
                                                            <img src={delete_mobile} alt="" width={24} height={24} />
                                                        </DeleteBtn>
                                                    </div>
                                                )
                                                )}
                                                <div className='d-flex justify-content-center'>
                                                    <BlueButton
                                                        className='d-flex align-items-center'
                                                        style={{ width: 165 }}
                                                        onClick={() => handle_add_item(r)}>
                                                        <img src={plus_white} alt="more" style={{ marginRight: 8 }} />
                                                        Add More
                                                    </BlueButton>
                                                </div>
                                            </div>}
                                        {section_types.type_4.includes(selected_section.side_bar) &&
                                            <div>
                                                {resume_[r]?.items?.map((item, i) => (
                                                    <div style={i !== 0 ? { marginTop: 30 } : {}}
                                                        onClick={() => {
                                                            set_selected_section({
                                                                ...selected_section, side_bar: r,
                                                                section_controller: r, type_4_item: i, index: i
                                                            })
                                                        }}>
                                                        <SectionTitleContainer>
                                                            <Section4Title>
                                                                {toWordCase(decor[selected_section?.side_bar]?.label)}
                                                            </Section4Title>
                                                            {resume_[r]?.items?.length > 1 && <div>
                                                                <ActionBtn onClick={() => displayDeleteConfirmation(r, i)}>
                                                                    <img src={delete_mobile} alt="" width={24} height={24} />
                                                                </ActionBtn>
                                                                <ActionBtn onClick={() => type_4_swap(i, r, "up")}>
                                                                    <img src={arrow_up} alt="" width={24} height={24} />
                                                                </ActionBtn>
                                                                <ActionBtn onClick={() => type_4_swap(i, r, "down")}>
                                                                    <img src={arrow_down} alt="" width={24} height={24} />
                                                                </ActionBtn>
                                                            </div>}
                                                        </SectionTitleContainer>
                                                        <div>
                                                            <SectionLabel>
                                                                Designation
                                                            </SectionLabel>
                                                            <SectionInput
                                                                type="text"
                                                                name="designation"
                                                                value={item?.designation}
                                                                placeholder={r == "education" ? "Enter a Degree" : "Designation"}
                                                                onChange={(e) => handle_titles_of_type_4(e, r, i)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <SectionLabel>
                                                                Company
                                                            </SectionLabel>
                                                            <SectionInput
                                                                type="text"
                                                                name="company"
                                                                value={item?.company}
                                                                placeholder={r == "education" ? "Enter a college/institute" : "Company"}
                                                                onChange={(e) => handle_titles_of_type_4(e, r, i)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <SectionLabel>
                                                                Duration
                                                            </SectionLabel>
                                                            <div className='d-flex'>
                                                                <SectionDate style={{ marginRight: 8 }}
                                                                    onClick={() => {
                                                                        set_calendar_index(i);
                                                                        set_start_calendar_view(!start_calendar_view);
                                                                    }}
                                                                >
                                                                    {item?.startDate ? `${moment(new Date(item?.startDate)).format(selectedDateFormat)}`
                                                                        : `${moment().format(selectedDateFormat)}`}
                                                                    <img src={calendar} alt="date" width={24} height={24} />
                                                                </SectionDate>
                                                                <SectionDate
                                                                    onClick={() => {
                                                                        set_calendar_index(i);
                                                                        set_end_calendar_view(!end_calendar_view);
                                                                    }}
                                                                >
                                                                    {item?.endDate && moment(new Date(item?.endDate)).isValid()
                                                                        ? `${moment(new Date(item?.endDate)).format(selectedDateFormat)}`
                                                                        : 'Present'}
                                                                    <img src={calendar} alt="date" width={24} height={24} />
                                                                </SectionDate>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <SectionLabel>
                                                                Location
                                                            </SectionLabel>
                                                            <SectionInput
                                                                type="text"
                                                                name="location"
                                                                value={item?.location}
                                                                placeholder={"Location"}
                                                                onInput={(e) => handle_titles_of_type_4(e, r, i)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <SectionLabel style={{ marginBottom: 8 }}>
                                                                Description
                                                            </SectionLabel>
                                                            <QuillEditor
                                                                index={`${r}_quill_${i}`}
                                                                placeholder="Enter text"
                                                                set_index={set_editor_index}
                                                                set_tool_view={set_tool_view}
                                                                tool_view={tool_view}
                                                                set_resume={set_resume}
                                                                editorMethods={editorMethods}
                                                                keys={r}
                                                                value={item?.content}
                                                                item_index={i}
                                                                isMobile={true}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                                )}
                                                <div className='d-flex justify-content-center' style={{ marginTop: 20 }}>
                                                    <BlueButton
                                                        className='d-flex align-items-center'
                                                        style={{ width: 165 }}
                                                        onClick={() => handle_add_type_4_item(r)}>
                                                        <img src={plus_white} alt="more" style={{ marginRight: 8 }} />
                                                        Add More
                                                    </BlueButton>
                                                </div>
                                                <Modal
                                                    onHide={() => {
                                                        set_start_calendar_view(false);
                                                        set_end_calendar_view(false);
                                                    }
                                                    }
                                                    show={start_calendar_view || end_calendar_view}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <div
                                                        style={{
                                                            boxShadow: '0 0 16px rgba(0, 0, 0, 0.25)',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: "center",
                                                            backgroundColor: 'white'
                                                        }}
                                                    >
                                                        <Calendar
                                                            onChange={date => { handle_calendar(date) }}
                                                            value={(start_calendar_view) ? start_date : end_date}
                                                            maxDetail="year"
                                                            locale="en-US"
                                                        />
                                                        {
                                                            (end_calendar_view) &&
                                                            <BUTTON
                                                                clr="#fff"
                                                                bord="0"
                                                                brad="12"
                                                                wid="80%"
                                                                pad="4px 0"
                                                                bg_color='#4984EA'
                                                                onClick={() => { handle_calendar("Present") }}
                                                                type='submit'
                                                                f_family='montserrat_semibold'
                                                                style={{
                                                                    borderRadius: "12px",
                                                                    margin: "12px 0 12px 0",
                                                                    boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)'
                                                                }}
                                                            >
                                                                Present
                                                            </BUTTON>
                                                        }
                                                    </div>
                                                </Modal>
                                            </div>}
                                        {selected_section?.side_bar == "personalInformation" &&
                                            <div>
                                                {
                                                    !resume_["linkedin"] &&
                                                    <div>
                                                        {
                                                            Object.keys(resume_["personalInformation"])?.map(v => (
                                                                v !== "qr" &&
                                                                <div>
                                                                    <SectionLabel>
                                                                        {decor[v]?.label}
                                                                    </SectionLabel>
                                                                    <SectionInput
                                                                        key={previous_selected_section}
                                                                        name={v}
                                                                        value={temporary_state_pi[v]}
                                                                        placeholder={decor[v]?.placeholder}
                                                                        onChange={handle_pi}
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                ) : null
                        ))
                }
            </SectionContainer>}
            <PreviewButton id={id} onAppear={saveAll} />
            <DeleteSectionModal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                sectionToRemove={sectionToRemove}
                itemToRemove={itemToRemove}
                mobSection={true} />
        </BuilderContainer>
    )
}

export default ResumeSection