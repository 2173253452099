import './App.css';
import React from 'react';
import Constructor from './Constructor';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
    token_engineer, login_modal as login_modal_atom,
    signup_view as signup_view_atom, reset_modal,
    prices as prices_atom,
    purchase_modal as purchase_modal_atom,
    page_loading, user_info, purchase_status
} from './recoil/store';
import TemplatePicker from './pages/TemplatePicker';
import Dashboard from './pages/Dashboard';
import Header from './Component/Layout/Header';
import Profile from './pages/Profile';
import { Modal } from 'react-bootstrap';
import { BUTTON, GENERAL_BLOCK, INPUT, INPUT_LABEL, LINK_BUTTON, TEXT } from './styled';
import { LOGIN_CONTAINER } from './pages/page-style/login.styled';
import { enqueueSnackbar } from 'notistack';
import { login, register, reset_password, set_password } from './services/auth';
import useMediaQuery from './Component/useMediaQuery';
import GoToDesktop from './pages/GoToDesktop';
import access_icon from './images/icons/access-icon.svg';
import access_icon_mobile from './images/icons/access-icon-mobile.svg';
import DownloadConstructor from './DownloadConstructor';
import { CircularProgress } from '@mui/material';
import { getPrices, getUserInfo, payment } from './services/userCv';
import ResumeBuilderMobile from './pages/Mobile/ResumeBuilderMobile';
import ResumeSection from './pages/Mobile/ResumeSection';
import Preview from './pages/Mobile/Preview';

function App() {

    const screenWidth = window.innerWidth;
    const [token, set_token] = useRecoilState(token_engineer);
    const [user_info_, set_user_info] = useRecoilState(user_info);
    const [login_modal, set_login_modal] = useRecoilState(login_modal_atom);
    const [signup_view, set_signup_view] = useRecoilState(signup_view_atom);
    const [reset_modal_, set_reset_modal] = useRecoilState(reset_modal);
    const [purchase_modal, set_purchase_modal] = useRecoilState(purchase_modal_atom);
    const [page_loading_, set_page_loading] = useRecoilState(page_loading);
    const [purchase_status_, set_purchase_status] = useRecoilState(purchase_status);
    const [purchase_status_modal, set_purchase_status_modal] = useState(false);
    const [prices, set_prices] = useRecoilState(prices_atom);
    const [email, handle_email] = useState("");
    const [purchase_loading, set_purchase_loading] = useState(false);
    const [eye_icon1, set_eye_icon1] = useState(false);
    const [eye_icon2, set_eye_icon2] = useState(false);
    const isMobile = useMediaQuery('(max-width: 900px)');
    const isMobilePremium = useMediaQuery('(max-width: 576px)');
    const nav = useNavigate();
    let loc = useLocation();
    let persist_token = JSON.parse(localStorage.getItem("token"));

    useEffect(() => {
        get_prices();
    }, [user_info_])

    useEffect(() => {
        switch (true) {
            case loc.pathname === '/resume-builder':
                nav("/");
                break;
            case loc.pathname.includes("/resume-builder") && screenWidth <= 900:
                nav("/");
                break;
            case loc.pathname.includes("/profile") && !loc.search.length && persist_token?.access === "":
                nav("/");
                break;
            default:
                break;
        }
    }, [loc]);

    useEffect(() => {
        if (screenWidth > 900 && (loc.pathname.includes("rb-mobile") ||
            loc.pathname.includes("resume-section") ||
            loc.pathname.includes("cv-preview"))) {
            nav("/");
        }
    }, [loc]);

    useEffect(() => {
        switch (true) {
            case loc.pathname?.includes("reset-password"):
                let patternReset = "/reset-password/";
                let resetCode = loc.pathname.replace(patternReset, "");
                reset_ref.current = resetCode;
                set_reset_modal({ ...reset_modal, reset_view: true, new_pass_view: true, mail_sent_view: false });
                set_signup_view(false);
                break;
            case loc.pathname?.includes("/user/referal="):
                if (!persist_token?.access) {
                    let patternReferal = "/user/referal=";
                    let referalCode = loc.pathname.replace(patternReferal, "");
                    referal_ref.current = referalCode;
                    set_login_modal(true);
                    set_signup_view(true);
                } else {
                    nav("/");
                }
                break;
            case loc.pathname === '/payment/result':
                handle_payment_result();
                break;
            default:
                break;
        }
    }, []);

    const handle_login = (value, field_name) => set_token({ ...token, [field_name]: value });

    let password_ref = useRef(null);
    let c_password_ref = useRef(null);
    let reset_ref = useRef(null);
    let referal_ref = useRef(null);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const get_prices = async () => {
        if (user_info_ != null) {
            let priceInfo = await getPrices();
            set_prices(priceInfo)
        }
    }

    const get_price = (discounted = true) => {
        const language = navigator.language;
        const code = (language === "ru") ? "prodamus" : "stripe";
        const currency = (language === "ru") ? "₽" : "$";
        const pricesArray = Array.isArray(prices.rows) ? prices.rows : [];
        const priceItem = pricesArray.find(item => item.code === code);
        if (priceItem) {
            let priceAmount = (discounted) ? priceItem.originalPrice : priceItem.price;
            return (language === "ru") ? (priceAmount + currency) : (currency + priceAmount);
        } else {
            return 0;
        }
    };

    const handle_payment_result = () => {
        const queryParams = new URLSearchParams(loc.search);
        const payformStatus = queryParams.get('_payform_status');
        const stripeStatus = queryParams.get('_stripe_status');
        if (payformStatus === "success" || stripeStatus === "success") {
            handle_payment_verification();
        } else {
            set_purchase_status(false);
            set_purchase_status_modal(true);
        }
    };

    const handle_payment_verification = async () => {
        set_page_loading(true);
        await delay(5000);
        let userInfo = await getUserInfo();
        if (userInfo?.isPremium) {
            set_user_info(userInfo);
            set_page_loading(false);
            set_purchase_status(true);
            set_purchase_status_modal(true);
        } else {
            handle_payment_verification();
        }
    };

    const updateUserInfo = async () => {
        let userInfo = await getUserInfo();
        set_user_info(userInfo);
    };

    const show_password = (type) => {
        if (type == "confirm") {
            set_eye_icon2(!eye_icon2)
        } else {
            set_eye_icon1(!eye_icon1)
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        const { email, password } = { ...token };
        try {
            const params = signup_view
                ? referal_ref?.current
                    ? { ...token, invitationCode: referal_ref.current }
                    : { ...token }
                : { email, password };

            const response = signup_view
                ? await register(params)
                : await login(params);

            const { accessToken, refreshToken } = response?.data || {};
            localStorage.setItem("token", JSON.stringify({ access: accessToken, refresh: refreshToken }));
            set_token({ ...token, access: accessToken });
            set_login_modal(false);

            const message = signup_view ? "Successfully signed up and signed in!" : "Successfully signed in!";
            enqueueSnackbar(message, { variant: "success" });
            await updateUserInfo(); nav(0);
        } catch (error) {
            let errorMessage = error.response?.data?.message || "Something went wrong, please try again later.";
            if (error?.response) {
                const statusCode = error.response.status;
                if (statusCode === 401) {
                    errorMessage = "Incorrect email or password.";
                } else if (statusCode === 403) {
                    errorMessage = "This account is registered through Google. Please sign in using Google.";
                } else if (statusCode === 409) {
                    errorMessage = "This account is already registered. Please use 'Forgot Password' to reset your password.";
                }
            }
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    };

    const handle_premium_purchase = async () => {
        try {
            set_purchase_loading(true);
            const response = await payment({
                type: (navigator.language == "ru") ? "prodamus" : "stripe"
            });
            const link = response.data?.link;
            if (link) {
                window.location.href = link;
            } else {
                set_purchase_loading(false);
                const errorMessage = "Invalid link received!";
                enqueueSnackbar(errorMessage, { variant: "error" });
            }
        } catch (error) {
            set_purchase_loading(false);
            if (error.response.status == 403) {
                let userInfo = await getUserInfo();
                set_user_info(userInfo);
                set_purchase_modal(false);
                enqueueSnackbar("You already have full access, please refresh the page.", { variant: "warning" });
            } else {
                enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" });
            }
        }
    };

    const handle_reset_password = async (e) => {
        e.preventDefault();
        try {
            let response = await reset_password({ email });
            if (response?.status === 200) {
                set_reset_modal({ ...reset_modal_, mail_sent_view: true });
            }
        } catch (error) {
            let errorMessage;
            if (error?.response) {
                const statusCode = error.response.status;
                if (statusCode === 401) {
                    errorMessage = "Please enter a valid email and password.";
                } else if (statusCode === 403) {
                    errorMessage = "This account is registered through Google. Please sign in using Google.";
                } else {
                    errorMessage = "Something is wrong, please try again later.";
                }
            } else {
                errorMessage = "An error occurred. Please try again.";
            }
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    const handle_set_password = async (e) => {
        e.preventDefault();
        try {
            let response = await set_password({ password: token?.password, reference: reset_ref.current });
            if (response?.status == 200) {
                set_reset_modal({ ...reset_modal_, reset_view: false, new_pass_view: true });
                // set_new_pass_view(true);
                enqueueSnackbar("Password has been successfully reset", { variant: "success" });
                if (persist_token?.access !== "") {
                    nav("/")
                } else {
                    set_login_modal(true)
                }
            }
        } catch (error) {
            enqueueSnackbar(error.response?.data?.error, { variant: "error" })
        }
    }

    let routes_with_token = [
        "resume-builder",
        "resume-builder/:id",
        "resume-builder/d/:id",
        "rb-mobile/:id",
        'resume-section',
        "pick-a-template"
    ]

    return (
        <div className="App">
            <style>{`
                .loading_modal .modal-content {
                    background: transparent;
                    border: 0;
            }  .loading_modal .modal-content .modal_container {
                    height: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
            } .loading_modal .modal-dialog {
                max-width: 100%
            }`}</style>
            {
                !loc.pathname.includes("/d/") && !loc.pathname.includes("rb-mobile") &&
                !loc.pathname.includes("resume-section") && !loc.pathname.includes("cv-preview") &&
                <Header />
            }
            <Routes>
                {
                    persist_token?.access == null &&
                    <>
                        {
                            routes_with_token?.map((pr, i) => (
                                <Route key={i} path={`/${pr}`} element={<Navigate to="/" />}></Route>
                            ))
                        }
                    </>
                }
                <Route path='/resume-builder/:id' element={<Constructor />} />
                <Route path='/rb-mobile/:id' element={<ResumeBuilderMobile />} />
                <Route path='/resume-section/:id/:section/:index' element={<ResumeSection />} />
                <Route path='/cv-preview/:id' element={<Preview />} />
                {
                    persist_token?.access !== null &&
                    <Route path='/pick-a-template' element={<TemplatePicker />} />
                }
                {
                    persist_token?.access !== null &&
                    <Route path='/profile' element={<Profile />} />
                }
                <Route path='/builder/d/:id/:token' element={<DownloadConstructor />} />
                <Route path='/' element={<Dashboard />} />
                <Route path='/reset-password/:id' element={<Dashboard />} />
                <Route path='/user/referal=/*' element={<Dashboard />} />
            </Routes >
            <Modal
                onHide={() => set_login_modal(false)}
                show={login_modal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="login_modal"
            >
                <div className="modal_container">
                    <div className='jcsb aic'>
                        <div className="modal_title">
                            Create Your Resume!
                        </div>
                        <div className='mt-3'>
                            {
                                signup_view ?
                                    <div className='d-flex aic'>Already have an account? <BUTTON
                                        f_size="93%"
                                        bord="0"
                                        bg_color="transparent"
                                        f_family="montserrat_bold"
                                        brad="4px"
                                        className='d-flex aic jcc text-center ms-1'
                                        pad="0"
                                        clr="#4984EA"
                                        wei="normal"
                                        onClick={() => set_signup_view(false)}>Login</BUTTON></div> :
                                    <div className='d-flex aic'>Or <BUTTON
                                        f_size="93%"
                                        bord="0"
                                        bg_color="transparent"
                                        f_family="montserrat_bold"
                                        brad="4px"
                                        className='d-flex aic jcc text-center ms-1'
                                        pad="0"
                                        clr="#4984EA"
                                        wei="normal"
                                        onClick={() => set_signup_view(true)}>Create an Account</BUTTON></div>
                            }
                        </div>
                    </div>
                    <div className="mt-4">
                        <LINK_BUTTON
                            href={'https://engineernow.org/api/auth/google'}
                            wid="100%"
                            f_size="93%"
                            bord="1px solid #1C1C1C"
                            bg_color="transparent"
                            f_family="montserrat_bold"
                            brad="4px"
                            className='d-flex aic jcc text-center'
                            pad="12px 0"
                            wei="normal"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clipPath="url(#clip0_737_3432)">
                                    <path d="M12.24 9.81836V14.4657H18.6982C18.4146 15.9602 17.5636 17.2257 16.2872 18.0766L20.1818 21.0985C22.4509 19.004 23.76 15.9276 23.76 12.273C23.76 11.4221 23.6836 10.6039 23.5418 9.81849L12.24 9.81836Z" fill="#4285F4" />
                                    <path d="M5.51487 14.2842L4.6365 14.9566L1.52734 17.3784C3.50189 21.2947 7.54889 24.0002 12.2398 24.0002C15.4797 24.0002 18.1961 22.9311 20.1816 21.0984L16.287 18.0766C15.2179 18.7966 13.8543 19.233 12.2398 19.233C9.11979 19.233 6.46894 17.1275 5.51978 14.2911L5.51487 14.2842Z" fill="#34A853" />
                                    <path d="M1.5273 6.62207C0.709157 8.23656 0.240112 10.0584 0.240112 12.0002C0.240112 13.942 0.709157 15.7639 1.5273 17.3784C1.5273 17.3892 5.52012 14.2802 5.52012 14.2802C5.28012 13.5602 5.13826 12.7966 5.13826 12.0001C5.13826 11.2036 5.28012 10.44 5.52012 9.72002L1.5273 6.62207Z" fill="#FBBC05" />
                                    <path d="M12.24 4.7782C14.0073 4.7782 15.5782 5.3891 16.8328 6.56731L20.2691 3.13097C18.1855 1.18917 15.4801 0 12.24 0C7.54914 0 3.5019 2.69456 1.52734 6.62186L5.52004 9.72007C6.46908 6.88366 9.12004 4.7782 12.24 4.7782Z" fill="#EA4335" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_737_3432">
                                        <rect width="23.52" height="24" fill="white" transform="translate(0.23999)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className='ms-2'>{signup_view ? "Sign up" : "Sign in"} with Google</span>
                        </LINK_BUTTON>
                        <div className='divider'>
                            <span>Or continue with</span>
                        </div>
                        <div className='mt-4'>
                            <form action="">
                                <LOGIN_CONTAINER>
                                    {
                                        signup_view &&
                                        <>
                                            <div className='mb-2'>
                                                <INPUT_LABEL
                                                    f_family="montserrat_semibold"
                                                >
                                                    Name*
                                                </INPUT_LABEL>
                                                <INPUT
                                                    type='email'
                                                    className='mt-1'
                                                    f_family="montserrat_medium"
                                                    placeholder='Name'
                                                    autoComplete='off'
                                                    wid="505px"
                                                    hei="46px"
                                                    brad="6px"
                                                    pad="12px 24px 12px 24px"
                                                    bord="1px solid rgb(28 28 28 / 48%)"
                                                    value={token?.name}
                                                    onChange={(e) => handle_login(e.target.value, "name")}
                                                />
                                            </div>
                                            <div className='mb-2'>
                                                <INPUT_LABEL
                                                    f_family="montserrat_semibold"
                                                >
                                                    Surname*
                                                </INPUT_LABEL>
                                                <INPUT
                                                    type='email'
                                                    className='mt-1'
                                                    f_family="montserrat_medium"
                                                    placeholder='Surname'
                                                    wid="505px"
                                                    hei="46px"
                                                    brad="6px"
                                                    pad="12px 24px 12px 24px"
                                                    bord="1px solid rgb(28 28 28 / 48%)"
                                                    value={token?.surname}
                                                    onChange={(e) => handle_login(e.target.value, "surname")}
                                                />
                                            </div>
                                        </>
                                    }
                                    <div className='mb-2'>
                                        <INPUT_LABEL
                                            f_family="montserrat_semibold"
                                        >
                                            Email Address*
                                        </INPUT_LABEL>
                                        <INPUT
                                            type='email'
                                            className='mt-1'
                                            f_family="montserrat_medium"
                                            placeholder='Email Address'
                                            wid="505px"
                                            hei="46px"
                                            brad="6px"
                                            pad="12px 24px 12px 24px"
                                            bord="1px solid rgb(28 28 28 / 48%)"
                                            value={token?.email}
                                            onChange={(e) => handle_login(e.target.value, "email")}
                                        />
                                    </div>
                                    <div className='mb-2'>
                                        <INPUT_LABEL
                                            f_family="montserrat_semibold"
                                        >
                                            Password*
                                        </INPUT_LABEL>
                                        <div className='position-relative'>
                                            <INPUT
                                                ref={password_ref}
                                                className='mt-1'
                                                f_family="montserrat_medium"
                                                placeholder='Password'
                                                wid="505px"
                                                hei="46px"
                                                brad="6px"
                                                pad="12px 24px 12px 24px"
                                                bord="1px solid rgb(28 28 28 / 48%)"
                                                type={!eye_icon1 ? "password" : "text"}
                                                value={token?.password}
                                                onChange={(e) => handle_login(e.target.value, "password")}
                                            />
                                            <span
                                                className="eye_blink"
                                                onClick={() => show_password("password")}
                                            >
                                                {
                                                    !eye_icon1 ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <g clipPath="url(#clip0_737_3452)">
                                                                <path d="M12 6.49969C14.76 6.49969 17 8.73969 17 11.4997C17 12.0097 16.9 12.4997 16.76 12.9597L19.82 16.0197C21.21 14.7897 22.31 13.2497 23 11.4897C21.27 7.10969 17 3.99969 12 3.99969C10.73 3.99969 9.51 4.19969 8.36 4.56969L10.53 6.73969C11 6.59969 11.49 6.49969 12 6.49969ZM2.71 3.15969C2.32 3.54969 2.32 4.17969 2.71 4.56969L4.68 6.53969C3.06 7.82969 1.77 9.52969 1 11.4997C2.73 15.8897 7 18.9997 12 18.9997C13.52 18.9997 14.97 18.6997 16.31 18.1797L19.03 20.8997C19.42 21.2897 20.05 21.2897 20.44 20.8997C20.83 20.5097 20.83 19.8797 20.44 19.4897L4.13 3.15969C3.74 2.76969 3.1 2.76969 2.71 3.15969ZM12 16.4997C9.24 16.4997 7 14.2597 7 11.4997C7 10.7297 7.18 9.99969 7.49 9.35969L9.06 10.9297C9.03 11.1097 9 11.2997 9 11.4997C9 13.1597 10.34 14.4997 12 14.4997C12.2 14.4997 12.38 14.4697 12.57 14.4297L14.14 15.9997C13.49 16.3197 12.77 16.4997 12 16.4997ZM14.97 11.1697C14.82 9.76969 13.72 8.67969 12.33 8.52969L14.97 11.1697Z" fill="#1C1C1C" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_737_3452">
                                                                    <rect width="24" height="24" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg> :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z" fill="#1C1C1C" />
                                                            <path d="M11.9979 9.14062C11.2408 9.14062 10.5146 9.44142 9.97915 9.97684C9.44374 10.5123 9.14294 11.2384 9.14294 11.9956C9.14294 12.7528 9.44374 13.479 9.97915 14.0144C10.5146 14.5498 11.2408 14.8506 11.9979 14.8506C13.5679 14.8506 14.8579 13.5706 14.8579 12.0006C14.8579 10.4306 13.5679 9.14062 11.9979 9.14062Z" fill="#1C1C1C" />
                                                        </svg>
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-2">{!signup_view &&
                                        <BUTTON
                                            f_size="93%"
                                            bord="0"
                                            bg_color="transparent"
                                            f_family="montserrat_bold"
                                            brad="4px"
                                            className='d-flex aic jcc text-center ms-1'
                                            pad="0"
                                            clr="#4984EA"
                                            wei="normal"
                                            type='button'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault();
                                                set_login_modal(false);
                                                set_reset_modal({ ...reset_modal_, reset_view: true })
                                            }}
                                        >Forgot Password?</BUTTON>
                                    }</div>
                                    <BUTTON
                                        className='mt-5'
                                        clr="#fff"
                                        bord="0"
                                        pad="12px 0"
                                        bg_color='#4984EA'
                                        brad="6px"
                                        wid="100%"
                                        onClick={submit}
                                        type='submit'
                                    >
                                        {signup_view ? "Sign Up" : "Sign in"}
                                    </BUTTON>
                                    <div className='privacy'>By continuing, you’re agreeing to our <a href="https://engineernow.org/terms-and-conditions" target='_blank' rel="noreferrer">Customer Terms of Service</a> and <a href="https://engineernow.org/privacy-policy" target='_blank' rel="noreferrer">Privacy Policy</a>.</div>
                                </LOGIN_CONTAINER>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* PAYMENT RESULT */}
            <Modal
                show={purchase_status_modal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="purchase_status_modal"
            >
                <div className="modal_container position-relative">
                    <GENERAL_BLOCK
                        poss="absolute"
                        topp="40px"
                        rightt="40px"
                    >
                        <BUTTON
                            bord="0"
                            bg_color="transparent"
                            type='button'
                            onClick={() => {
                                set_purchase_status_modal(false);
                                nav("/");
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <g clipPath="url(#clip0_117_2914)">
                                    <path d="M22.6 11.286L17.886 
                            16L22.6 20.714C23.1186 21.2326 23.1186 22.0811 22.6 22.5997C22.0815 
                            23.1182 21.2329 23.1182 20.7144 22.5997L16.0004 17.8856L11.2863 
                            22.5997C10.7678 23.1182 9.91923 23.1182 9.40069 22.5997C8.88214 22.0811 8.88214 
                            21.2326 9.40069 
                            20.714L14.1147 16L9.40069 11.286C8.88214 10.7674 8.88214 9.91888 9.40069
                            9.40034C9.91923 8.88179
                            10.7678 8.88179 11.2863 9.40034L16.0004 14.1144L20.7144 9.40034C21.2329
                            8.88179 22.0815 8.88179
                            22.6 9.40034C23.1186 9.91888 23.1186 10.7674 22.6 11.286Z" fill="#1C1C1C" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_117_2914">
                                        <rect width="32" height="32" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </BUTTON>
                    </GENERAL_BLOCK>
                    {
                        <div>
                            <TEXT
                                f_size={isMobile ? '20px' : '36px'}
                                clr="#1C1C1C"
                                f_family='montserrat_semibold'
                            >Premium Purchase</TEXT>
                            <br />
                            <TEXT
                                f_size={!isMobile ? '16px' : '18px'}
                                clr="#1C1C1C"
                                f_family='montserrat_semibold'
                            >
                                {(!purchase_status_) ? "Your payment has failed. Please try again." : "Your payment was successfull. Enjoy premium features."}
                            </TEXT>
                        </div>
                    }
                </div>
            </Modal>
            {/* RESET MODALS */}
            <Modal
                onHide={() => {
                    if (loc.pathname.includes("/reset-password/")) {
                        nav("/")
                        set_reset_modal({ ...reset_modal_, reset_view: false, mail_sent_view: false })
                    } else {
                        set_reset_modal({ ...reset_modal_, reset_view: false, mail_sent_view: false })
                    }
                }}
                show={reset_modal_?.reset_view}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="login_modal"
            >
                <form action="">
                    <div className="modal_container position-relative">
                        <GENERAL_BLOCK
                            poss="absolute"
                            topp="40px"
                            rightt="40px"
                        >
                            <BUTTON
                                bord="0"
                                bg_color="transparent"
                                type='button'
                                onClick={() => {
                                    if (loc.pathname.includes("/reset-password/")) {
                                        nav("/")
                                        set_reset_modal({ ...reset_modal_, reset_view: false, mail_sent_view: false })
                                    } else {
                                        set_reset_modal({ ...reset_modal_, reset_view: false, mail_sent_view: false })
                                    }
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <g clipPath="url(#clip0_117_2914)">
                                        <path d="M22.6 11.286L17.886 
                            16L22.6 20.714C23.1186 21.2326 23.1186 22.0811 22.6 22.5997C22.0815 
                            23.1182 21.2329 23.1182 20.7144 22.5997L16.0004 17.8856L11.2863 
                            22.5997C10.7678 23.1182 9.91923 23.1182 9.40069 22.5997C8.88214 22.0811 8.88214 
                            21.2326 9.40069 
                            20.714L14.1147 16L9.40069 11.286C8.88214 10.7674 8.88214 9.91888 9.40069
                            9.40034C9.91923 8.88179
                            10.7678 8.88179 11.2863 9.40034L16.0004 14.1144L20.7144 9.40034C21.2329
                            8.88179 22.0815 8.88179
                            22.6 9.40034C23.1186 9.91888 23.1186 10.7674 22.6 11.286Z" fill="#1C1C1C" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_117_2914">
                                            <rect width="32" height="32" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </BUTTON>
                        </GENERAL_BLOCK>
                        {
                            reset_modal_?.mail_sent_view ? <>
                                <div>
                                    <TEXT
                                        f_size={isMobile ? '20px' : '36px'}
                                        clr="#1C1C1C"
                                        f_family='montserrat_semibold'
                                    >Password Reset</TEXT>
                                    <br />
                                    <TEXT
                                        f_size={!isMobile ? '16px' : '18px'}
                                        clr="#1C1C1C"
                                        f_family='montserrat_semibold'
                                    >
                                        We have sent you an email. Please <a href="mailto: info@engineernow.org" style={{ textDecoration: "none" }}>contact us</a> if you do not receive it within a few minutes.
                                    </TEXT>
                                </div>
                            </> :
                                reset_modal_?.new_pass_view ? <>
                                    <div className='mb-2'>
                                        <div>
                                            <TEXT
                                                f_size="36px"
                                                clr="#1C1C1C"
                                                f_family='montserrat_semibold'
                                            >Reset your password</TEXT>
                                            <br />
                                            <TEXT
                                                f_size="20px"
                                                clr="#1C1C1C"
                                                f_family='montserrat_semibold'
                                            >
                                                Set the new password for your account so you can login and access all the features.
                                            </TEXT>
                                        </div>
                                        <br />
                                        <INPUT_LABEL
                                            f_family="montserrat_semibold"
                                        >
                                            New Password
                                        </INPUT_LABEL>
                                        <div className='position-relative'>
                                            <INPUT
                                                ref={password_ref}
                                                className='mt-1'
                                                f_family="montserrat_medium"
                                                placeholder='Password'
                                                wid="505px"
                                                hei="46px"
                                                brad="6px"
                                                pad="12px 24px 12px 24px"
                                                bord="1px solid rgb(28 28 28 / 48%)"
                                                type={!eye_icon1 ? "password" : "text"}
                                                // autoComplete="new-password"
                                                value={token?.password}
                                                onChange={(e) => handle_login(e.target.value, "password")}
                                            />
                                            <span
                                                className="eye_blink"
                                                onClick={() => show_password("password")}
                                            >
                                                {
                                                    !eye_icon1 ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <g clipPath="url(#clip0_737_3452)">
                                                                <path d="M12 6.49969C14.76 6.49969 17 8.73969 17 11.4997C17 12.0097 16.9 12.4997 16.76 12.9597L19.82 16.0197C21.21 14.7897 22.31 13.2497 23 11.4897C21.27 7.10969 17 3.99969 12 3.99969C10.73 3.99969 9.51 4.19969 8.36 4.56969L10.53 6.73969C11 6.59969 11.49 6.49969 12 6.49969ZM2.71 3.15969C2.32 3.54969 2.32 4.17969 2.71 4.56969L4.68 6.53969C3.06 7.82969 1.77 9.52969 1 11.4997C2.73 15.8897 7 18.9997 12 18.9997C13.52 18.9997 14.97 18.6997 16.31 18.1797L19.03 20.8997C19.42 21.2897 20.05 21.2897 20.44 20.8997C20.83 20.5097 20.83 19.8797 20.44 19.4897L4.13 3.15969C3.74 2.76969 3.1 2.76969 2.71 3.15969ZM12 16.4997C9.24 16.4997 7 14.2597 7 11.4997C7 10.7297 7.18 9.99969 7.49 9.35969L9.06 10.9297C9.03 11.1097 9 11.2997 9 11.4997C9 13.1597 10.34 14.4997 12 14.4997C12.2 14.4997 12.38 14.4697 12.57 14.4297L14.14 15.9997C13.49 16.3197 12.77 16.4997 12 16.4997ZM14.97 11.1697C14.82 9.76969 13.72 8.67969 12.33 8.52969L14.97 11.1697Z" fill="#1C1C1C" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_737_3452">
                                                                    <rect width="24" height="24" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg> :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z" fill="#1C1C1C" />
                                                            <path d="M11.9979 9.14062C11.2408 9.14062 10.5146 9.44142 9.97915 9.97684C9.44374 10.5123 9.14294 11.2384 9.14294 11.9956C9.14294 12.7528 9.44374 13.479 9.97915 14.0144C10.5146 14.5498 11.2408 14.8506 11.9979 14.8506C13.5679 14.8506 14.8579 13.5706 14.8579 12.0006C14.8579 10.4306 13.5679 9.14062 11.9979 9.14062Z" fill="#1C1C1C" />
                                                        </svg>
                                                }
                                            </span>
                                        </div>

                                    </div>
                                    <div className='mb-2'>
                                        <INPUT_LABEL
                                            f_family="montserrat_semibold"
                                        >
                                            Confirm New Password
                                        </INPUT_LABEL>
                                        <div className='position-relative'>
                                            <INPUT
                                                ref={c_password_ref}
                                                className='mt-1'
                                                f_family="montserrat_medium"
                                                placeholder='Password'
                                                wid="505px"
                                                hei="46px"
                                                brad="6px"
                                                pad="12px 24px 12px 24px"
                                                bord="1px solid rgb(28 28 28 / 48%)"
                                                type={!eye_icon2 ? "password" : "text"}
                                            // autoComplete="new-password"
                                            // value={token?.confirm_password}
                                            // onChange={(e) => handle_login(e.target.value, "confirm_password")}
                                            />
                                            <span
                                                className="eye_blink"
                                                onClick={() => show_password("confirm")}
                                            >
                                                {
                                                    !eye_icon2 ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <g clipPath="url(#clip0_737_3452)">
                                                                <path d="M12 6.49969C14.76 6.49969 17 8.73969 17 11.4997C17 12.0097 16.9 12.4997 16.76 12.9597L19.82 16.0197C21.21 14.7897 22.31 13.2497 23 11.4897C21.27 7.10969 17 3.99969 12 3.99969C10.73 3.99969 9.51 4.19969 8.36 4.56969L10.53 6.73969C11 6.59969 11.49 6.49969 12 6.49969ZM2.71 3.15969C2.32 3.54969 2.32 4.17969 2.71 4.56969L4.68 6.53969C3.06 7.82969 1.77 9.52969 1 11.4997C2.73 15.8897 7 18.9997 12 18.9997C13.52 18.9997 14.97 18.6997 16.31 18.1797L19.03 20.8997C19.42 21.2897 20.05 21.2897 20.44 20.8997C20.83 20.5097 20.83 19.8797 20.44 19.4897L4.13 3.15969C3.74 2.76969 3.1 2.76969 2.71 3.15969ZM12 16.4997C9.24 16.4997 7 14.2597 7 11.4997C7 10.7297 7.18 9.99969 7.49 9.35969L9.06 10.9297C9.03 11.1097 9 11.2997 9 11.4997C9 13.1597 10.34 14.4997 12 14.4997C12.2 14.4997 12.38 14.4697 12.57 14.4297L14.14 15.9997C13.49 16.3197 12.77 16.4997 12 16.4997ZM14.97 11.1697C14.82 9.76969 13.72 8.67969 12.33 8.52969L14.97 11.1697Z" fill="#1C1C1C" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_737_3452">
                                                                    <rect width="24" height="24" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg> :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z" fill="#1C1C1C" />
                                                            <path d="M11.9979 9.14062C11.2408 9.14062 10.5146 9.44142 9.97915 9.97684C9.44374 10.5123 9.14294 11.2384 9.14294 11.9956C9.14294 12.7528 9.44374 13.479 9.97915 14.0144C10.5146 14.5498 11.2408 14.8506 11.9979 14.8506C13.5679 14.8506 14.8579 13.5706 14.8579 12.0006C14.8579 10.4306 13.5679 9.14062 11.9979 9.14062Z" fill="#1C1C1C" />
                                                        </svg>
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <TEXT
                                        f_size="12px"
                                        clr="#1C1C1C"
                                        f_family='montserrat_medium'
                                    >
                                        Must be at least 8 characters
                                    </TEXT>
                                    <div className='d-flex'>
                                        <BUTTON
                                            className='mt-5'
                                            clr="#fff"
                                            bord="0"
                                            pad="12px 0"
                                            bg_color='#4984EA'
                                            brad="6px"
                                            wid="100%"
                                            f_family='montserrat_semibold'
                                            onClick={handle_set_password}
                                        >
                                            Reset My Password
                                        </BUTTON>
                                    </div>
                                </> :
                                    <>
                                        <div>
                                            <TEXT
                                                f_size="36px"
                                                clr="#1C1C1C"
                                                f_family='montserrat_semibold'
                                            >Forgot Your Password?</TEXT>
                                            <br />
                                            <TEXT
                                                f_size="20px"
                                                clr="#1C1C1C"
                                                f_family='montserrat_semibold'
                                            >
                                                Enter your email below to receive instructions to reset it.
                                            </TEXT>
                                        </div>
                                        <div className='mt-4'>
                                            <INPUT_LABEL

                                                f_family="montserrat_semibold"
                                            >
                                                Email Address*
                                            </INPUT_LABEL>
                                            <INPUT
                                                type='email'
                                                f_family="montserrat_medium"
                                                placeholder='Email Address'
                                                wid="505px"
                                                hei="46px"
                                                brad="6px"
                                                pad="12px 24px 12px 24px"
                                                bord="1px solid rgb(28 28 28 / 48%)"
                                                value={email}
                                                onChange={(e) => handle_email(e.target.value)}
                                            />
                                        </div>
                                        <div className='d-flex'>
                                            <BUTTON
                                                className='mt-5'
                                                clr="#fff"
                                                bord="0"
                                                pad="12px 0"
                                                bg_color='#4984EA'
                                                brad="6px"
                                                wid="100%"
                                                f_family='montserrat_semibold'
                                                onClick={handle_reset_password}
                                            >
                                                Reset My Password
                                            </BUTTON>
                                        </div>

                                    </>
                        }
                    </div>
                </form>
            </Modal>
            {/* PREMIUM MODAL */}
            <Modal
                onHide={() => set_purchase_modal(false)}
                show={purchase_modal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="purchase_modal"
            >
                <div className="modal_container position-relative text-center">
                    <GENERAL_BLOCK
                        poss="absolute"
                        topp="20px"
                        rightt="20px"
                    >
                        <BUTTON
                            bord="0"
                            bg_color="transparent"
                            onClick={() => set_purchase_modal(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <g clipPath="url(#clip0_117_2914)">
                                    <path d="M22.6 11.286L17.886 
                            16L22.6 20.714C23.1186 21.2326 23.1186 22.0811 22.6 22.5997C22.0815 
                            23.1182 21.2329 23.1182 20.7144 22.5997L16.0004 17.8856L11.2863 
                            22.5997C10.7678 23.1182 9.91923 23.1182 9.40069 22.5997C8.88214 22.0811 8.88214 
                            21.2326 9.40069 
                            20.714L14.1147 16L9.40069 11.286C8.88214 10.7674 8.88214 9.91888 9.40069
                            9.40034C9.91923 8.88179
                            10.7678 8.88179 11.2863 9.40034L16.0004 14.1144L20.7144 9.40034C21.2329
                            8.88179 22.0815 8.88179
                            22.6 9.40034C23.1186 9.91888 23.1186 10.7674 22.6 11.286Z" fill="#1C1C1C" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_117_2914">
                                        <rect width="32" height="32" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </BUTTON>
                    </GENERAL_BLOCK>
                    <TEXT
                        f_size='40px'
                        f_family="montserrat_semibold"
                        className='text-center mobile-premium-title'
                    >
                        Get access to all functionality of the Platform
                    </TEXT>
                    <TEXT f_size="24px"
                        f_family="montserrat_medium"
                        className='text-center mt-3 mobile-premium-text'>
                        No Monthly Fees - <BUTTON
                            bord="0"
                            bg_color="transparent"
                            clr={isMobilePremium ? "#4984EA" : "#405595"}
                            f_family="montserrat_semibold"
                            style={{ textDecoration: "underline", pointerEvents: "none" }}
                        >One Time Payment</BUTTON>
                    </TEXT>
                    <img src={isMobilePremium ? access_icon_mobile : access_icon} alt="" className='mt-4 mobile-premium-icon' />
                    <TEXT f_size="18px"
                        f_family="montserrat_medium"
                        className='text-center mt-4 mobile-premium-text'
                        style={{ marginBottom: 12 }}>
                        You have a question now <BUTTON
                            bord="0"
                            bg_color="transparent"
                            clr={isMobilePremium ? "#4984EA" : "#405595"}
                            d_play="contents"
                            f_family="montserrat_semibold"
                            style={{ pointerEvents: "none" }}
                        >“Does it really cost {get_price(false)}?”</BUTTON>
                    </TEXT>
                    <TEXT
                        f_size="18px"
                        f_family="montserrat_medium"
                        className='text-center mobile-premium-text'
                    >
                        Please answer the question<BUTTON
                            bord="0"
                            bg_color="transparent"
                            clr={isMobilePremium ? "#4984EA" : "#405595"}
                            d_play="contents"
                            f_family="montserrat_semibold"
                            style={{ pointerEvents: "none" }}
                        > “Does {get_price(false)} investment worth to land a job for $10,000 a month or even more?”</BUTTON>
                    </TEXT>
                    <TEXT f_size="32px" f_family="montserrat_semibold" className='text-center'>
                        {"Today Only "}
                        <BUTTON bord="0" bg_color="transparent" clr="#C51D1D" style={{ pointerEvents: "none" }}>
                            {get_price(false)}
                            <span style={{ color: "#000" }}> / </span>
                            <TEXT d_play="inline" clr="#000" t_decor="line-through" style={{ pointerEvents: "none" }}>
                                {get_price(true)}
                            </TEXT>
                        </BUTTON>
                    </TEXT>
                    <BUTTON
                        bord="0"
                        brad="5px"
                        bg_color={isMobilePremium ? "#4984EA" : "#405595"}
                        clr="#fff"
                        m_wid="131px"
                        hei="46px"
                        f_size="18px"
                        style={{ marginTop: 30 }}
                        onClick={() => handle_premium_purchase()}
                    >
                        {purchase_loading ? "" : "Buy"} {purchase_loading &&
                            <CircularProgress style={{ marginTop: "6px", width: "25px", height: "25px", color: "white" }} />}
                    </BUTTON>
                </div>
            </Modal>
            {/* LOADING MODAL */}
            <Modal
                onHide={() => set_page_loading(false)}
                show={page_loading_}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="loading_modal"
            >
                <div className="modal_container position-relative text-center">
                    <CircularProgress style={{ width: "48px", height: "48px", position: "absolute", color: "#b8dbff" }} />
                </div>
            </Modal>
        </div >
    );
}

export default App;

