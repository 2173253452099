import styled from "styled-components";

export const TEMPLATE = styled.div`
    margin: ${props => props?.marg};
    width:  ${props => props?.wid};
`

export const CUSTOM_TEXTAREA = styled.p`
    heigth: ${props => props?.hei};
    font-size: ${props => props?.f_size};
    font-size: ${props => props?.f_weight};
    outline: 0;
    padding-left: ${props => props?.pad};
    border: 0
    `

export const CUSTOM_LIST = styled.p`
    position: absolute;
    // heigth: ${props => props?.hei};
    list-style-type: ${props => props?.d_play};
    outline: 0;
    border: 0;
    left: ${props => props?.left};
    color: #808080b8;
    font-size: 15px;
    font-weight: bold;
    top: 0
`

export const ROCKY_TEXTAREA = styled.textarea`
    font-size: ${props => props?.f_size};
    line-height: ${props => props?.l_height};
    width: 200px;
    height: 20px;
    text-align: ${props => props?.align};
    min-width: 100%;
    max-width: 300px;
    transition: width 0.25s;
    resize: none;
    overflow: scroll;
    text-decoration: ${props => props?.u_line};
    border: 0;
    outline: 0;
    padding-left: ${props => props?.pad};
    ::-webkit-scrollbar {
        width: 1px;
        height: 1px
    };
    /* Track */
    ::-webkit-scrollbar-track {
    background:transparent;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: transparent;
    }
   
`