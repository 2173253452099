import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { BlueButton, ButtonPosition, TitleText } from "./ActionButtons.Styled";
import './settings.css';
import { useRecoilState, useRecoilValue } from "recoil";
import { Sheet, Content, detents, Portal } from 'react-sheet-slide'
import 'react-sheet-slide/style.css'
import {
    download_data, is_preview, purchase_modal, resume,
    selected_section as selected_section_atom, tool_view, user_info
} from '../../recoil/store';
import preview from "../../images/icons/preview.svg";
import settings from "../../images/icons/settings.svg";
import downloadIcon from "../../images/icons/download.svg";
import SectionsAI from "../Sections/SectionsAI";
import { enqueueSnackbar } from "notistack";
import { downloadCv } from "../../services/download";
import { pageSize } from "../../constants";
import ResumeSettings from "../ResumeSettings/ResumeSettings";
import { useSaveFunction } from "../../utils/save";

const PreviewButton = ({ id, onAppear = async () => { } }) => {

    const navigate = useNavigate();
    const loc = useLocation();
    let user_info_ = useRecoilValue(user_info);
    const [selected_section, set_selected_section] = useRecoilState(selected_section_atom);
    const [is_preview_, set_is_preview] = useRecoilState(is_preview);
    const [resume_, set_resume] = useRecoilState(resume);
    const [purchase_modal_, set_purchase_modal] = useRecoilState(purchase_modal);
    const [download_data_, set_download_data] = useRecoilState(download_data);
    const [tool_view_, set_tool_view] = useRecoilState(tool_view);
    const [openSettings, setOpenSettings] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, set_loading] = useState(false);

    const handlePreviewClick = async () => {
        await onAppear();
        set_is_preview(true);
        navigate(`/cv-preview/${id}`);
    };

    const saveFunction = useSaveFunction();

    const download = async (pageSize) => {
        set_loading(true);
        try {
            await saveFunction(id, true);
            if (user_info_?.isPremium) {
                set_tool_view(false);
                const persistToken = JSON.parse(localStorage.getItem("token") || "null");
                const resp = await downloadCv(download_data_?.id,
                    persistToken, pageSize);
                const url = URL.createObjectURL(resp);
                const a = document.createElement('a');
                a.download = `${download_data_?.name}.pdf`;
                a.href = url;
                a.target = '_self';
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
                set_loading(false);
            } else {
                set_loading(false);
                set_purchase_modal(true);
            }
        } catch (error) {
            set_loading(false);

            if (error?.response?.status === 403) {
                set_purchase_modal(true);
            } else {
                enqueueSnackbar("Something went wrong!", { variant: "error" });
            }
        }
        finally { setShowModal(false); }
    };

    useEffect(() => {
        if (loc.pathname.includes('/cv-preview')) {
            setOpenSettings(false);
        }
    }, [resume_])

    return (
        <div style={{ touchAction: 'manipulation' }}>
            <ButtonPosition>
                {loc.pathname.includes('/cv-preview') ?
                    <BlueButton onClick={() => setShowModal(true)}>
                        Download
                        <img src={downloadIcon} alt="download" />
                    </BlueButton> :
                    <BlueButton onClick={handlePreviewClick}>
                        Preview
                        <img src={preview} alt="preview" />
                    </BlueButton>}
                {(selected_section?.side_bar === "certifications" ||
                    selected_section?.side_bar === "professionalAffiliations" ||
                    selected_section?.side_bar === "achievements" ||
                    ["workExperience", "internship"].includes(selected_section.side_bar) ||
                    selected_section?.side_bar === "keySkillsCompetencies" ||
                    selected_section?.side_bar === "trainingCourses" || loc.pathname.includes('/cv-preview')) &&
                    <BlueButton onClick={() => setOpenSettings(true)} style={{ marginLeft: 12 }}>
                        Settings
                        <img src={settings} alt="settings" />
                    </BlueButton>}
            </ButtonPosition>
            {openSettings &&
                <Portal>
                    <Sheet
                        open={openSettings}
                        onDismiss={() => setOpenSettings(false)}
                        onClose={() => {
                            // console.log('Component unmounted')
                        }}
                        selectedDetent={detents.large}
                        detents={props => [
                            detents.large(props)
                        ]}
                        useDarkMode={false}
                        useModal={false}
                    >
                        <Content className="rss-content">
                            {loc.pathname.includes('/cv-preview') ?
                                <ResumeSettings id={id} /> :
                                <SectionsAI />}
                        </Content>
                    </Sheet>
                </Portal>
            }
            <Modal
                onHide={() => setShowModal(false)}
                show={showModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="modal_container">
                    <div className="d-flex flex-column">
                        {!loading ? (
                            <>
                                <TitleText>Select a page size:</TitleText>
                                {pageSize.map((item, index) => (
                                    <BlueButton
                                        key={index}
                                        className="text-center"
                                        style={index === 0 ? { marginBottom: 12 } : {}}
                                        onClick={() => download(item.value)}
                                    >
                                        {item.text}
                                    </BlueButton>
                                ))}
                            </>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <CircularProgress
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        color: resume_.settings?.color,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

            </Modal>
        </div>
    )
}
export default PreviewButton