import React, { useEffect, useState } from 'react'
import Sidebar from './Component/Sidebar/Sidebar'
import Template from './Component/Template/Template'
import Toolbar from './Component/Toolbar/Toolbar'
import { useRecoilState, useRecoilValue } from 'recoil'
import { download_data, new_resume, page_loading, resume } from './recoil/store'
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router'
import { getCvById } from './services/getCvById'
import { calculateTextColorFromRGB } from './utils/color-formating';
import useWindowDimensions from './utils/WindowDimension'

const Constructor = () => {
    const { width } = useWindowDimensions();
    let param = useParams();
    const [page_loading_, set_page_loading] = useRecoilState(page_loading);
    const new_resume_ = useRecoilValue(new_resume);
    const [resume_, set_resume] = useRecoilState(resume);
    const [resume_loaded, set_resume_loaded] = useState(false);
    const [download_data_, set_download_data] = useRecoilState(download_data);
    let right_sidebar_style = { right: 0 };
    let right_drawer_style = { brad: "6px 0px 0px 6px", left: "-60px" };
    const templateWidth = width > 1025 ? "816px" : "680px";
    const templateMar = width > 1025 ? "0 auto 50px auto" : "0 auto 0 auto";
    useEffect(() => {
        (async function () {
            set_page_loading(true)
            try {
                let response = await getCvById(param?.id);
                set_download_data({
                    id: param?.id,
                    name: response?.name
                })
                const textColor = calculateTextColorFromRGB(response?.settings?.color);

                if (Array.isArray(response?.customSection)) {
                    for (const key of Object.keys(response)) {
                        if (key === 'customSection') {
                            for (const k of response[key]) {
                                for (const k2 of Object.keys(k)) {
                                    response = {
                                        ...response,
                                        [k2]: k[k2]
                                    }
                                }
                            }
                        }
                    }
                }
                let deep_copy = JSON.parse(JSON.stringify(response));
                deep_copy.settings.textColor = textColor;

                set_resume(deep_copy);
                set_page_loading(false);
                set_resume_loaded(true);
            } catch (error) {
                console.log(error);
                set_page_loading(false)
                enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" })
            }
        })()
    }, [])

    const [section, set_section] = useState('');
    const [edit_panel_view, set_edit_panel_view] = useState(false);
    const [toolbar_view, set_toolbar_view] = useState(false);

    return (
        <div id='constructor-container' style={{ paddingTop: "142px" }}>
            <Toolbar
                resume_loaded={resume_loaded}
            />
            {!page_loading_ &&
                <Template
                    templateWidth={templateWidth}
                    marg="0 auto 50px auto"
                    set_toolbar_view={set_toolbar_view}
                />
            }
            <Sidebar
                edit_panel_view={edit_panel_view}
                style={{ right_sidebar_style, right_drawer_style }}
                target="template_design_panel"
                section={section}
                set_section={set_section}
            />
            <Sidebar
                edit_panel_view={edit_panel_view}
                style={{ right_sidebar_style, right_drawer_style }}
                target="edit_panel"
                section={section}
                set_section={set_section}
            />
        </div>
    )
}

export default Constructor