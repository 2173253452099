import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class Editor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: "",
            index: ""
        }

        this.modules = {
            toolbar: {
                handlers: {
                    'undo': this.myUndo,
                    'redo': this.myRedo,
                },
                container: [
                    ['undo'],
                    ['redo'],
                    ['bold'],
                    ['italic'],
                    ['underline'],
                    [{ 'list': 'ordered' }],
                    [{ 'list': 'bullet' }],
                    [{ align: '' }],
                    [{ align: 'center' }],
                    [{ align: 'right' }],
                    [{ align: 'justify' }],
                    ['link'],
                ]
            }
        }
        this.formats = [
            'header',
            'align',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'indent', 'indent',
            'script', 'strike',
            'color', 'background',
            'link', 'image',
            'undo', 'redo'
        ];

        var icons = ReactQuill.Quill.import("ui/icons");

        icons["undo"] = `<svg viewbox="0 0 18 18">
            <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
            <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
        </svg>`;
        icons["redo"] = `<svg viewbox="0 0 18 18">
            <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
            <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
        </svg>`;
    }
    handleChange = (content) => {
        if (this.props.keys?.includes("education")
            || this.props.keys?.includes("workExperience") || this.props.keys?.includes("internship")) {
            this.props.set_resume(resume => {
                let copy = { ...resume };
                let items = [...copy[this.props.keys]?.items];
                let one_item = items[this.props.item_index];
                one_item = {
                    ...one_item,
                    content
                }
                items[this.props.item_index] = one_item;
                copy[this.props.keys] = {
                    ...copy[this.props.keys],
                    items
                }
                return copy;
            })
        }
        else if (this.props.isMobile && this.props.keys?.includes("summary")) {
            this.props.set_resume(content);
        }
        else {
            this.props.set_resume(resume => {
                let copy = { ...resume };
                copy[this.props.keys] = {
                    ...copy[this.props.keys],
                    content
                }
                return copy;
            })
        }
        this.setState({ data: content })
    }

    myUndo = () => {
        let myEditor = this.reactQuillRef.getEditor();
        return myEditor.history.undo();
    }

    myRedo = () => {
        let myEditor = this.reactQuillRef.getEditor();
        return myEditor.history.redo();
    }

    render() {
        return (
            <div>
                <style>{`body { font-size:  ${this.props.f_size}`}</style>
                <ReactQuill
                    theme="snow"
                    ref={(el) => { this.reactQuillRef = el }}
                    formats={this.formats}
                    style={{
                        fontSize: "20px"
                    }}
                    modules={this.modules}
                    id={this.props.isMobile ? 'mobile' : this.props.index}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onBlur={() => this.props.set_index("")}
                    onFocus={() => {
                        this.props.set_tool_view(true);
                        setTimeout(() => {
                            this.props.set_index(this.props.index);
                        }, 1);
                    }}
                    onChange={this.handleChange}
                />
            </div>
        );
    }

}
