import React from 'react';
// styling
import styles from "./toolbar.module.css";
import { BUTTON } from '../../styled';
// images & icons
import arrow_long_left from "../../images/icons/arrow-long-left.svg";
import save_icon from "../../images/icons/save.svg";
import zoom_in_icon from "../../images/icons/zoom-in.svg";
import zoom_out_icon from "../../images/icons/zoom-out.svg";
import font_size_icon from "../../images/icons/font-size.svg";
import letter_spacing_icon from "../../images/icons/letter-spacing.svg";
import arrow_double from "../../images/icons/arrow-double-select.svg";
import arrowDown from "../../images/icons/arrow-down-select.svg";
import palette_icon from "../../images/icons/palette.svg";
// components
import SelectBox from "../Selectbox/SelectBoxSingle";
import SelectBoxWithTitle from "../Selectbox/SelectBoxWithTitle";
// constants
import { date_format, font_sizes, fonts, pageSize, spacing } from '../../constants';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    template_ref, tool_view,
    resume, download_data,
    is_preview, purchase_modal, user_info, cvHeight,
    request_save as request_save_,
} from '../../recoil/store';
import { editCV } from '../../services/crud';
import { useNavigate, useParams } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { downloadCv } from '../../services/download';
import { CircularProgress } from '@mui/material';
import { calculateTextColorFromRGB } from '../../utils/color-formating';
import { useAutosave } from 'react-autosave';

const AColorPicker = require('a-color-picker');

const Toolbar = ({ resume_loaded }) => {
    const nav = useNavigate();
    const { id } = useParams();
    let user_info_ = useRecoilValue(user_info);
    const [purchase_modal_, set_purchase_modal] = useRecoilState(purchase_modal);
    const [request_save, set_request_save] = useRecoilState(request_save_);
    const [is_preview_, set_is_preview] = useRecoilState(is_preview);
    const [resume_, set_resume] = useRecoilState(resume);
    const [show_picker, set_show_picker] = useState(false);
    const [loading, set_loading] = useState(false);
    const [loading_save, set_loading_save] = useState(false);
    const [template_ref_, set_template_ref] = useRecoilState(template_ref);
    const [tool_view_, set_tool_view] = useRecoilState(tool_view);
    let color_ref = useRef(null)
    let value = useRef(null);
    const [download_data_, set_download_data] = useRecoilState(download_data);
    let zoomArr = [0.85, 0.9, 1, 1.2, 1.5];
    const [height, set_height] = useRecoilState(cvHeight);
    const [zoom_index, set_zoom_index] = useState(2);
    const resumeRef = useRef(resume_);

    useEffect(() => {
        resumeRef.current = resume_;
    }, [resume_]);

    useEffect(() => {
        if (resume_loaded === true) {
            value.current = zoomArr[zoom_index];
            const constructorContainer = document.getElementById("constructor-container");
            if (constructorContainer && template_ref_) {
                template_ref_.style['transform'] = `scale(${value.current})`;

                let scaledHeight = height * value.current;
                let heightDifference = scaledHeight - height;

                let maginMultiplier = (height < 3000) ? 0.55 : 0.525;
                maginMultiplier = (height < 1500) ? 0.6 : maginMultiplier;
                maginMultiplier = (value.current < 1) ? 0.4 : maginMultiplier;

                template_ref_.style["margin-top"] = (value.current != 1
                    ? (heightDifference * maginMultiplier) : 24) + "px";
                template_ref_.style["margin-bottom"] = (value.current != 1
                    ? (heightDifference * maginMultiplier) : 24) + "px";
            }
        }
    }, [zoom_index, resume_loaded, height]);

    useEffect(() => {
        if (resume_loaded === true) {
            if (show_picker) {
                color_ref.current.show();
            } else {
                color_ref.current.hide();
            }
        }
    }, [show_picker]);

    useEffect(() => {
        set_settings("height", height);
    }, [height]);

    useEffect(() => {
        if (request_save) {
            set_request_save(false);
            save(true);
        }
    }, [request_save]);

    useEffect(() => {
        initializeColorPicker();
        let saveTimeout;
        const handleKeyDown = function (event) {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                if (!saveTimeout) {
                    saveTimeout = setTimeout(() => {
                        clearTimeout(saveTimeout);
                        saveTimeout = null;
                    }, 5000);
                    save();
                }
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const initializeColorPicker = () => {
        color_ref.current = AColorPicker.createPicker(document.getElementsByClassName("color-picker")[0], {
            showHSL: false,
            showAlpha: false,
            showRGB: false,
            paletteEditable: false,
            useAlphaInPalette: false,
            acpShowHex: true
        });
        color_ref.current.color = resumeRef?.current?.settings?.color;
        color_ref.current.on('change', (picker, color) => {
            let copy = { ...resumeRef?.current };
            const textColor = calculateTextColorFromRGB(color);
            copy.settings = {
                ...copy.settings,
                color: picker.rgbhex,
                textColor
            };
            set_resume(copy)
        });
        color_ref.current.hide();
        document.addEventListener("mousedown", handleClickOutside);
    }

    const zoom = (inZoom = false) => {
        if (inZoom && zoom_index < zoomArr.length - 1) {
            set_zoom_index(zoom_index + 1);
        } else if (!inZoom && zoom_index > 0) {
            set_zoom_index(zoom_index - 1);
        }
    }

    const download = async (pageSize) => {
        set_loading(true);
        try {
            await save(true);

            if (user_info_?.isPremium) {
                set_tool_view(false);
                const persistToken = JSON.parse(localStorage.getItem("token") || "null");
                const resp = await downloadCv(download_data_?.id,
                    persistToken, pageSize);
                const url = URL.createObjectURL(resp);

                const a = document.createElement('a');
                a.download = `${download_data_?.name}.pdf`;
                a.href = url;
                a.target = '_self';
                a.click();
                a.remove();
                URL.revokeObjectURL(url);

                set_loading(false);
            } else {
                set_loading(false);
                set_purchase_modal(true);
            }
        } catch (error) {
            set_loading(false);

            if (error?.response?.status === 403) {
                set_purchase_modal(true);
            } else {
                enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" });
            }
        }
    };

    const set_settings = (prop, value) => {
        let copy = { ...resumeRef?.current };
        copy.settings = {
            ...copy.settings,
            [prop]: +value
        };
        set_resume(copy)
    };

    const handleClickOutside = (event) => {
        let element = document.getElementById("color-picker")
        if (!element?.contains(event.target)) {
            set_show_picker(false);
        }
    };

    const save = async (inBackground = false) => {
        if (loading_save) {
            return;
        }
        try {
            const resume = { ...resumeRef?.current };

            const deepCopy = JSON.parse(JSON.stringify(resume));
            if (deepCopy.professionId === null) {
                delete deepCopy.professionId;
            }
            delete deepCopy?.settings?.textColor;
            for (const k of Object.keys(deepCopy)) {
                if (k === "keySkillsCompetencies" || k === "trainingCourses") {
                    deepCopy[k].content?.forEach(c => delete c?.last);
                }
            }
            const customSection = Object.entries(resume)
                .filter(([key]) => key.includes("custom") && key !== "customSection")
                .map(([key, value]) => ({ [key]: value }));
            const filteredObject = Object.fromEntries(Object.entries(deepCopy).filter(([key]) => !key?.includes("custom")));

            set_loading_save(prevState => (!inBackground ? true : prevState));

            const response = await editCV(id, { ...filteredObject, customSection });
            set_loading_save(prevState => (!inBackground ? false : prevState));
            if (response && !inBackground) {
                enqueueSnackbar("Resume has been successfully updated!", { variant: "success" });
            }
        } catch (error) {
            set_loading_save(prevState => (!inBackground ? false : prevState));
            enqueueSnackbar("Something is wrong, please try again later.", { variant: "error" });
        }
    };

    const autoSave = () => {
        if (resume_loaded) {
            save();
        }
    }

    useAutosave({
        data: resumeRef?.current,
        onSave: autoSave,
        interval: 60000
    });

    return (
        <div className={styles.toolbar_holder}>
            <div className={styles.toolbar_container}>
                <div className="d-flex aic">
                    <div className={styles.divider}>
                        <BUTTON
                            wid="100%"
                            hei="100%"
                            bord="0"
                            clr="#1C1C1C"
                            f_size="18px"
                            f_family="montserrat_semibold"
                            bg_color="transparent"
                            brad="4px"
                            pad="0 20px 0 0"
                            className='d-flex jcc aic'
                            onClick={() => nav("/")}
                        >
                            <img src={arrow_long_left} alt="" className='me-2' />
                            My Resumes
                        </BUTTON>
                    </div>
                    <div className={styles.divider}>
                        <BUTTON
                            wid="120px"
                            hei="27px"
                            bord="0"
                            clr="#1C1C1C"
                            f_size="18px"
                            f_family="montserrat_semibold"
                            bg_color="transparent"
                            brad="4px"
                            pad="0 20px 0 20px"
                            className='d-flex jcc aic'
                            onClick={() => save()}
                        >
                            {
                                !loading_save ?
                                    <>
                                        <img src={save_icon} alt="" className='me-2' />
                                        Save
                                    </> : <CircularProgress style={{ width: "25px", height: "25px", position: "absolute", color: resumeRef?.current?.settings?.color }} />
                            }
                        </BUTTON>
                    </div>
                    <div>
                        <BUTTON
                            wid="100%"
                            hei="100%"
                            bord="0"
                            clr="#1C1C1C"
                            f_size="18px"
                            f_family="montserrat_semibold"
                            bg_color="transparent"
                            brad="4px"
                            pad="0 0px 0 20px"
                            className='d-flex jcc aic'
                            onClick={() => zoom(true)}
                        >
                            <img src={zoom_out_icon} alt="" className='me-2' />
                        </BUTTON>
                    </div>
                    <div className={styles.divider}>
                        <BUTTON
                            wid="100%"
                            hei="100%"
                            bord="0"
                            clr="#1C1C1C"
                            f_size="18px"
                            f_family="montserrat_semibold"
                            bg_color="transparent"
                            brad="4px"
                            pad="0 20px 0 20px"
                            className='d-flex jcc aic'
                            onClick={() => zoom()}
                        >
                            <img src={zoom_in_icon} alt="" className='me-2' />
                        </BUTTON>
                    </div>
                    <SelectBox
                        padding="0 5px 0 15px"
                        placeholder={<div>{fonts[0].value}</div>}
                        arrow_icon={arrowDown}
                        selected_value={resumeRef?.current?.settings?.font}
                        multiple={false}
                        data={fonts}
                        setResult={(val) => {
                            set_settings("font", val);
                        }}
                    />
                    <div className={styles.divider}>
                        <SelectBox
                            padding="0 15px 0 5px"
                            represent_icon={font_size_icon}
                            arrow_icon={arrowDown}
                            placeholder={<div>{font_sizes[0].value}</div>}
                            selected_value={resumeRef?.current?.settings?.fontSize}
                            data={font_sizes}
                            setResult={(val) => set_settings("fontSize", val)}
                        />
                    </div>
                    <div className={styles.divider}>
                        <SelectBox
                            represent_icon={letter_spacing_icon}
                            double_arrow
                            arrow_icon={arrow_double}
                            placeholder={spacing[0].value}
                            selected_value={resumeRef?.current?.settings?.lineSpacing}
                            data={spacing}
                            setResult={(val) => set_settings("lineSpacing", val)}
                        />
                    </div>
                    <div className={styles.divider}>
                        <SelectBox
                            arrow_icon={arrowDown}
                            placeholder={date_format[0].value}
                            selected_value={resumeRef?.current?.settings?.dateFormat}
                            data={date_format}
                            setResult={(val) => set_settings("dateFormat", val)}
                        />
                    </div>
                    <div id='color-picker' className={`${styles.divider} position-relative`}>
                        <BUTTON
                            wid="100%"
                            hei="100%"
                            bord="0"
                            clr="#1C1C1C"
                            f_family="montserrat_semibold"
                            bg_color="transparent"
                            pad="0 20px 0 20px"
                            className='d-flex jcc aic'
                            onClick={() => set_show_picker(!show_picker)}>
                            <img src={palette_icon} alt="" />
                            <img src={arrowDown} alt="" />
                        </BUTTON>
                        <div className="color-picker" ref={color_ref}></div>
                    </div>
                    <div className={`${styles.divider} position-relative`}>
                        <BUTTON
                            wid={loading ? "135px" : "100%"}
                            hei="100%"
                            bord="0"
                            clr="#1C1C1C"
                            f_size="18px"
                            f_family="montserrat_semibold"
                            bg_color="transparent"
                            brad="4px"
                            pad="0 20px 0 20px"
                            className='d-flex jcc aic text-center'
                            onClick={() => set_is_preview(!is_preview_)}
                        >
                            {is_preview_ ? "Edit" : "Preview"}
                        </BUTTON>
                    </div>
                    <div className={`${styles.divider} position-relative`}>
                        <SelectBoxWithTitle
                            data={pageSize}
                            setResult={(type) => download(type)}
                            loading={loading}
                            loadingColor={resumeRef?.current?.settings?.color}
                            title="Download"
                            f_size="18px"
                            f_family="montserrat_semibold"
                        />
                    </div>
                </div>
            </div >
        </div >
    )
}
export default Toolbar;
