import React, { useState, useEffect, useRef } from 'react';
import "./selectbox.css";
import check_icon from "../../images/icons/check.svg";
import SearchInput from '../SearchInput/SearchInput';

const SelectBox = ({ data, style, setResult, id, selected_value, search_value, onSearch, staticSearch, isLoading, onInputChange }) => {

    const [selectboxView, setSelectboxView] = useState(false);
    const [selected, setSelected] = useState(selected_value);
    const [data_, set_data] = useState(data);
    const [searchInputValue, setSearchInputValue] = useState('');
    const inputReference = useRef(null);
    const mainDivReference = useRef(null);

    useEffect(() => {
        setSelected(selected_value);
        set_data(data);
    }, [data, selected_value]);

    useEffect(() => {
        updateSelection();
    }, [selected]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mainDivReference]);

    const updateSelection = (event) => {
        const selectedText = data_.find(item => item.prof_id === selected)?.text?.replace(/^Default: /, '') ?? searchInputValue
        setSearchInputValue(selectedText);
    };

    const handleClickOutside = (event) => {
        if (mainDivReference && !mainDivReference?.current?.contains(event.target)) {
            setSelectboxView(false);
            let searchValue = inputReference?.current?.value;
            if (!data_.find(item => item.text === searchValue)) {
                setSelected(null);
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !staticSearch && e.target.value.length > 3) {
            inputReference?.current?.blur();
            setSelectboxView(false);
            onSearch(e.target.value);
        }
    };

    const searchOnChange = (value) => {
        const filteredData = data?.filter(d => d?.text?.toLowerCase()?.includes(value.toLowerCase()));
        set_data(filteredData);
        if (onInputChange != undefined) {
            onInputChange(value);
        }
    };

    return (
        <div style={style} ref={mainDivReference}>
            <SearchInput
                onFocus={() => setSelectboxView(true)}
                onChange={(value) => {
                    setSearchInputValue(value);
                    searchOnChange(value);
                }}
                onKeyDown={handleKeyDown}
                onSearch={onSearch}
                staticSearch={staticSearch}
                value={searchInputValue}
                inputReference={inputReference}
                isLoading={isLoading}
            />
            {selectboxView && (
                <ul
                    className='select_box'
                    style={{ height: data_?.length > 14 ? "290px" : "auto", overflowY: "scroll" }}
                    id={id}
                >
                    {data_?.length > 0 ? (
                        data_?.map(({ key, text, value, prof_id }) => (
                            <li key={key}>
                                <button
                                    className={`select_box_item ${selected === value ? 'selected' : ""}`}
                                    id={value}
                                    name={text}
                                    onClick={() => {
                                        setSelected(prof_id);
                                        setResult(value === "-" ? "" : value, prof_id);
                                        setSelectboxView(false);
                                        updateSelection();
                                    }}
                                >
                                    {text}
                                    {selected === value && <img src={check_icon} alt="" />}
                                </button>
                            </li>
                        ))
                    ) : (
                        <li>
                            <button
                                disabled
                                className={`select_box_item`}
                            >
                                No value
                            </button>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default SelectBox;
