export function calculateTextColorFromRGB(color) {
    let r, g, b;

    if (typeof color === 'string') {
        // Handle RGB string format
        if (color.startsWith('rgb')) {
            const rgbValues = color.match(/\d+/g);
            [r, g, b] = rgbValues.map(Number);
        }
        // Handle Hex string format
        else if (color.startsWith('#')) {
            const hex = color.replace('#', '');
            if (hex.length === 3) {
                r = parseInt(hex[0] + hex[0], 16);
                g = parseInt(hex[1] + hex[1], 16);
                b = parseInt(hex[2] + hex[2], 16);
            } else if (hex.length === 6) {
                r = parseInt(hex.substring(0, 2), 16);
                g = parseInt(hex.substring(2, 4), 16);
                b = parseInt(hex.substring(4, 6), 16);
            }
        }
    } else if (typeof color === 'object') {
        // Handle RGB object format
        ({ r, g, b } = color);
    }

    if (r !== undefined && g !== undefined && b !== undefined) {
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 128 ? 'black' : 'white';
    } else {
        throw new Error('Invalid color format');
    }
}
