import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const token_engineer = atom({
    key: 'token_engineer',
    default: {
        email: "",
        password: "",
        name: "",
        surname: ""
    },
});

export const user_info = atom({
    key: 'user_info',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

export const template_ref = atom({
    key: 'template_ref',
    default: null,
});

export const holder_ref = atom({
    key: 'holder_ref',
    default: null,
});

export const toolbar_ref = atom({
    key: 'toolbar_ref',
    default: null,
});
export const selected_input = atom({
    key: 'selected_input',
    default: null,
});
export const input_holder_ref = atom({
    key: 'input_holder_ref',
    default: null,
});

export const language = atom({
    key: 'language',
    default: "",
    effects_UNSTABLE: [persistAtom]
});

export const resume = atom({
    key: 'resume',
    default: {
        name: "",
        professionId: "",
        settings: {
            font: 1,
            fontSize: 1,
            lineSpacing: 1,
            dateFormat: 1,
            color: "#004EC2",
            height: 1190
        },
        personalInformation: {
            fullName: "Full Name",
            jobTitle: "Job Title",
            credentials: "Credentials",
            email: "email@email.com",
            phone: "050678968",
            location: "location",
            linkedin: "Linkedin",
            qr: {
                frameLabel: "Label",
                url: "",
                active: true
            }
        },
        summary: {
            content: "<p><br></p>",
            active: true,
        },
        keySkillsCompetencies: {
            bulletType: 4,
            content: [{
                value: "",
            }],
            active: true,
            order: 1,
        },
        workExperience: {
            items: [
                {
                    designation: "",
                    company: "",
                    content: "<p><br></p>",
                    startDate: "",
                    endDate: "",
                    location: ""
                }
            ],
            active: true,
            order: 2
        },
        education: {
            items: [{
                designation: "",
                company: "",
                content: "<p><br></p>",
                startDate: "",
                endDate: "",
                location: ""
            }],
            active: true,
            order: 3
        },
        internship: {
            items: [{
                designation: "",
                company: "",
                content: "<p><br></p>",
                startDate: "",
                endDate: "",
                location: ""
            }],
            active: true,
            order: 4
        },
        achievements: {
            content: "<p><br></p>",
            active: true,
            order: 5,
        },
        conferences: {
            content: "<p><br></p>",
            active: true,
            order: 6,
        },
        certifications: {
            content: "<p><br></p>",
            active: true,
            order: 7,
        },
        professionalAffiliations: {
            content: "<p><br></p>",
            active: true,
            order: 8,
        },
        patentPublications: {
            content: "<p><br></p>",
            active: true,
            order: 9,
        },
        trainingCourses: {
            bulletType: 4,
            content: [{
                value: "",
            }],
            active: true,
            order: 10,
        },
        languages: {
            content: "<p><br></p>",
            active: true,
            order: 11
        },
        references: {
            content: "<p><br></p>",
            active: true,
            order: 12,
        },
        projects: {
            content: "<p><br></p>",
            active: true,
            order: 13
        },
    },
});

export const report = atom({
    key: 'report',
    default: "",
    effects_UNSTABLE: [persistAtom]
})
export const tool_view = atom({
    key: 'tool_view',
    default: false,
})
export const editor_index = atom({
    key: 'editor_index',
    default: "",
})
export const cvHeight = atom({
    key: 'cvHeight',
    default: 1190
})

export const selected_section = atom({
    key: 'selected_section',
    default: {
        section_controller: "",
        side_bar: "",
        type_4_item: "",
        custom_type: "",
        index: 0
    },
})
export const editor_methods = atom({
    key: 'editor_methods',
    default: {
        editor: ""
    },
    effects_UNSTABLE: [persistAtom]
})
export const selected_template = atom({
    key: 'selected_template',
    default: "",
    effects_UNSTABLE: [persistAtom]
})
export const new_resume = atom({
    key: 'new_resume',
    default: {
        id: "",
        name: ""
    },
    effects_UNSTABLE: [persistAtom]
})

export const login_modal = atom({
    key: 'login_modal',
    default: false,
});

export const signup_view = atom({
    key: 'signup_view',
    default: false,
})

export const reset_modal = atom({
    key: 'reset_modal',
    default: {
        reset_view: false,
        mail_sent_view: false,
        new_pass_view: false
    },
})

export const purchase_modal = atom({
    key: 'purchase_modal',
    default: false,
})

export const all_end_values = atom({
    key: 'all_end_values',
    default: {},
    effects_UNSTABLE: [persistAtom]
})

export const profession_templates = atom({
    key: 'profession_templates',
    default: [{
        id: "",
        name: ""
    }],
    effects_UNSTABLE: [persistAtom]
})

export const download_data = atom({
    key: 'download_data',
    default: {
        id: "",
        name: ""
    }
})

export const is_preview = atom({
    key: 'is_preview',
    default: false
})

export const page_loading = atom({
    key: 'page_loading',
    default: false
})

export const purchase_status = atom({
    key: 'purchase_status',
    default: null
});

export const prices = atom({
    key: 'prices',
    default: [],
    effects_UNSTABLE: [persistAtom]
});

export const request_save = atom({
    key: 'save',
    default: false
})

export const loading_save = atom({
    key: 'loading_save',
    default: false
})