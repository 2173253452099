import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BUTTON, GENERAL_BLOCK, STYLED_LINK } from '../../../styled';
import { useRecoilState } from 'recoil';
import { language, login_modal as login_modal_atom, signup_view as signup_view_atom } from '../../../recoil/store';
import mark_icon from "../../../images/icons/mark.svg";

const NavbarMobile = ({ logo, links }) => {
    const [open_close, set_open_close] = useState(false);
    const [open_close_dropdown, set_open_close_dropdown] = useState(false);
    const [selected_lang, set_selected_lang] = useRecoilState(language);
    const [login_modal, set_login_modal] = useRecoilState(login_modal_atom);
    const [signup_view, set_signup_view] = useRecoilState(signup_view_atom);
    let persist_token = JSON.parse(localStorage.getItem("token"));
    const nav = useNavigate();

    return (
        <div>
            <div className='d-flex jcsb'>
                <Link to="https://engineernow.org">{logo}</Link>
                <BUTTON
                    bg_color="transparent"
                    bord="0"
                    onClick={() => set_open_close(!open_close)}
                >
                    {
                        !open_close ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="18" viewBox="0 0 37 18" fill="none">
                                <line x1="6" y1="1" x2="36" y2="1" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <line x1="6" y1="9" x2="36" y2="9" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <line x1="6" y1="17" x2="36" y2="17" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
                                <g clipPath="url(#clip0_971_3425)">
                                    <path d="M26.1312 13.0494L20.6806 18.5L26.1312 23.9506C26.7308 24.5502 26.7308 25.5313 26.1312 26.1309C25.5317 26.7304 24.5506 26.7304 23.951 26.1309L18.5004 20.6802L13.0498 26.1309C12.4502 26.7304 11.4691 26.7304 10.8695 26.1309C10.2699 25.5313 10.2699 24.5502 10.8695 23.9506L16.3201 18.5L10.8695 13.0494C10.2699 12.4498 10.2699 11.4687 10.8695 10.8691C11.4691 10.2696 12.4502 10.2696 13.0498 10.8691L18.5004 16.3198L23.951 10.8691C24.5506 10.2696 25.5317 10.2696 26.1312 10.8691C26.7308 11.4687 26.7308 12.4498 26.1312 13.0494Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_971_3425">
                                        <rect width="37" height="37" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                    }
                </BUTTON>
            </div>
            {
                open_close &&
                <>
                    <GENERAL_BLOCK
                        bordBot="1px solid white"
                        className='d-flex flex-column mt-5 pb-3'>
                        <STYLED_LINK
                            clr="#fff"
                            hov="#EB4B07"
                            f_family="montserrat_medium"
                            className='mb-3'
                            to={"/pick-a-template"}
                        >Resume Builder</STYLED_LINK>
                        <STYLED_LINK
                            clr="#fff"
                            hov="#EB4B07"
                            f_family="montserrat_medium"
                            className='mb-3'
                            to={"/"}
                        >My Dashboard</STYLED_LINK>
                        {
                            !persist_token?.access ? <>

                                <STYLED_LINK
                                    clr="#fff"
                                    hov="#EB4B07"
                                    f_family="montserrat_medium"
                                    className='mb-3'
                                    onClick={() => set_login_modal(true)}
                                >Login</STYLED_LINK>
                                <STYLED_LINK
                                    clr="#fff"
                                    hov="#EB4B07"
                                    f_family="montserrat_medium"
                                    className='mb-3'
                                    onClick={() => {
                                        set_login_modal(true);
                                        set_signup_view(true)
                                    }}
                                >Sign Up</STYLED_LINK>
                            </> :
                                <STYLED_LINK
                                    clr="#fff"
                                    hov="#EB4B07"
                                    f_family="montserrat_medium"
                                    className='mb-3'
                                    to={"/profile"}
                                >Profile</STYLED_LINK>
                        }
                    </GENERAL_BLOCK>
                    {/* <GENERAL_BLOCK
                        className='mt-5'>
                        <BUTTON
                            bg_color="transparent"
                            bord="0"
                            clr="#fff"
                            hov="#EB4B07"
                            f_family="montserrat_medium"
                            className='mb-3'
                            onClick={() => set_open_close_dropdown(!open_close_dropdown)}
                        >English  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8.11997 9.28957L12 13.1696L15.88 9.28957C16.27 8.89957 16.9 8.89957 17.29 9.28957C17.68 9.67957 17.68 10.3096 17.29 10.6996L12.7 15.2896C12.31 15.6796 11.68 15.6796 11.29 15.2896L6.69997 10.6996C6.30997 10.3096 6.30997 9.67957 6.69997 9.28957C7.08997 8.90957 7.72997 8.89957 8.11997 9.28957Z" fill="white" />
                            </svg></BUTTON>
                        {
                            open_close_dropdown &&
                            <GENERAL_BLOCK
                                bg_color="#fff"
                                brad="6px"
                                className='d-flex flex-column align-items-start p-3'
                            >
                                <BUTTON
                                    bg_color="transparent"
                                    bord="0"
                                    clr="#000"
                                    hov={
                                        {
                                            color: "#EB4B07",
                                            bg_color: ""
                                        }
                                    }
                                    f_family="montserrat_medium"
                                    className='mb-3 d-flex jcsb aic w-100'
                                    onClick={() => set_selected_lang("en")}
                                >English
                                    {
                                        selected_lang == "en" &&
                                        <img src={mark_icon} alt="" />
                                    }
                                </BUTTON>
                                <BUTTON
                                    bg_color="transparent"
                                    bord="0"
                                    clr="#000"
                                    className='d-flex jcsb aic w-100'
                                    hov={
                                        {
                                            color: "#EB4B07",
                                            bg_color: ""
                                        }
                                    }
                                    f_family="montserrat_medium"
                                    onClick={() => set_selected_lang("ru")}
                                >Russian
                                    {
                                        selected_lang == "ru" &&
                                        <img src={mark_icon} alt="" />
                                    }

                                </BUTTON>
                            </GENERAL_BLOCK>
                        }
                    </GENERAL_BLOCK> */}
                </>
            }
        </div >
    )
}

export default NavbarMobile