import moment from 'moment';

export const BASE_URL = process.env.NODE_ENV;
export const pageSize = [
    {
        "value": "A4",
        "text": "A4"
    },
    {
        "value": "Letter",
        "text": "US Letter"
    }
]
export const fonts = [
    {
        "value": 1,
        "text": "Montserrat",
        "key": "montserrat_medium",
        class: "montserrat_medium"
    },
    {
        "value": 2,
        "text": "Times New Roman",
        "key": "tinos_regular",
        class: "tinos_regular"
    },
    {
        "value": 3,
        "text": "Calibri",
        "key": 'calibri_',
        class: "calibri_"
    },
    {
        "value": 4,
        "text": "Open Sans",
        "key": "open_sans",
        class: "open_sans"
    },
    {
        "value": 5,
        "text": "Arial",
        "key": "arial_",
        class: "arial_"
    },
    {
        "value": 6,
        "text": "Cambria",
        "key": "cambria_",
        class: "cambria_"
    },
    {
        "value": 7,
        "text": "Garamond",
        "key": "garamond_",
        class: "garamond_"
    },
    {
        "value": 8,
        "text": "Verdana",
        "key": "verdana_",
        class: "verdana_"
    },
    {
        "value": 9,
        "text": "Helvetica",
        "key": "Helvetica_Neue",
        class: "Helvetica_Neue"
    },
];

export const font_sizes = [
    {
        key: "10_font_size",
        value: 1,
        text: "10"
    },
    {
        key: "10.5_font_size",
        value: 2,
        text: "10.5"
    },
    {
        key: "11_font_size",
        value: 3,
        text: "11"
    },
    {
        key: "11.5_font_size",
        value: 4,
        text: "11.5"
    },
    {
        key: "12_font_size",
        value: 5,
        text: "12"
    },
    {
        key: "12.5_font_size",
        value: 6,
        text: "12.5"
    },
    {
        key: "13_font_size",
        value: 7,
        text: "13"
    },
    {
        key: "13.5_font_size",
        value: 8,
        text: "13.5"
    },
    {
        key: "14_font_size",
        value: 9,
        text: "14"
    },
    {
        key: "14.5_font_size",
        value: 10,
        text: "14.5"
    },
];

export const spacing = [
    {
        key: "1_spacing",
        value: 1,
        text: "1",
        lineHeight: 1
    },
    {
        key: "1.5_spacing",
        value: 1.5,
        text: "2",
        lineHeight: 1.125
    },
    {
        key: "2.25_spacing",
        value: 2.25,
        text: "3",
        lineHeight: 1.3125
    },
    {
        key: "3_spacing",
        value: 3,
        text: "4",
        lineHeight: 1.5
    },
    {
        key: "3.75_spacing",
        value: 3.75,
        text: "5",
        lineHeight: 1.6875
    },
    {
        key: "4.5_spacing",
        value: 4.5,
        text: "6",
        lineHeight: 1.875
    },
    {
        key: "5.25_spacing",
        value: 5.25,
        text: "7",
        lineHeight: 2.0625
    },
    {
        key: "6_spacing",
        value: 6,
        text: "8",
        lineHeight: 2.25
    },
    {
        key: "6.75_spacing",
        value: 6.75,
        text: "9",
        lineHeight: 2.4375
    },
    {
        key: "7.5_spacing",
        value: 7.5,
        text: "10",
        lineHeight: 2.625
    }
];


export const date_format = [
    {
        value: 1,
        text: moment().format('YYYY'),
        key: 'yyyy',
    },
    {
        value: 2,
        text: moment().format('MMMM YYYY'),
        key: 'MMMM yyyy',
    },
    {
        value: 3,
        text: moment().format('MM.YYYY'),
        key: 'MM.yyyy',
    },
    {
        value: 4,
        text: moment().format('MM/YYYY'),
        key: 'MM/yyyy',
    },
];
