import Interceptor from "./interceptor";

export async function login(data) {
    return (await Interceptor.post("/auth/login", data));
}

export async function register(data) {
    return (await Interceptor.post("/auth/register", data));
}

export async function refresh(data) {
    return (await Interceptor.post("/auth/refresh", data));
}

export async function reset_password(data) {
    return (await Interceptor.post("/auth/reset", data));
}

export async function set_password(data) {
    return (await Interceptor.post("/auth/set", data));
}

export async function google() {
    return (await Interceptor.get("/auth/google"));
}
