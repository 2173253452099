import styled from "styled-components";

export const SIDEBAR = styled.div`
    width: 360px;
    position: fixed;
    padding-top: 140px;
    top: 0;
    right: 0;
    background: rgb(255, 255, 255);
    z-index: 1;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
`
export const SIDEBAR_CONTAINER = styled.div`
    background-color: #fff;
    height: 100%;
    top: 140px;
    left:  ${props => props?.left};
    right:  ${props => props?.right};
    padding:  ${props => props?.pad};
    overflow:  ${props => props?.scrol};
    ::-webkit-scrollbar {
        width: 0px;
        height:0px
    };
    /* Track */
    ::-webkit-scrollbar-track {
    background:transparent;
    };
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: transparent;
    };
`
export const SIDEBAR_IN = styled.div`
    position: absolute;
    left: -60px;
    top: 50px;
`
export const DRAWER = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    position: absolute;
    width: 60px;
    height: 52px;
    top: 190px;
    left: ${props => props?.left};
    right: ${props => props?.right};
    background: #4984EA;
    cursor: pointer;
    border-radius: ${props => props?.brad};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
`