import axios from "axios";
import { refresh } from "./auth";

const baseURL = process.env.REACT_APP_BASE_URL || "https://engineernow.org/api";

const instance = axios.create({ baseURL });

instance.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem("token"));
        if (token) {
            config.headers["Authorization"] = `Bearer ${token?.access}`;
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err) => {
        const token = JSON.parse(localStorage.getItem("token"));
        const config = err?.config;

        if (err?.response?.status === 401) {
            const requestUrl = config.url;

            if (requestUrl === "/auth/login" || requestUrl === "/auth/register") {
                return Promise.reject(err);
            }

            let res = await refresh({ refreshToken: token?.refresh });
            localStorage.setItem("token", JSON.stringify({ ...token, access: res.data?.accessToken }));

            if (res.data?.accessToken) {
                config.headers = {
                    ...config.headers,
                    authorization: `Bearer ${res.data?.accessToken}`,
                };
            }
            return axios(config);
        }

        return Promise.reject(err);
    }
);

export default instance;
