import React, { useEffect, useRef, useState } from 'react'
import {
  ADD_INPUT_INPUT, BODY, BUTTON, SECTION_CONTROL, SUB_SECTION_CONTROL,
  SECTION_ROOT, GENERAL_BLOCK, TEXT
} from '../../styled';
import arrow_up from "../../images/icons/arrow-up.svg"
import arrow_down from "../../images/icons/arrow-down.svg";
import delete_icon from "../../images/icons/delete.svg";
import close_icon from "../../images/icons/close.svg";
import copy_icon from "../../images/icons/copy.svg";
import "./index.css"
import styles from "./template.module.css";
import { font_sizes, fonts, spacing, date_format } from '../../constants';
import 'react-datepicker/dist/react-datepicker.css';
import {
  resume,
  selected_section as selected_section_atom,
  tool_view as tool_view_atom,
  editor_index as editor_index_atom,
  editor_methods,
  is_preview
} from '../../recoil/store';
import { useRecoilState } from 'recoil';
import { Modal } from 'react-bootstrap';
import { Button, TextField } from '@mui/material';
import { decor, section_types } from '../../templates';

import { Types } from 'react-easy-edit';
import { InlineCustom } from "../Contenteditable"
import QuillEditor from "../QuillEditor"

import "./editor.css";
import { editorMethods } from '../../utilFunctions';
import moment from 'moment';

import Calendar from 'react-calendar';

const Body = ({
  is_download,
  template_state,
  link_modal,
  on_set_modal,
}) => {
  const [is_preview_, set_is_preview] = useRecoilState(is_preview);
  const [resume_, set_resume] = useRecoilState(resume)
  const [body_content, set_body_content] = useState(template_state);
  const [start_calendar_view, set_start_calendar_view] = useState(false);
  const [start_date, set_start_date] = useState();
  const [end_date, set_end_date] = useState();
  const [end_calendar_view, set_end_calendar_view] = useState(false);
  const [selected_section, set_selected_section] = useRecoilState(selected_section_atom);
  const [editor_methods_, set_editor_methods] = useRecoilState(editor_methods);

  const [link, set_link] = useState({
    url: "",
    address: ""
  });
  const [editor_index, set_editor_index] = useRecoilState(editor_index_atom);
  const [tool_view, set_tool_view] = useRecoilState(tool_view_atom);
  const [isEnter, set_isEnter] = useState(false);
  const [key, set_key] = useState("");

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [{ sectionToRemove, itemToRemove }, setToRemove] = useState({ sectionToRemove: "", itemToRemove: null });

  const selectedDateFormat = date_format[resume_?.settings?.dateFormat - 1].key || date_format[0].key;

  let calendar_ref = useRef(null);
  var calendar_index = 0;
  const [calendarTopPosition, setCalendarTopPosition] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendar_ref && calendar_ref.current) {
        const isClickInsideCalendar = calendar_ref.current.contains(event.target);
        if (!isClickInsideCalendar) {
          set_start_calendar_view(false);
          set_end_calendar_view(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendar_ref]);

  useEffect(() => {
    let key = selected_section?.section_controller
    if (resume_[key] != undefined && !is_preview_) {
      let copy = JSON.parse(JSON.stringify(resume_))
      copy[key].items[selected_section?.index]["startDate"] = moment(start_date).format("MM/DD/YYYY");
      copy[key].items[selected_section?.index]["endDate"] = moment(end_date).format("MM/DD/YYYY");
      set_resume(copy)
    }
  }, [start_date, end_date])

  const handleDateClick = (parentId, dateTextId, index) => {
    const parentElement = document.getElementById(parentId);
    const dateTextElement = document.getElementById(dateTextId);
    if (parentElement && dateTextElement) {
      const parentPosition = parentElement.getBoundingClientRect().top;
      const textPosition = dateTextElement.getBoundingClientRect().bottom - parentPosition;
      calendar_index = index;
      setCalendarTopPosition(textPosition + 12);
    }
  };

  const isContentValid = (content) => {
    if (content == undefined) return false;
    const plainTextContent = content.replace(/<[^>]*>/g, '');
    return plainTextContent.length > 1;
  };

  const add = () => {
    let copy_body = [...body_content];
    if (link?.address?.row !== undefined) {
      copy_body[link.address?.section].section_content[link?.address.content].items[link?.address?.row] = {
        ...copy_body[link.address?.section].section_content[link?.address.content].items[link?.row],
        value: link?.url,
        address: true
      }
    } else {
      copy_body[link.address?.section].section_content[link?.address.content] = {
        ...copy_body[link.address?.section].section_content[link?.address.content],
        value: link?.url,
        address: true
      }
    }
    set_body_content(copy_body);
    on_set_modal(false);
  }

  const sectionKeys = (copy) => {
    return Object.keys(copy).filter(f =>
      f !== "designType" && f !== "name" &&
      f !== "professionId" && f !== "personalInformation" &&
      f !== "settings" && f !== "customSection");
  }

  const visiableKeys = (copy) => {
    return sectionKeys(copy)
      .filter(key => resume_[key]?.active == true)
      .filter(key => resume_[key]?.order !== undefined)
      .sort((s1, s2) => resume_[s1]?.order < resume_[s2]?.order ? -1 : resume_[s1]?.order > resume_[s2]?.order ? 1 : 0)
  }

  // controller functions
  const swap_down = (order) => {
    var copy = { ...resume_ };
    let vKeys = visiableKeys(copy);
    let cSectionIndex = vKeys.findIndex(key => resume_[key]?.order == order);
    if (cSectionIndex < vKeys.length - 1) {
      let nextSection = copy[vKeys[cSectionIndex + 1]]
      copy[vKeys[cSectionIndex + 1]] = {
        ...copy[vKeys[cSectionIndex + 1]],
        order: order
      };
      copy[vKeys[cSectionIndex]] = {
        ...copy[vKeys[cSectionIndex]],
        order: nextSection?.order
      };
      set_resume(copy)
    }
  }

  const swap_up = (order) => {
    var copy = { ...resume_ };
    let vKeys = visiableKeys(copy);
    let cSectionIndex = vKeys.findIndex(key => resume_[key]?.order == order);
    if (cSectionIndex > 1) {
      let previousSection = copy[vKeys[cSectionIndex - 1]]
      copy[vKeys[cSectionIndex - 1]] = {
        ...copy[vKeys[cSectionIndex - 1]],
        order: order
      };
      copy[vKeys[cSectionIndex]] = {
        ...copy[vKeys[cSectionIndex]],
        order: previousSection?.order
      };
      set_resume(copy);
    }
  }

  const type_4_swap_down = (index, key) => {
    let all = { ...resume_ };
    let copy = [...resume_[key].items];
    let this_index = copy[index];
    if (index == copy?.length - 1) {
      copy[index] = copy[0];
      copy[0] = this_index;
    } else {
      copy[index] = copy[index + 1];
      copy[index + 1] = this_index;
    }

    all = {
      ...all,
      [key]: { ...all[key], items: copy }
    };
    set_resume(all)
  }

  const type_4_swap_up = (index, key) => {
    let all = { ...resume_ }
    let copy = [...resume_[key].items];

    let this_index = copy[index];
    if (index == 0) {
      copy[index] = copy[copy?.length - 1];
      copy[copy?.length - 1] = this_index;
    }
    else {
      copy[index] = copy[index - 1];
      copy[index - 1] = this_index;
    }
    all = {
      ...all,
      [key]: { ...all[key], items: copy }
    };
    set_resume(all)
  }

  const add_section_type_4_item = (key) => {
    let all = { ...resume_ };
    let copy = [...resume_[key].items];
    copy?.push({
      designation: "",
      company: "",
      content: "<p><br></p>",
      startDate: new Date(),
      endDate: new Date(),
      location: ""
    });
    all = {
      ...all,
      [key]: {
        ...all[key],
        items: copy
      }
    }
    set_resume(all);
  }

  function displayDeleteConfirmation(key, item) {
    setToRemove({ sectionToRemove: key, itemToRemove: item });
    setShowConfirmationModal(true);
  }

  function deleteSection() {
    let key = sectionToRemove
    let resume = { ...resume_ };
    if (itemToRemove != null) {
      let collection = { ...resume?.[key] }
      let items = [...collection?.items]
      items?.splice(itemToRemove, 1);
      resume = {
        ...resume,
        [key]: {
          ...resume[key],
          items
        }
      }
    } else {
      resume[key] = {
        ...resume[key],
        active: false
      };
      set_selected_section({ ...selected_section, side_bar: "", section_controller: "", index: 0 })
    }
    setShowConfirmationModal(false);
    set_resume(resume);
  }

  function handle_calendar(date) {
    set_start_calendar_view(false);
    set_end_calendar_view(false);
    if (start_calendar_view) {
      set_start_date(date);
    } else {
      set_end_date((date == "Present") ? null : date);
    }
  }

  function handle_location(value, key, i) {
    let copy = { ...resume_ };
    let key_ = { ...copy[key] };
    let array = [...copy[key].items];
    array[i] = {
      ...array[i],
      location: value,
    };
    key_.items = array;
    copy[key] = key_;
    set_resume(copy)
  }

  function handle_titles_of_type_4(e, key, i) {
    let { name, value } = e.target;
    let copy = { ...resume_ };
    let key_ = { ...copy[key] };
    let array = [...copy[key].items];
    array[i] = {
      ...array[i],
      [name]: value,
    };
    key_.items = array;
    copy[key] = key_;
    set_resume(copy)
  }

  function add_new_input(e, key, i) {
    let resume_copy = { ...resume_ };
    let key_ = { ...resume_copy[key] };
    let array = [...resume_copy[key].content];

    if (e.keyCode === 13) {
      const newIndex = i + 1;

      array.splice(newIndex, 0, {
        value: "",
        last: true,
      });

      key_.content = array;
      resume_copy[key] = key_;
      set_resume(resume_copy);

      if (array.length - 1 !== newIndex) {
        e.preventDefault();
        const element = document.getElementById(`${key}-${newIndex}`);
        if (element) {
          element.focus();
        }
      }
    } else if (e.keyCode === 8) {
      if (key_.content?.length === 1) return;

      if (e.target.value?.length === 0) {
        e.preventDefault();
        array.splice(i, 1);
        key_.content = array;
        resume_copy[key] = key_;
        set_resume(resume_copy);

        const element = document.getElementById(`${key}-${i - 1}`).focus();
        if (element) {
          element.focus();
        }
      }
    }
  }

  function handle_type_2_state(value, i, key) {
    let resume_copy = { ...resume_ };
    let key_ = { ...resume_copy[key] };
    let array = [...resume_copy[key].content];
    array[i] = {
      ...array[i],
      value,
    };
    key_.content = array;
    resume_copy[key] = key_;
    set_resume(resume_copy)
  }

  useEffect(() => {
    let all = Array.from(document.getElementsByClassName("ql-toolbar"));
    if (editor_index !== "") {
      for (const iterator of all) {
        iterator.style.display = "none"
      }
      if (editor_index !== "") {
        let el = document.getElementById(editor_index)?.getElementsByClassName("ql-toolbar")[0];
        if (el) {
          el.style.display = "flex";
          el.style.justifyContent = "space-between"
        }
      }
      else {
        let el = document.getElementById(editor_index)?.getElementsByClassName("ql-toolbar")[0];
        if (el) {
          el.style.display = "none"
        }
      }
    }
    else {
      for (const iterator of all) {
        iterator.style.display = "none"
      }
    }
  }, [editor_index])

  useEffect(() => {
    if (key) {
      if (isEnter) {
        var text_div = Array.from(document.getElementById(key).
          getElementsByClassName("inline_editor"))[Array.from(document.getElementById(key).
            getElementsByClassName("inline_editor")).length - 1];
        if (text_div.childNodes.length) {
          var startNode = text_div.childNodes[0];
          var endNode = text_div.childNodes[0];
          if (startNode.nodeValue !== null && startNode.nodeValue !== undefined) {
            startNode.nodeValue = startNode.nodeValue.trim();
            var selectedRange = document.createRange();
            selectedRange.setStart(startNode, text_div.childNodes[0]);
            selectedRange.setEnd(endNode, text_div.childNodes[0]);
            var selectedText = window.getSelection();
            selectedText.removeAllRanges();
            selectedText.addRange(selectedRange);
            text_div.focus();
          }
        }
        Array.from(document.getElementById(key).getElementsByClassName("inline_editor"))[Array.from(document.getElementById(key).getElementsByClassName("inline_editor")).length - 1].focus();
      }
    }
  }, [resume_, isEnter, key])

  useEffect(() => {
    let ed = document.getElementsByClassName("ql-container");
    for (const iterator of ed) {
      iterator.getElementsByClassName("ql-editor")[0].style.fontSize = `${fontSize}pt`;
      iterator.getElementsByClassName("ql-editor")[0].style.lineHeight = spacing.find(f => f?.value == resume_?.settings?.lineSpacing)?.lineHeight;
      iterator.getElementsByClassName("ql-editor")[0].style.fontFamily = fonts.find(f => f?.value == resume_?.settings?.font)?.key;
    }
  }, [resume_]);
  let fontSize = font_sizes.find(f => f?.value == resume_?.settings?.fontSize)?.text
  let lineSpacing = spacing.find(f => f?.value == resume_?.settings?.lineSpacing)
  return (
    <BODY
      bg_color="#fff"
      pad="16px 31px"
    >
      {
        visiableKeys(resume_)
          .map((r, i, array) => (
            r?.includes("custom") && resume_[r]?.active ?
              <SECTION_ROOT
                key={r}
                className='position-relative'
                is_last={(resume_?.designType == 0) ? ((i + 1 === array.length) ? false : true) : false}
                border_color={resume_?.settings?.color}
                onClick={() => set_selected_section({ ...selected_section, side_bar: r, section_controller: r, custom_type: i, index: i })}>
                <GENERAL_BLOCK
                  clr={resume_?.designType == 1 ? resume_?.settings?.color : "#000"}
                  f_size={fontSize + "pt"}
                  f_family={fonts.find(f => f?.value == resume_?.settings?.font)?.key}
                  pad={(resume_?.designType == 4 || resume_?.designType == 3) && "0 0 0 10px"}
                  bg_color={(resume_?.designType == 3) && `${resume_?.settings?.color}61`}
                  textTrans="uppercase"
                  bordLeft={resume_?.designType == 4 && `16px solid ${resume_?.settings?.color}`}
                  bordBot={(resume_?.designType == 1 || resume_?.designType == 2) && `1px solid ${resume_?.settings?.color}`}
                  wei={900}
                >{resume_[r]?.name}</GENERAL_BLOCK>
                {
                  (selected_section?.section_controller == r) &&
                  <SECTION_CONTROL className='section_controller'>
                    <div className='d-flex'>
                      <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" className='d-flex aic' brad="4px"
                        onClick={() => swap_down(resume_[r]?.order)}
                      >
                        <img src={arrow_down} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                      </BUTTON>
                      <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" brad="4px" className='ms-2 d-flex aic justify-center'
                        onClick={() => swap_up(resume_[r]?.order)}
                      >
                        <img src={arrow_up} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                      </BUTTON>
                    </div>
                    {
                      r !== "summary" &&
                      <BUTTON wid="24px"
                        onClick={() => displayDeleteConfirmation(r, null)}
                        hei="24px" bg_color="#1C1C1C" brad="4px" className='mt-2 d-flex aic justify-center'>
                        <img src={delete_icon} alt="" style={{ width: '100%', height: '60%' }} />
                      </BUTTON>
                    }
                  </SECTION_CONTROL>
                }
                <div style={{
                  padding: resume_?.designType == 4 && "0 0 0 27px",
                  display: (is_download) ? (isContentValid(resume_[r]?.content) ? "" : "none") : ""
                }}>
                  <QuillEditor
                    placeholder={"Enter text (not visible if blank, click Preview)"}
                    index={`${r}_quill_${i}`}
                    set_index={set_editor_index}
                    set_tool_view={set_tool_view}
                    tool_view={tool_view}
                    set_resume={set_resume}
                    keys={r}
                    item_index={i}
                    value={resume_[r]?.content}
                  />
                </div>
              </SECTION_ROOT> :
              section_types?.type_1?.includes(r) && resume_[r]?.active ?
                <SECTION_ROOT
                  key={r}
                  className='position-relative'
                  is_last={(resume_?.designType == 0) ? ((i + 1 === array.length) ? false : true) : false}
                  border_color={resume_?.settings?.color}
                  onClick={(e) => {
                    set_selected_section({
                      ...selected_section,
                      side_bar: ["summary", "achievements", "certifications",
                        "professionalAffiliations"].includes(r) ? r : "",
                      section_controller: r,
                      index: i
                    });
                  }}
                >
                  <GENERAL_BLOCK
                    clr={(resume_?.designType == 1 || resume_?.designType == 2) ? resume_?.settings?.color : "#000"}
                    f_size={fontSize + "pt"}
                    f_family={fonts.find(f => f?.value == resume_?.settings?.font)?.key}
                    bordLeft={resume_?.designType == 4 && `16px solid ${resume_?.settings?.color}`}
                    bordBot={(resume_?.designType == 1 || resume_?.designType == 2) && `1px solid ${resume_?.settings?.color}`}
                    pad={(resume_?.designType == 4 || resume_?.designType == 3) && "0 0 0 10px"}
                    bg_color={(resume_?.designType == 3) && `${resume_?.settings?.color}61`}
                    textTrans="uppercase"
                    wei={900}
                  >
                    {decor[r]?.label}
                  </GENERAL_BLOCK>
                  {
                    ((selected_section?.section_controller == r)) &&
                    <SECTION_CONTROL className='section_controller' style={{ right: "-26px" }}>
                      {
                        r != "summary" &&
                        <div className='d-flex'>
                          <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" className='d-flex aic justify-center' brad="4px"
                            onClick={() => swap_down(resume_[r]?.order)}
                          >
                            <img src={arrow_down} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                          </BUTTON>
                          <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" brad="4px" className='ms-2 d-flex aic justify-center'
                            onClick={() => swap_up(resume_[r]?.order)}
                          >
                            <img src={arrow_up} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                          </BUTTON>
                        </div>
                      }
                      {
                        <BUTTON wid="24px"
                          onClick={() => displayDeleteConfirmation(r, null)}
                          hei="24px" bg_color="#1C1C1C" brad="4px" className='mt-2 d-flex aic justify-center'>
                          <img src={delete_icon} alt="" style={{ width: '100%', height: '60%' }} />
                        </BUTTON>
                      }
                    </SECTION_CONTROL>
                  }
                  <div style={{
                    padding: resume_?.designType == 4 && "0 0 0 27px",
                    display: (is_download) ? (isContentValid(resume_[r]?.content) ? "" : "none") : ""
                  }}>
                    <QuillEditor
                      placeholder={decor[r]?.placeholder}
                      index={`${r}_quill_1`}
                      set_index={set_editor_index}
                      set_tool_view={set_tool_view}
                      tool_view={tool_view}
                      set_editor_methods={set_editor_methods}
                      editorMethods={editorMethods}
                      set_resume={set_resume}
                      keys={r}
                      value={resume_[r]?.content ?? ""}
                    />
                  </div>
                </SECTION_ROOT> :
                section_types?.type_2.includes(r) && resume_[r]?.active ?
                  <SECTION_ROOT
                    key={r}
                    className='position-relative'
                    is_last={(resume_?.designType == 0) ? ((i + 1 === array.length) ? false : true) : false}
                    border_color={resume_?.settings?.color}
                    onClick={() => {
                      set_tool_view(false)
                      set_selected_section({
                        ...selected_section,
                        side_bar: r, section_controller: r, index: i
                      })
                    }}
                  >
                    <GENERAL_BLOCK
                      clr={(resume_?.designType == 1 || resume_?.designType == 2) ? resume_?.settings?.color : "#000"}
                      f_size={fontSize + "pt"}
                      bordLeft={resume_?.designType == 4 && `16px solid ${resume_?.settings?.color}`}
                      bordBot={(resume_?.designType == 1 || resume_?.designType == 2) && `1px solid ${resume_?.settings?.color}`}
                      pad={(resume_?.designType == 4 || resume_?.designType == 3) && "0 0 0 10px"}
                      bg_color={(resume_?.designType == 3) && `${resume_?.settings?.color}61`}
                      textTrans="uppercase"
                      wei={900}
                    >{decor[r]?.label}
                    </GENERAL_BLOCK>
                    {
                      selected_section?.section_controller == r &&
                      <SECTION_CONTROL className='section_controller'>
                        <div className=''>
                          <div className='d-flex'>
                            <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" className='d-flex aic' brad="4px"
                              onClick={() => swap_down(resume_[r]?.order)}
                            >
                              <img src={arrow_down} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                            </BUTTON>
                            <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" brad="4px" className='ms-2 d-flex aic justify-center'
                              onClick={() =>
                                swap_up(resume_[r]?.order)}
                            >
                              <img src={arrow_up} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                            </BUTTON>
                          </div>
                          <BUTTON wid="24px"
                            onClick={() => displayDeleteConfirmation(r, null)}
                            hei="24px" bg_color="#1C1C1C" brad="4px" className='mt-2 d-flex aic justify-center'>
                            <img src={delete_icon} alt="" style={{ width: '100%', height: '60%' }} />
                          </BUTTON>
                        </div>
                      </SECTION_CONTROL>
                    }
                    <div style={{
                      display: (is_download) ? (resume_[r]?.content[0].value ? "" : "none") : ""
                    }}>
                      <ul
                        style={{
                          display: resume_[r]?.bulletType == 3 || resume_[r]?.bulletType == 4 ? "flex" : "grid",
                          listStyleType: resume_[r]?.bulletType == 3 && "none",
                          padding:
                            resume_[r]?.bulletType != 3
                              ? resume_[r]?.bulletType == 4
                                ? resume_?.designType === 4
                                  ? lineSpacing.value === 1
                                    ? "1px 0 11px 42px"
                                    : "1px 0 8.5px 42px"
                                  : lineSpacing.value === 1
                                    ? "1px 0 11px 12px"
                                    : "1px 0 8.5px 12px"
                                : resume_?.designType === 4
                                  ? "6px 30px 6px"
                                  : "6px 0 6px"
                              : resume_?.designType === 4
                                ? "5px 0 10px 28px"
                                : "5px 0",
                          gridTemplateColumns: resume_[r]?.bulletType == 2 ? "1fr 1fr 1fr" : "1fr 1fr",
                          flexWrap: "wrap",
                          margin: 0,
                          flexDirection: resume_[r]?.bulletType !== 4 && resume_[r]?.bulletType !== 3 && "column",
                        }}
                        id={r}
                      >
                        {
                          resume_[r]?.content?.map((c, i) => (
                            resume_[r]?.bulletType == 3 ?
                              <InlineCustom
                                r={r}
                                i={i}
                                style={{
                                  minWidth: "30px",
                                  maxWidth: "628px",
                                  marginRight: "5px",
                                  marginTop: 1,
                                  marginBottom: 1,
                                  outline: "none",
                                  background: resume_?.settings?.color,
                                  color: resume_?.settings?.textColor,
                                  padding: "6px 7.5px",
                                  borderRadius: "5px",
                                  fontSize: fontSize + "pt",
                                  lineHeight: 1
                                }}
                                set_isEnter={set_isEnter}
                                set_key={set_key}
                                class={resume_[r]?.class}
                                resume={resume_}
                                set_resume={set_resume}
                                type={Types.TEXT}
                                attributes={{ name: "awesome-input", id: 1 }}
                              />
                              :
                              resume_[r]?.bulletType == 4 ?
                                <li key={i} style={{
                                  marginBottom: (lineSpacing?.value == 1) ? "-5px" : "-2.5px"
                                }}>
                                  <div className="me-4">
                                    <InlineCustom
                                      set_isEnter={set_isEnter}
                                      set_key={set_key}
                                      r={r}
                                      i={i}
                                      style={{
                                        width: "100%",
                                        outline: "none",
                                        fontSize: fontSize + "pt",
                                        lineHeight: lineSpacing?.lineHeight,
                                      }}
                                      class={resume_[r]?.class}
                                      resume={resume_}
                                      set_resume={set_resume}
                                      type={Types.TEXT}
                                      attributes={{ name: "awesome-input", id: 1 }}
                                    />
                                  </div>
                                </li> :
                                <div key={i} style={(is_download) ? (c?.value) ? {} : { display: "none" } : {}}>
                                  <li style={{ display: "flex", alignItems: "center", listStyleType: "none" }}>
                                    <svg
                                      width="4"
                                      height="4"
                                      style={{
                                        marginRight: "1px",
                                        padding: 0
                                      }}
                                    >
                                      <circle cx="2" cy="2" r="2" />
                                    </svg>
                                    <input
                                      type="text"
                                      autoFocus={c?.last}
                                      id={`${r}-${i}`}
                                      onBlur={(e) => (e.target.value = e.target.value.trim())}
                                      value={c?.value}
                                      onChange={(e) => handle_type_2_state(e.target.value, i, r)}
                                      onFocus={(e) => set_editor_index("")}
                                      style={{
                                        border: 0,
                                        outline: 0,
                                        fontSize: fontSize + "pt",
                                        lineHeight: (lineSpacing.value == 1) ? 0.5 : lineSpacing?.lineHeight,
                                        width: "100%",
                                        padding: "0px 5px",
                                      }}
                                      placeholder={decor[r]?.placeholder}
                                      onKeyDown={(e) => add_new_input(e, r, i)}
                                      autoComplete='off'
                                    />
                                  </li>
                                </div>
                          ))
                        }
                      </ul>
                    </div>
                  </SECTION_ROOT> :
                  section_types?.type_4?.includes(r) && resume_[r]?.active ?
                    <SECTION_ROOT
                      id={r}
                      key={r}
                      className='position-relative'
                      is_last={(resume_?.designType == 0) ? ((i + 1 === array.length) ? false : true) : false}
                      border_color={resume_?.settings?.color}
                    >
                      <GENERAL_BLOCK
                        clr={(resume_?.designType == 1 || resume_?.designType == 2) ? resume_?.settings?.color : "#000"}
                        f_size={`${fontSize}pt`}
                        f_family={fonts.find(f => f?.value == resume_?.settings?.font)?.key}
                        bordLeft={resume_?.designType == 4 && `16px solid ${resume_?.settings?.color}`}
                        bordBot={(resume_?.designType == 1 || resume_?.designType == 2) && `1px solid ${resume_?.settings?.color}`}
                        pad={(resume_?.designType == 4 || resume_?.designType == 3) && "0 0 0 10px"}
                        bg_color={(resume_?.designType == 3) && `${resume_?.settings?.color}61`}
                        textTrans="uppercase"
                        wei={900}
                      >{decor[r]?.label}</GENERAL_BLOCK>
                      {
                        selected_section?.section_controller == r &&
                        <SECTION_CONTROL className='section_controller'>
                          <div className='d-flex'>
                            <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" className='d-flex aic' brad="4px"
                              onClick={() => swap_down(resume_[r]?.order)}
                            >
                              <img src={arrow_down} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                            </BUTTON>
                            <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" brad="4px" className='ms-2 d-flex aic justify-center'
                              onClick={() => swap_up(resume_[r]?.order)}
                            >
                              <img src={arrow_up} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                            </BUTTON>
                          </div>
                          <BUTTON wid="24px"
                            onClick={() => displayDeleteConfirmation(r, null)}
                            hei="24px" bg_color="#1C1C1C" brad="4px" className='mt-2 d-flex aic justify-center'>
                            <img src={delete_icon} alt="" style={{ width: '100%', height: '60%' }} />
                          </BUTTON>
                          <BUTTON wid="24px"
                            onClick={() => add_section_type_4_item(r)}
                            hei="24px" bg_color="#1C1C1C" brad="4px" className='mt-2 d-flex aic'>
                            <img src={copy_icon} alt="" style={{ width: '100%', height: '60%' }} />
                          </BUTTON>
                        </SECTION_CONTROL>
                      }
                      <div style={{
                        padding: resume_?.designType == 4 && "0 0 0 27px",
                        display: (is_download) ? (resume_[r]?.items ? "" : "none") : ""
                      }}>
                        {
                          resume_[r]?.items?.map((w, i) => (
                            <div
                              key={i}
                              onClick={(event) => {
                                if (calendar_ref?.current) {
                                  const isClickInsideCalendar = calendar_ref?.current.contains(event.target);
                                  if (!isClickInsideCalendar) {
                                    return
                                  }
                                  return
                                }
                                set_selected_section({
                                  ...selected_section, side_bar: ["workExperience", "internship"].includes(r) ? r : "",
                                  section_controller: r, type_4_item: i, index: i
                                })
                              }}
                            >
                              <div className='position-relative'>
                                {
                                  selected_section?.section_controller == r && selected_section?.type_4_item == i && resume_[r]?.items?.length > 1 &&
                                  <div className='d-flex justify-content-center'>
                                    <SUB_SECTION_CONTROL className='section_controller'>
                                      <div className='d-flex'>
                                        <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" className='d-flex aic' brad="4px"
                                          onClick={() => type_4_swap_down(i, r)}
                                        >
                                          <img src={arrow_down} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                                        </BUTTON>
                                        <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" brad="4px" className='ms-2 d-flex aic justify-center'
                                          onClick={() => type_4_swap_up(i, r)}
                                        >
                                          <img src={arrow_up} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                                        </BUTTON>
                                        <BUTTON wid="24px"
                                          onClick={() => displayDeleteConfirmation(r, null)}
                                          hei="24px" bg_color="#1C1C1C" brad="4px" className='mt-2 d-flex aic justify-center'>
                                          <img src={delete_icon} alt="" style={{ width: '100%', height: '60%' }} />
                                        </BUTTON>
                                      </div>
                                    </SUB_SECTION_CONTROL>
                                  </div>
                                }
                              </div>
                              <div className='d-flex justify-content-between'
                                style={{ paddingTop: "6px" }}>
                                <div style={{
                                  minWidth: "354px",
                                  paddingBottom: isContentValid(w?.content) ? "0px"
                                    : (is_download) ? "6px" : "0px"
                                }}>
                                  {
                                    (!is_download || isContentValid(w?.designation)) &&
                                    <div>
                                      <ADD_INPUT_INPUT
                                        f_weight="700"
                                        f_size={`${fontSize}pt`}
                                        f_family={fonts.find(f => f?.value == resume_?.settings?.font)?.key}
                                        type="text"
                                        value={w?.designation}
                                        placeholder={r == "education" ? "Enter a Degree" : "Designation"}
                                        name={"designation"}
                                        onFocus={() => set_tool_view(false)}
                                        onChange={(e) => handle_titles_of_type_4(e, r, i)}
                                      />
                                    </div>
                                  }
                                  {
                                    (!is_download || isContentValid(w?.company)) &&
                                    <div>
                                      <ADD_INPUT_INPUT
                                        f_weight="700"
                                        f_size={`${fontSize}pt`}
                                        f_family={fonts.find(f => f?.value == resume_?.settings?.font)?.key}
                                        type="text"
                                        value={w?.company}
                                        placeholder={r == "education" ? "Enter a college/institute" : "Company"}
                                        name={"company"}
                                        onFocus={() => set_tool_view(false)}
                                        onChange={(e) => handle_titles_of_type_4(e, r, i)}
                                      />
                                    </div>
                                  }
                                </div>
                                <div style={{ minWidth: "300px" }}>
                                  <div className='d-flex justify-content-end' style={{ whiteSpace: "pre" }}>
                                    <TEXT
                                      id={r + "_start_date_" + i}
                                      f_weight="700"
                                      f_size={`${fontSize}pt`}
                                      f_family={fonts.find((f) => f?.value === resume_?.settings?.font)?.key}
                                      onClick={() => {
                                        handleDateClick(r, r + "_start_date_" + i, i)
                                        set_start_calendar_view(!start_calendar_view)
                                      }}
                                    >
                                      {w?.startDate ? `${moment(new Date(w?.startDate)).format(selectedDateFormat)}`
                                        : `${moment().format(selectedDateFormat)}`}
                                    </TEXT>
                                    <TEXT f_weight="700" f_size={`${fontSize}pt`} f_family={fonts.find((f) => f?.value === resume_?.settings?.font)?.key}>
                                      {"  ─  "}
                                    </TEXT>
                                    <TEXT
                                      id={r + "_end_date_" + i}
                                      f_weight="700"
                                      f_size={`${fontSize}pt`}
                                      f_family={fonts.find((f) => f?.value === resume_?.settings?.font)?.key}
                                      onClick={() => {
                                        handleDateClick(r, r + "_start_date_" + i, i)
                                        set_end_calendar_view(!end_calendar_view)
                                      }}
                                    >
                                      {w?.endDate && moment(new Date(w?.endDate)).isValid()
                                        ? `${moment(new Date(w?.endDate)).format(selectedDateFormat)}`
                                        : 'Present'}
                                    </TEXT>
                                  </div>
                                  {
                                    (start_calendar_view || end_calendar_view)
                                    && calendar_index == i && selected_section.section_controller === r &&
                                    <div ref={calendar_ref}
                                      style={{
                                        boxShadow: '0 0 16px rgba(0, 0, 0, 0.25)',
                                        position: "absolute",
                                        top: calendarTopPosition,
                                        right: "-90px",
                                        zIndex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: "center",
                                        backgroundColor: 'white'
                                      }}
                                    >
                                      <Calendar
                                        onChange={date => { handle_calendar(date) }}
                                        value={(start_calendar_view) ? start_date : end_date}
                                        maxDetail="year"
                                      />
                                      {
                                        (end_calendar_view) &&
                                        <BUTTON
                                          clr="#fff"
                                          bord="0"
                                          brad="12"
                                          wid="80%"
                                          pad="4px 0"
                                          bg_color='#4984EA'
                                          onClick={() => { handle_calendar("Present") }}
                                          type='submit'
                                          f_family='montserrat_semibold'
                                          style={{
                                            borderRadius: "12px",
                                            margin: "12px 0 12px 0",
                                            boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)'
                                          }}
                                        >
                                          Present
                                        </BUTTON>
                                      }
                                    </div>
                                  }
                                  {
                                    (!is_download || isContentValid(w?.location)) &&
                                    <div className='d-flex justify-content-end'>
                                      <ADD_INPUT_INPUT
                                        f_weight="700"
                                        f_size={`${fontSize}pt`}
                                        type="text"
                                        placeholder={(is_download) ? "" : 'Location'}
                                        style={{ textAlign: "right", color: "#3a3a3c6b" }}
                                        value={w?.location}
                                        onFocus={() => set_tool_view(false)}
                                        onInput={(e) => handle_location(e.target.value, r, i)} />
                                    </div>
                                  }
                                </div>
                              </div>
                              <div style={{
                                display: (is_download) ? (isContentValid(w?.content) ? "" : "none") : ""
                              }}>
                                <QuillEditor
                                  index={`${r}_quill_${i}`}
                                  placeholder="Enter text (not visible if blank, click Preview)"
                                  set_index={set_editor_index}
                                  set_tool_view={set_tool_view}
                                  tool_view={tool_view}
                                  set_resume={set_resume}
                                  editorMethods={editorMethods}
                                  keys={r}
                                  value={w?.content}
                                  item_index={i}
                                />
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </SECTION_ROOT> :
                    section_types?.type_5.includes(r) && resume_[r]?.active ?
                      <SECTION_ROOT
                        key={r}
                        className='position-relative'
                        is_last={(resume_?.designType == 0) ? ((i + 1 === array.length) ? false : true) : false}
                        border_color={resume_?.settings?.color}
                        onClick={(e) => {
                          set_tool_view(false)
                          set_selected_section({
                            ...selected_section, side_bar: r == "trainingCourses" ? r : "",
                            section_controller: r, index: i
                          })
                        }}
                      >
                        <GENERAL_BLOCK
                          clr={(resume_?.designType == 1 || resume_?.designType == 2) ? resume_?.settings?.color : "#000"}
                          f_size={fontSize + "pt"}
                          bordLeft={resume_?.designType == 4 && `16px solid ${resume_?.settings?.color}`}
                          bordBot={(resume_?.designType == 1 || resume_?.designType == 2) && `1px solid ${resume_?.settings?.color}`}
                          pad={(resume_?.designType == 4 || resume_?.designType == 3) && "0 0 0 10px"}
                          bg_color={(resume_?.designType == 3) && `${resume_?.settings?.color}61`}
                          textTrans="uppercase"
                          wei={900}
                        >{decor[r]?.label}</GENERAL_BLOCK>
                        {
                          selected_section?.section_controller == r &&
                          <SECTION_CONTROL className='section_controller'>
                            <div className=''>
                              <div className='d-flex'>
                                <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" className='d-flex aic' brad="4px"
                                  onClick={() => swap_down(resume_[r]?.order)}
                                >
                                  <img src={arrow_down} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                                </BUTTON>
                                <BUTTON wid="24px" hei="24px" bg_color="#1C1C1C" brad="4px" className='ms-2 d-flex aic justify-center'
                                  onClick={() =>
                                    swap_up(resume_[r]?.order)}
                                >
                                  <img src={arrow_up} alt="" style={{ padding: "4px", width: '100%', height: '100%' }} />
                                </BUTTON>
                              </div>
                              <BUTTON wid="24px"
                                onClick={() => displayDeleteConfirmation(r, null)}
                                hei="24px" bg_color="#1C1C1C" brad="4px" className='mt-2 d-flex aic justify-center'>
                                <img src={delete_icon} alt="" style={{ width: '100%', height: '60%' }} />
                              </BUTTON>
                            </div>
                          </SECTION_CONTROL>
                        }
                        <div style={{
                          display: (is_download) ? (resume_[r]?.content[0].value ? "" : "none") : ""
                        }}>
                          <ul
                            style={{
                              display: resume_[r]?.bulletType == 3 || resume_[r]?.bulletType == 4 ? "flex" : "grid",
                              listStyleType: resume_[r]?.bulletType == 3 && "none",
                              padding:
                                resume_?.designType === 4
                                  ? "6px 30px 6px"
                                  : "6px 0 6px",
                              gridTemplateColumns: resume_[r]?.bulletType == 1 ? "1fr 1fr" : "1fr",
                              flexWrap: "wrap",
                              margin: 0,
                              flexDirection: resume_[r]?.bulletType !== 4 && resume_[r]?.bulletType !== 3 && "column",
                            }}
                            id={r}
                          >
                            {
                              resume_[r]?.content?.map((c, i) => (
                                resume_[r]?.bulletType == 3 ?
                                  <InlineCustom
                                    r={r}
                                    i={i}
                                    style={{
                                      minWidth: "30px",
                                      maxWidth: "628px",
                                      marginRight: "5px",
                                      marginTop: 1,
                                      marginBottom: 1,
                                      outline: "none",
                                      background: resume_?.settings?.color,
                                      color: resume_?.settings?.textColor,
                                      padding: "6px 7.5px",
                                      borderRadius: "5px",
                                      fontSize: fontSize + "pt",
                                      lineHeight: 1
                                    }}
                                    set_isEnter={set_isEnter}
                                    set_key={set_key}
                                    class={resume_[r]?.class}
                                    resume={resume_}
                                    set_resume={set_resume}
                                    type={Types.TEXT}
                                    attributes={{ name: "awesome-input", id: 1 }}
                                  />
                                  :
                                  resume_[r]?.bulletType == 4 ?
                                    <li key={i} style={{
                                      marginBottom: (lineSpacing?.value == 1) ? "-5px" : "-2.5px"
                                    }}>
                                      <div className="me-4">
                                        <InlineCustom
                                          set_isEnter={set_isEnter}
                                          set_key={set_key}
                                          r={r}
                                          i={i}
                                          style={{
                                            width: "100%",
                                            outline: "none",
                                            fontSize: fontSize + "pt",
                                            lineHeight: lineSpacing?.lineHeight,
                                          }}
                                          class={resume_[r]?.class}
                                          resume={resume_}
                                          set_resume={set_resume}
                                          type={Types.TEXT}
                                          attributes={{ name: "awesome-input", id: 1 }}
                                        />
                                      </div>
                                    </li> :
                                    <div style={(is_download) ? (c?.value) ? {} : { display: "none" } : {}}>
                                      <li style={{ display: "flex", alignItems: "center", listStyleType: "none" }}>
                                        <svg
                                          width="4"
                                          height="4"
                                          style={{
                                            marginRight: "1px",
                                            padding: 0
                                          }}
                                        >
                                          <circle cx="2" cy="2" r="2" />
                                        </svg>
                                        <input
                                          type="text"
                                          autoFocus={c?.last}
                                          id={`${r}-${i}`}
                                          onBlur={(e) => (e.target.value = e.target.value.trim())}
                                          value={c?.value}
                                          onChange={(e) => handle_type_2_state(e.target.value, i, r)}
                                          onFocus={(e) => set_editor_index("")}
                                          style={{
                                            border: 0,
                                            outline: 0,
                                            fontSize: fontSize + "pt",
                                            lineHeight: (lineSpacing.value == 1) ? 0.5 : lineSpacing?.lineHeight,
                                            width: "100%",
                                            padding: "0px 5px",
                                          }}
                                          placeholder={decor[r]?.placeholder}
                                          onKeyDown={(e) => add_new_input(e, r, i)}
                                          autoComplete='off'
                                        />
                                      </li>
                                    </div>
                              ))
                            }
                          </ul>
                        </div>
                      </SECTION_ROOT> : null)
          )
      }
      <Modal
        onHide={() => on_set_modal(false)}
        show={link_modal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className={styles.modal_container}>
          <div className='d-flex jcsb aic'>
            <div className={styles.modal_title}>
              Add section
            </div>
            <div>
              <BUTTON
                bg_color="transparent"
                bord="0"
                onClick={() => on_set_modal(false)}><img src={close_icon} alt="close_icon" /></BUTTON>
            </div>
          </div>
          <div className={`${styles.modal_content} mt-5`}>
            <div className='d-flex flex-colu,'>
              <TextField type='url' id="standard-basic" label="Standard" variant="standard" onChange={(e) => set_link({ ...link, url: e.target.value })} />
              <Button type='buttopn' onClick={add} variant='contained'>Add</Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        onHide={() => setShowConfirmationModal(false)}
        show={showConfirmationModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal_container">
          <div className='text-center'>
            <TEXT
              f_size={"18px"}
              clr="#1C1C1C"
              f_family='montserrat_bold'
            >
              Are you sure you want to delete this section?
            </TEXT>
          </div>
          <div className={`d-flex`}>
            <BUTTON
              className={"mt-5"}
              clr="#000"
              bord="0"
              pad="12px 0"
              bg_color='transparent'
              brad="6px"
              wid="100%"
              f_family='montserrat_semibold'
              onClick={() => setShowConfirmationModal(false)}
            >
              Cancel
            </BUTTON>
            <BUTTON
              className={"mt-5"}
              clr="#fff"
              bord="0"
              pad="12px 0"
              bg_color='#4984EA'
              brad="6px"
              wid="100%"
              onClick={() => deleteSection()}
            >
              Delete
            </BUTTON>
          </div>
        </div>
      </Modal>
    </BODY >
  )
}

export default Body