import React from "react";
import ContentEditable from "react-contenteditable";

export class InlineEditorAsTextarea extends React.Component {
    constructor() {
        super();
        this.contentEditable2 = React.createRef();
        this.state = { html: "" };
    }

    componentDidMount() {
        this.setState({ html: this.props.value });
    }

    handleChange = (value) => {
        this.props.set_value(value);
        this.setState({ html: value });
    };

    handlePaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        document.execCommand("insertText", false, text);
    };

    render = () => {
        return (
            <ContentEditable
                className={`inline_editor ${this.props.class}`}
                style={this.props.style}
                placeholder={this.props.placeholder}
                autoFocus={true}
                aria-multiline={false}
                innerRef={this.contentEditable2}
                html={this.state.html}
                disabled={false}
                onChange={(e) => this.handleChange(e.target.value)}
                tagName="div"
                onPaste={this.handlePaste}
            />
        );
    };
}
