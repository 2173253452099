import styled from "styled-components";
export const SectionContainer = styled.div`
    height: 100%;
    padding-top: 30px;
`
export const SectionTitle = styled.p`
    font-family: montserrat_semibold;
    font-size: 18px;
    line-height: 21.94px;
    letter-spacing: -0.01em;
    margin-bottom: 30px;
`
export const Section4Title = styled.p`
    font-family: montserrat_semibold;
    font-size: 18px;
    line-height: 21.94px;
    letter-spacing: -0.01em;
`
export const SectionTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`
export const SectionLabel = styled.p`
    font-family: montserrat_semibold;
    font-size: 14px;
    line-height: 17px;
    color: #1C1C1C;
`
export const SectionInput = styled.input`
    font-family: montserrat_medium;
    font-size: 16px;
    line-height: 19.5px;
    letter-spacing: -0.01em;
    height: 44px;
    padding: 12px 12px 12px 16px;
    border: 1px solid #1C1C1C80;
    border-radius: 6px;
    margin-bottom: 16px;
    color: #1C1C1C;
    width: 100%;
    margin-top: 8px;
    outline: 0;
    // :focus{
    //     border: 1px solid #1C1C1C !important;
    // }
`
export const SectionDiv = styled.div`
    font-family: montserrat_medium;
    font-size: 16px;
    line-height: 19.5px;
    letter-spacing: -0.01em;
    height: 44px;
    padding: 12px 12px 12px 16px;
    border: 1px solid #1C1C1C80;
    border-radius: 6px;
    margin-bottom: 16px;
    width: 100%;
    margin-top: 20px;
    min-height: 260px;
`
export const SectionDate = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: montserrat_medium;
    font-size: 16px;
    line-height: 19.5px;
    letter-spacing: -0.01em;
    height: 44px;
    padding: 12px 12px 12px 16px;
    border: 1px solid #1C1C1C80;
    border-radius: 6px;
    margin-bottom: 16px;
    color: #1C1C1C;
    width: 100%;
    margin-top: 8px;
    outline: 0;
`
export const DeleteBtn = styled.button`
    width: 44px;
    height: 44px;
    margin-left: 7px;
    border: 1px solid #1C1C1C80;
    border-radius: 6px;
    margin-bottom: 16px;
`
export const ActionBtn = styled.button`
    width: 34px;
    height: 34px;
    margin-left: 7px;
    border: 1px solid #1C1C1C80;
    border-radius: 6px;
`