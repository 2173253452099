import Interceptor from "./interceptor";

export async function createCV(body) {
    return (await Interceptor.post('/cv', body)).data;
}
export async function editCV(cv_id, body) {
    return (await Interceptor.put(`/cv/${cv_id}`, body)).data;
}
export async function deleteCV(cv_id) {
    return (await Interceptor.delete(`/cv/${cv_id}`)).data;
}
export async function duplicateCV(cv_id, body) {
    return (await Interceptor.post(`/cv/duplicate/${cv_id}`, body)).data;
}