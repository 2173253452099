import React, { useEffect, useState } from 'react';
import { getList } from '../services/getTemplateList';
import { BUTTON, INPUT, TEXT } from '../styled';
import { GRID, TEMPLATE_CARD } from '../page-style/page.styled';
import style from '../page-style/page-style.module.css';
import SelectBox from '../Component/Selectbox/SelectboxWithSearchbar';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import {
  new_resume,
  page_loading,
  profession_templates,
  selected_template,
  tool_view
} from '../recoil/store';
import { createCV } from '../services/crud';
import { Modal } from 'react-bootstrap';
import { enqueueSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';

const TemplatePicker = () => {

  const nav = useNavigate();
  const [name, setName] = useState("");
  const [nameModal, setNameModal] = useState(false);

  const [allTemplates, setAllTemplates] = useState();
  const [selectedProfessionTemplate, setSelectedProfessionTemplate] = useState();
  const [professionTemplates, setProfessionTemplates] = useRecoilState(profession_templates);
  const [selectedTemplate, setSelectedTemplate] = useRecoilState(selected_template);
  const [pageLoading, setPageLoading] = useRecoilState(page_loading);
  const [toolView, setToolView] = useRecoilState(tool_view);
  const [newCV, setNewCV] = useRecoilState(new_resume);
  const isMobile = useMediaQuery('(max-width: 991px)');
  const isMobileTemplate = useMediaQuery('(max-width: 355px)');
  const isMobileBuilder = useMediaQuery('(max-width: 900px)');
  useEffect(() => {
    (async function fetchData() {
      setPageLoading(true);
      try {
        const response = await getList();
        response.professionTemplates = (response.professionTemplates || [])
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((p) => ({
            key: p?.id,
            value: p?.professionId,
            text: p?.name,
            image: p?.image,
            prof_id: p?.professionId
          }));
        setAllTemplates(response?.allTemplates);
        setProfessionTemplates(response?.professionTemplates);
        setSelectedTemplate(response?.professionTemplates[0]?.key);
        setSelectedProfessionTemplate(response?.professionTemplates[0]?.value);
        setPageLoading(false);
      } catch (error) {
        setPageLoading(false);
        console.error(error);
      }
    })();
  }, []);

  const save = async () => {
    const createResponse = await createCV({ name, templateId: selectedTemplate });
    setNewCV({ ...newCV, name, id: createResponse?.cvId });
    setToolView(false);
    enqueueSnackbar("Resume has been successfully created!", { variant: "success" });
    if (isMobileBuilder) {
      nav(`/rb-mobile/${createResponse?.cvId}`)
    }
    else {
      nav(`/resume-builder/${createResponse?.cvId}`);
    }
  };

  const renderProfessionTemplates = () => {
    if (!selectedProfessionTemplate || !professionTemplates?.length) return null;
    return professionTemplates
      .filter((t) => t?.value === selectedProfessionTemplate)
      .map((t) => (
        <TEMPLATE_CARD
          key={t.key}
          wid={isMobileTemplate ? "100%" : "313px"}
          className="mt-5"
          onClick={() => {
            setSelectedTemplate(t.key);
            setNameModal(true);
          }}
        >
          <img src={t?.image} alt="" />
          <TEXT className={`${style.text} mt-2`} f_size="20px" d_play="block">
            {t?.text}
          </TEXT>
        </TEMPLATE_CARD>
      ));
  };

  const renderAllTemplates = () => {
    if (!allTemplates?.length) return null;
    return allTemplates.map((d) => (
      <TEMPLATE_CARD
        key={d?.id}
        wid={isMobileTemplate ? "100%" : "313px"}
        onClick={() => {
          setSelectedTemplate(d?.id);
          setNameModal(true);
        }}
      >
        <img src={d?.image} alt="" />
        <TEXT className={`${style.text} mt-2`} f_size="20px" d_play="block">
          {d?.name}
        </TEXT>
        <TEXT d_play="block" f_family="montserrat_medium" className="mt-2">
          {d?.description ?? "A clean resume with empty fields."}
        </TEXT>
      </TEMPLATE_CARD>
    ));
  };

  return (
    <div className="container pt-4 pb-4">
      <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
        <div>
          <TEXT
            className={style.text}
            f_size="40px"
            d_play="block"
            f_family="montserrat_medium"
          >
            Choose your Profession
          </TEXT>
          {!isMobile && renderProfessionTemplates()}
        </div>
        {isMobile && (
          <SelectBox
            style={{
              width: "100%",
              minWidth: "100px",
              height: "40px",
              position: "relative",
              marginTop: "24px",
            }}
            width="100%"
            multiple={false}
            data={professionTemplates}
            setResult={(v) => { setSelectedProfessionTemplate(v) }}
            staticSearch={true}
          />
        )}
        {isMobile && renderProfessionTemplates()}
        {!isMobile && (
          <SelectBox
            style={{
              width: "343px",
              marginLeft: "40px",
              minWidth: "100px",
              height: "40px",
              position: "relative",
            }}
            width="313px"
            multiple={false}
            data={professionTemplates}
            setResult={(v) => { setSelectedProfessionTemplate(v) }}
            staticSearch={true}
          />
        )}
      </div>
      <div className="mt-5">
        <TEXT
          className={style.text}
          f_size="40px"
          d_play="block"
          f_family="montserrat_medium"
        >
          Or pick a template for your Engineering Resume
        </TEXT>
        <GRID className="mt-5">{renderAllTemplates()}</GRID>
      </div>

      <Modal
        onHide={() => setNameModal(false)}
        show={nameModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="login_modal"
      >
        <div className="modal_container">
          <div className="text-center">
            <TEXT f_size="30px" clr="#1C1C1C" f_family="montserrat_medium">
              New Resume
            </TEXT>
          </div>
          <INPUT
            type="email"
            className="mt-4"
            f_family="montserrat_medium"
            placeholder="name"
            wid="505px"
            hei="46px"
            brad="6px"
            pad="12px 24px 12px 24px"
            bord="1px solid rgb(28 28 28 / 48%)"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="d-flex">
            <BUTTON
              className="mt-5"
              clr="#000"
              bord="0"
              pad="12px 0"
              bg_color="transparent"
              brad="6px"
              wid="100%"
              f_family="montserrat_semibold"
              onClick={() => setNameModal(false)}
            >
              Cancel
            </BUTTON>
            <BUTTON
              className="mt-5"
              clr="#fff"
              bord="0"
              pad="12px 0"
              bg_color="#4984EA"
              brad="6px"
              wid="100%"
              onClick={save}
            >
              Save
            </BUTTON>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TemplatePicker;
